// Footer.js
import { useState } from "react";
import { Container, Navbar, Nav, NavDropdown, Button, FormControl, Col, Row, Form } from "react-bootstrap";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import "../styles.css";
import logo_d3 from '../l_d3.png';
import logo_laimburg from '../images/Laimburg.png';
import { useTranslation } from 'react-i18next';

export default function Footer () {
	
    const { t, i18n } = useTranslation();

	return (

	<>
	    <div className="footer">

		<div className="flex-row">
		<img className="logo-laimburg" src={logo_laimburg}/>
		
		<div className="padding">
		    <div className="flex-col footer-text">
			<span>{t("vzl")}</span>
			<span>{t("vzl_post")}</span>
			<span>{t("vzl_tel")}</span>
		    </div>
		</div>
		</div>

		<div className="padding" sx={{marginLeft: 'auto'}}>
			<img className="m-1" src={logo_d3}/>
		</div>
	    </div>
	</>

	);
} 
