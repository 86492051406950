import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import Grid from "@mui/material/Grid";
import { getCurrentYear, getCurrentMonth } from '../../../js/datetime';

import { GraphContext } from "../../GraphContext";

import dayjs from 'dayjs';

import { preferences } from "../../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ExcelButton from '../../../components/ExcelButton';
import PDFButton from '../../../components/PDFButton';
import Title from '../../../components/Title';

import { TemperatureTable } from './tables/TemperatureTable';
import { DaysTable } from './tables/DaysTable';
import { HumidityTable } from './tables/HumidityTable';
import { RainTable } from './tables/RainTable';
import { SunTable } from './tables/SunTable';
import { LevelTable } from './tables/LevelTable';
import Snackbar from "@mui/material/Snackbar";
import DatePicker from '../../../components/DatePicker';
import StationButton from "../../../components/StationButton";
import StackRow from "../../../components/StackRow";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

export const Monatsreport = ({ active }) => {

    const { trackPageView } = useMatomo()
    const location = useLocation();
    
    const [ parameter, setParameter ] = useContext(GraphContext);

    const [ fromdjs, setFromdjs ] = useState(dayjs());

    const [ year, setYear ] = useState(getCurrentYear());
    const [ month, setMonth ] = useState(getCurrentMonth());

    const [ sensors, setSensors ] = useState([]);

    const [ open, setOpen ] = useState(false);
    const { t, i18n } = useTranslation();

    // Track page view
    useEffect(() => {
      trackPageView({
          documentTitle: 'Monatsreport: '+year+"-"+month, // optional
	  href: 'https://meteo.laimburg.it'+location.pathname, // optional
          customDimensions: [
          {
            id: 1
          },
        ], // optional                                                                                                                              
      })
    }, [trackPageView, location])

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelector = (event) => {

        setFromdjs(event);

        let date = event.$d;

        setMonth(date.getMonth() + 1);
        setYear(1900 + date.getYear());

        /*setMonth (date.getMonth()+1);*/
    }

    useEffect(() => {

        let station = parameter.station;

        let url = preferences.address + '/rest/activesensors.php?station=' + station;
        
        fetch(url)
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                  return response.json();
                } else {
                  throw Error(response.statusText);
                }
              })
            .then((data) => {
                setSensors(data)
            })
            .catch((err) => {
                console.log(err);
            });
    }, [month, year, parameter])

    const exportExcel = (event) => {

        let station = parameter.station;

        var url = preferences.address + '/query/report/export/month/report/excel.php?station=' + station + "&year=" + year + "&month=" + month + "&lang=" + i18n.language;
	setOpen(true);
	let filename = '';
        fetch(url)
	    .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error)
                {filename = 'Data_Export.xlsx'}
                return response.blob() })
            .then((data) => {
		var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
		setOpen(false);
            });
    }

    const exportPDF = (event) => {

        let station = parameter.station;

        var url = preferences.address + '/query/report/export/month/report/pdf.php?station=' + station + "&year=" + year + "&month=" + month + "&lang=" + i18n.language;
	setOpen (true);
	let filename = '';
        fetch(url)
	    .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error)
                {filename = 'Data_Export.pdf'}
                return response.blob() })
            .then((data) => {
                let date = Date();
                var file = new File([data], filename, { type: "application/pdf;charset=utf-8" });
                saveAs(file);
		setOpen (false);
            });
    }

    const action = (
	<React.Fragment>
	</React.Fragment>
    )

    return (

        <>

            {/* Pass down functions*/}
            <div className="stagearea grid p-2">

		<Title text={t("monatsreport")}/>

                <Stack spacing={0} >

                    <StackRow text={t("station")} component={<StationButton />} />

                    <Item>
                        <DatePicker
                            title="month"
                            value={fromdjs}
                            callback={handleSelector}
                            view={["month", "year"]}
                            format="MMM YYYY"
                        />
                    </Item>

                </Stack>


                {/* Tables */}
                <DaysTable year={year} month={month}></DaysTable>

		{sensors.filter ( (d, i) => {return d==="airtemp2m"} ).length > 0 &&
                 <TemperatureTable sensor="airtemp2m" year={year} month={month}></TemperatureTable>
		}

		{sensors.filter ( (d, i) => {return d==="airtemp"} ).length > 0 &&
                 <TemperatureTable sensor="airtemp" year={year} month={month}></TemperatureTable>
		}
		{sensors.filter ( (d, i) => {return d==="rain"} ).length > 0 &&
                 <RainTable year={year} month={month}></RainTable>
		}
		
		{sensors.filter ( (d, i) => {return d==="globalrad"} ).length > 0 &&
                 <SunTable year={year} month={month}></SunTable>
		}

		{sensors.filter ( (d, i) => {return d==="humidity"} ).length > 0 &&
                <HumidityTable sensor="humidity" year={year} month={month}></HumidityTable>
		}

                {/* Bodentemperaturen */}
		{sensors.filter ( (d, i) => {return d==="soiltemp1"} ).length > 0 &&
                 <TemperatureTable sensor="soiltemp1" year={year} month={month}></TemperatureTable>
		}
		{sensors.filter ( (d, i) => {return d==="soiltemp2"} ).length > 0 &&
                 <TemperatureTable sensor="soiltemp2" year={year} month={month}></TemperatureTable>
		}
		
                {/* Grundwasser */}
		{sensors.filter ( (d, i) => {return d==="level"} ).length > 0 &&
                 <LevelTable sensor="level" year={year} month={month}></LevelTable>
		}
           
                <Grid container flexDirection={"row-reverse"}>
            
                    <Grid item className="p-2">
                        <ExcelButton callback={exportExcel} />
                    </Grid>

                    <Grid item className="p-2">
                        <PDFButton callback={exportPDF} />
                    </Grid>

                </Grid>
                
            </div>

	    <Snackbar
		open={open}
		autoHideDuration={6000}
		message="Downloading ..."
		action={action}
	    />
        </>
    );
}
