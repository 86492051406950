// Stationinfo.js
import Footer from "../components/Footer";
import Middle from "../routes/Middle";
import Header from "../components/Header";

import Box from '@mui/material/Box';

import "../styles.css";

export default function Layout () {

return (
    
	<Box
        sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
        }}>

        <Header />
        <Middle />
        <Footer />
	</Box>

	);
}
