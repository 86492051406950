import { useState, useEffect, useContext } from "react";

import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { GraphContext } from "../../GraphContext";
import { getCurrentYear, getCurrentMonth } from "../../../js/datetime";
import { preferences } from "../../../preferences";

import { saveAs } from 'file-saver';
import DatePicker from '../../../components/DatePicker';
import StationButton from "../../../components/StationButton";
import Title from "../../../components/Title";
import StackRow from "../../../components/StackRow";
import Box from '@mui/material/Box';
import { t } from "i18next";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export const Witterung = (props) => {

    const { t, i18n } = useTranslation();
    const { trackPageView } = useMatomo()
    const location = useLocation();

    const [ reps, setReps ] = useState([]);
    const [ parameter, setParameter ] = useContext(GraphContext);

    // Track page view                                                                                                                              
    useEffect(() => {
        trackPageView({
            documentTitle: 'Ranking', // optional                                                                       
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {
        fetch(preferences.address+"/files/witterungen.json")
            .then(response => response.json())
            .then(data => {
                setReps(data.filter((item) => {return item.language === i18n.language}));
                //setReps(data);

                })
            .catch(error => console.log(error));
    }, [i18n.language]);

    const handleDownload = (str) => () => {

        let fileName = reps[str].filename;
        let prefix = reps[str].prefix;

        fetch(preferences.address+"/files/" + fileName)
            .then(response => {
                response.blob()
                .then(blob => {
                    // Creating new object of PDF file                                                                                         

                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values                                                                                         
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = fileName;
                    alink.click();
                })
            })
    }


    return (
        <>
            <div className="stagearea grid p-2">

                <Title text={t("witterungsberichte")} />

                <Box>
                    <List>
                        {reps !== undefined &&
                            reps.map((item, index) => {
                                return (
                                <ListItem key={index}>
                                    <ListItemButton onClick={handleDownload(index)}>
                                        <ListItemIcon sx={{maxWidth: '35px'}}>
                                            <PictureAsPdfIcon />
                                        </ListItemIcon>
                                        <ListItemText sx={{maxWidth: '50px'}}>
                                            {item.year}
                                        </ListItemText>
                                        <ListItemText>
                                            {item.filename}
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                )
                            })
                        }

                    </List>
                </Box>

            </div>
        </>
    );
}
