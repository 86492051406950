import { useState, useEffect, useContext } from "react";

import { GraphContext } from "../GraphContext";
import { useTranslation} from 'react-i18next';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import PropTypes from 'prop-types';

import { TemperatureOverview } from './TemperatureOverview';
import { TempMapDev } from './TempMapDev';
import { TempMap } from './TempMap';
import { TempMinmax } from './TempMinmax';
import { Frostberegnung } from './Frostberegnung';

import { TabPanel } from "../../components/TabPanel";

import StationButton from "../../components/StationButton";
import StackRow from "../../components/StackRow";
import Title from "../../components/Title";
import NoData from "../../components/NoData";

export function TemperatureContent ({exttab}) {

    const { t } = useTranslation();
	const [parameter, setParameter] = useContext(GraphContext);

	const [ tab, setTab ] = useState(exttab !== undefined ? parseInt(exttab) : 0);
	  
	  TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		tab: PropTypes.number.isRequired,
	  };
	  
	  function a11yProps(index) {
		return {
		  id: `simple-tab-${index}`,
		  'aria-controls': `simple-tabpanel-${index}`,
		};
	  }

	  const handleChange = (event, newValue) => {
		setTab(newValue);
	 };

	useEffect (() => {
	    if (parameter.station !== 1)
		    setTab(0);
	}, [parameter])

	 return (

		<>
			<div className="stagearea grid p-2">

            <StackRow text={t("station")} component={<StationButton />} />

	
			<Title text={t("temperature")} />

			<Box sx={{ width: '100%' }}>

				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						variant="scrollable"
						centered
						value={tab} 
						onChange={handleChange} 
						aria-label="basic tabs" 
						TabIndicatorProps={{ className: "hidden sm:block" }}
						classes={{
  							flexContainer: "flex-wrap sm:flex-nowrap",
  						}}
  					> 
					<Tab label={t("tagesmittelwerte")} {...a11yProps(0)} />
					<Tab label={t("temperature_maps")} {...a11yProps(1)} />
					<Tab label={t("temperature_divs")} {...a11yProps(2)} />
					<Tab label={t("temperature_minmax")} {...a11yProps(3)} />
		                        {parameter.station === 1 && <Tab label={t("frosttemperatur")} {...a11yProps(1)} />}
					
					</Tabs>
				</Box>

				<TabPanel value={tab} index={0}>
					<TemperatureOverview />
				</TabPanel>

				<TabPanel value={tab} index={1}>
					 <TempMap />
				</TabPanel>

				<TabPanel value={tab} index={2}>
					 <TempMapDev />
				</TabPanel>

				<TabPanel value={tab} index={3}>
					 <TempMinmax />
		 </TabPanel>
		 
		  <TabPanel value={tab} index={4}>
		  {parameter.station === 1 && <Frostberegnung /> }
		  </TabPanel>

			</Box>

			<div className="flex-row p-4 expand">
			</div>

			<div id="pdf">
			</div>


		</div>

		</>
		);

}

