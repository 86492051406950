import { useState, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { convertTimestamp } from '../../js/datetime';
import { GraphContext } from "../GraphContext";
import dayjs from 'dayjs';
import { NumericFormat } from 'react-number-format';
import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import { Button } from "@mui/material";
import { useIsAuthenticated } from 'react-auth-kit';

export const Correction = ({ station, sensor, date }) => {


  const isAuthenticated = useIsAuthenticated();

  const [fromdjs, setFromdjs] = useState(dayjs());

  const [parameter] = useContext(GraphContext);

  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [prevs, setPrevs] = useState([]);
  const [fore, setFore] = useState([]);
  const [stamps, setStamps] = useState([]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#40516f',
      color: theme.palette.common.white,
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const Item = styled(Paper)(({ theme }) => ({

    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  useEffect(() => {

    if (sensor !== undefined) {
      var url = preferences.address + '/query/errors/get.php?station=' + parameter.station + '&sensor=' + sensor.cat + '&date=' + convertTimestamp(date);

      fetch(url)
        .then((response) => response.json())
        .then((data) => {


          let h = [];
          data.prev_day.map((item) => {
            let i = data.prev.find(o => o.timestamp === item);
            if (i !== undefined)
              h.push(i)
            else
              h.push({ value: 0, timestamp: item, missing: true })
            return 0;
          })
          setPrevs(h);

          h = [];
          data.next_day.map((item) => {
            let i = data.fore.find(o => o.timestamp === item);
            if (i !== undefined)
              h.push(i)
            else
              h.push({ value: 0, timestamp: item })
            return 0;
          })
          setFore(h);


          h = [];
          data.timestamps.map((item) => {
            let i = data.list.find(o => o.timestamp === item);
            if (i !== undefined)
            {
              i.missing = false;
              h.push(i)
            }
            else
              h.push({ value: 0, timestamp: item, missing: true })
            return 0;
          })
          console.log (h);
          setRows(h);

          setStamps(data.timestamps);

        });
    }
  }, [parameter, fromdjs, sensor, date])


  function put(value, timestamp) {

    var url = preferences.address + '/query/errors/post.php';

    fetch(url, {
      method: "POST",
      mode: 'cors',
      cache: "no-cache",
      headers: {},
      body: JSON.stringify({
        station: parameter.station,
        sensor: sensor.cat,
        timestamp: timestamp,
        value: value
      }),
    });

  }

  const saveCallback = () => {

    rows.map((item) => {

      if (item.change) {
        console.log(item);
        put(item.value, item.timestamp);
      }
      return 0;
    })
  }


  const handleChangeNF = (event, index) => {

    let a = [...rows];

    let element = a[index]

    if (a[index].value !== parseFloat(event.target.value)) {
      element.value = parseFloat(event.target.value);
      element.change = true;
      element.c = true;
    }
    
    if (a[index].missing === true) {
      element.value = parseFloat(event.target.value);
      element.change = true;
      element.c = true;
    }

    setRows(a);
  };

  return (
    <>
      {isAuthenticated() &&
        <>

          <TableContainer component={Paper}>
            <Table size="small" aria-label="general table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t("_date")}</StyledTableCell>
                  <StyledTableCell>{t("_date")} (-1)</StyledTableCell>
                  <StyledTableCell>{t("value")}</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>{t("_date")} (+1)</StyledTableCell>
                  <StyledTableCell>{t("count")}</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>

                {stamps !== undefined &&

                  stamps.map((row, index) => {
                    return (
                      <StyledTableRow key={index} className="">
                        <StyledTableCell component="th" scope="row">{row}</StyledTableCell>

                        <StyledTableCell component="th" scope="row">{prevs[index] !== undefined && prevs[index].value}</StyledTableCell>

                        <StyledTableCell component="th" scope="row">

                          <NumericFormat
                            //suffix={'%'}
                            allowedDecimalSeparators={['%']}
                            decimalScale={1}
                            value={rows[index].value}
                            onBlur={(e) => handleChangeNF(e, index)}
                          />
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row">
                          {rows[index].missing===true && "Missing"}
                          {rows[index].corr===1 && "Corrected"}
                          {rows[index].c === true && "*"}
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row">{fore[index] !== undefined && fore[index].value}</StyledTableCell>

                        <StyledTableCell component="th" scope="row">{rows[index] !== undefined && rows[index].count}</StyledTableCell>

                      </StyledTableRow>
                    )
                  })}


              </TableBody>

            </Table>
          </TableContainer>

          <Button sx={{ margin: '10px' }} variant="contained" onClick={saveCallback} color="primary">Save</Button>

        </>
      }
    </>
  );
}
