import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import dayjs from 'dayjs';
//import { saveAs } from 'file-saver';

import { preferences } from "../../preferences";

import { getCurrentYear, } from '../../js/datetime';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from "@mui/material/CircularProgress";

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

//import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import DatePicker from '../../components/DatePicker';
import SpinButton from '../../components/SpinButton';
import StackRow from '../../components/StackRow';
import StationButton from '../../components/StationButton';

import { xlabel, ylabel, logo } from '../../components/d3/charts/chart';

import { useMatomo } from '@datapunt/matomo-tracker-react'

export const Clever = () => {

    const { trackPageView } = useMatomo()
    const { t, i18n } = useTranslation();

    const location = useLocation();

    const [parameter] = useContext(GraphContext);

    const [fromyjs, setFromyjs] = useState(dayjs().month() < 5 ? dayjs().year(getCurrentYear()).month(3).date(1) : dayjs().year(getCurrentYear() - 1).month(3).date(1));
    const [frombjs, setFrombjs] = useState(dayjs().month() < 5 ? dayjs().year(getCurrentYear()).month(3).date(1) : dayjs().year(getCurrentYear() - 1).month(3).date(1));

    //const [ year, setYear ] = useState(getCurrentYear()-1);//getCurrentYear());
    const [night, setNight] = useState(10);
    const [day, setDay] = useState(15);
    const [red1, setRed1] = useState(50);
    const [red2, setRed2] = useState(25);

    const svgRef = useRef();

    const [data, setData] = useState();
    const [cast, setCast] = useState();

    const [ temperature, setTemperature ] = useState();
    const [ radiation, setRadiation ] = useState();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {
        clear();
        d3.json(preferences.address + "/query/models/clever/get.php?station=" + parameter.station + "&year=" + fromyjs.year())
            .then((data) => {
                setData(data);

            });
    }, [parameter, fromyjs]);

    useEffect(() => {
	setCast();
        //clear();
        //d3.json("https://daten.buergernetz.bz.it/services/weather/district/1/bulletin?format=json&lang=de")
	/*fetch ("https://daten.buergernetz.bz.it/services/weather/district/1/bulletin?format=json&lang=de", {
	    method: "GET",
	    mode: "cors",
	    cache: "no-cache",
	    headers: {"Access-Control-Allow-Origin": "*"}, //"Content-Type": "application/json", 
	    //headers: {},
	    referrerPolicy: "no-referrer"
	    }
	)*/
	fetch ("/forecast.json")
	.then (response => response.json())
	.then (response => {
	    //console.log (response);
	    setCast(response);
	    }
	)
	.catch(error => {
	    console.log (error)
	})

	/*d3.json("forecast.json")
            .then((data) => {
                setCast(data);

            });*/
    }, [parameter, fromyjs]);

    useEffect (() => {
	if (data !== undefined && cast !== undefined)
	{
	    let t = [];
	    data.chart.forEach((d) => {
		let o = {};
		o.date = d.date;
		o.night = d.night;
		t.push(o);
	    })
	    cast.forecasts.forEach((d) => {
		t.forEach((e) => {
		    if (e.date === d.date.split("T")[0])
			e.night = d.temperatureMin;
		})
	    })
	    setTemperature(t);
	    //console.log(t);
	}

    }, [data, cast]);

	function forcastRadiation(item)
	{
	    let d = item.symbol.description;
	    let c = item.symbol.code;
	    /*if (d.includes("Regen")) return 5;
	    if (d.includes("Bedeckt")) return 10;
	    if (d.includes("Wolkig")) return 15;
	    if (d.includes("Heiter")) return 20;
	    if (d.includes("Wolkenlos")) return 50;*/
	    switch (c)
	    {
		case 'a':
			return 28;
		case 'b':
		case "t":
			return 22;
		case 'c': // Wolkig
		case 'f':
		case 'g':
		case 'u':
		case 'w':
		case 'y':
			return 17;
		case 'd':
			return 12;
		case 'e': // Bedeckt
		case 'h':
		case 'i':
		case 'j':
		case 'v':
		case 'x':
			return 8;
		default:
			return 5;
	    }
	    //return 20;
	}

    useEffect (() => {
	if (data !== undefined && cast !== undefined)
	{
	    let r = [];
	    data.chart.forEach((d) => {
		let o = {};
		o.date = d.date;
		o.rad = d.rad;
		r.push(o);
	    })
	    cast.forecasts.forEach((d) => {
		r.forEach((e) => {
		    if (e.date === d.date.split("T")[0])
			e.rad = forcastRadiation(d);
		})
	    })
	    setRadiation (r);
	    //console.log (r);
	}

    }, [data, cast]);

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Clever Modell', // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])


    useEffect(() => {
        const svg = d3.select(svgRef.current);

        //console.log (data.days);
        if (data !== undefined)
            draw(svg);

        /*let labels = [t("temperature_mids"), t("temperature_maps"), t("above_middle"), t("underneath_middle")]
        borders (svg, labels, 300, 70, 10);
        addLine (svg, 0, 2.5, "black", labels[0]);
        addLine (svg, 1, 1.5, "black", labels[1]);
        addArea (svg, 2, "red", labels[2]);
        addArea (svg, 3, "blue", labels[3]);*/

    }, [resize, temperature, radiation, night, day, fromyjs, frombjs, i18n.language, red1, red2]);


    /*const downloadExcel = () => {

        var url = preferences.address + '/query/climate/temperature/overview/excel.php';

        let block = {};
        block.station = parameter.station;
        block.year = fromyjs.year();
        block.lang = i18n.language;

        let filename = ''
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try { filename = response.headers.get('content-disposition').split('filename=')[1]; }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" })
                saveAs(file);

            });

    }*/


    const handleSelector = (event) => {
        event.hour(0).minute(0).second(0);
        setFromyjs(event);
        setFrombjs(frombjs.year(event.year()));
    }

    /* Behandlung */
    const handleBehandlung = (event) => {

        setFrombjs(event);
    }

    var xScale;
    var xScaleBand;
    let brush;
    let xAxis;
    let xGrid;
    let clip;

    let yScaleT;
    let yScaleS;
    let yScaleR;

    var height = 600;// - margin.top - margin.bottom;
    var width = 0;
    var margin = { top: 10, right: 80, bottom: 40, left: 80, bottomHeight: 100, offset: 20 }

    let behandlung = [];

    function clear() {

        // Clear
        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove();
        setData();

    }

    function draw(svg) {

        // Berechnug grüne Kurve
        radiation.forEach( // data.chart
            (d) => {
                let h = dayjs(d.date);
                h.hour(0);
                if (frombjs.hour(0).minute(0).second(0) <= h) { let e = {}; e.date = d.date; e.rad = d.rad; if (d.rad !== null) behandlung.push(e); } //dayjs(d.date)<=dayjs()
            }
        )
        behandlung = behandlung.filter((d, i) => { return i < 12 });

        behandlung.forEach((d, i) => {
            if (i > 0 && i < 6)
                d.rad = d.rad * (1 - red1 / 100);
            if (i > 5 && i < 11)
                d.rad = d.rad * (1 - red2 / 100);
        })
        //console.log (list);

        var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);

        // Clear
        svg.selectAll("*").remove();

        // setup SVG
        svg
            .attr("viewBox", [0, 0, svg.style('width'), svg.style('height')])
            .style("overflow", "visible")
            .style("-webkit-tap-highlight-color", "transparent")
            .on("pointerenter pointermove", pointermoved)
            .on("pointerleave", pointerleft)
            .on("touchstart", event => event.preventDefault());

        // Getting width
        width = parseInt(svg.style('width'));

        // SVG
        svg
            .attr("width", width) // + margin.left + margin.right
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // Add X axis --> it is a date format

        xScale = d3.scaleTime()
            .domain([d3.timeParse("%Y-%m-%d")(data.init), d3.timeParse("%Y-%m-%d")(data.end)])
            .range([margin.left, width - margin.right]);


        //console.log (off_set);
        xScaleBand = d3.scaleBand()
            .domain(d3.map(data.band, d => d3.timeParse("%Y-%m-%d")(d)))
            .range([margin.left, width - margin.right]).padding(0);

        /* X axis rain */
        xAxis = svg.append("g")
            .attr("transform", "translate(0," + (height - margin.bottom) + ")")
            .call(d3.axisBottom(xScale))
            .style("font-size", "12px");

        let bottomScale = d3.axisBottom(xScale);

        // X grid
        xGrid = svg.append("g")
            .attr('id', 'xgrid')
            .attr('transform', 'translate(0 ,' + (height - margin.bottom) + ')')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(bottomScale.tickSize(-height + margin.bottom + margin.top, 0).tickFormat("").ticks(60));

        // Y grid
        yScaleT = d3.scaleLinear()
            .domain([-2.5, 25])
            .range([height - margin.bottom - margin.bottomHeight - margin.offset, margin.top]);

        // Y ticks temperatur
        let axisLeft = d3.axisLeft(yScaleT);
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .call(axisLeft.ticks(10, "0.1f").tickFormat((x) => { return x + " °C" }))
            .style("font-size", "12px");

        // Berechnung der Scala der Globalstrahlung
        /*
            -2.5 - night - 25  summe 27.5
    
            x - day - y  summe 44
        */
        //let y1 = night + 2.5;
        //let y2 = 25 - night;
        let a1 = day - (25 - night) * 44 / 27.5;
        let a2 = (night + 2.5) * 44 / 27.5 + day;
        // Y grid
        yScaleS = d3.scaleLinear()
            //.domain([d3.max(data.chart, function (d) { return +d.rad; }), d3.min(data.chart, function (d) { return +d.rad; })])
            .domain([a2, a1])
            .range([height - margin.bottom - margin.bottomHeight - margin.offset, margin.top]);
        // Y ticks temperatur

        let axisRight = d3.axisRight(yScaleS);
        svg.append("g")
            .attr('transform', 'translate(' + (width - margin.right) + ', 0)')
            .call(axisRight.ticks(10, "0.1f").tickFormat((x) => { return x + " MJ/m²" }))
            .style("font-size", "12px");

        // Y under
        yScaleR = d3.scaleLinear()
            .domain([0, 100])
            .range([height - margin.bottom, height - margin.bottom - margin.bottomHeight]);

        // brush
        /*
        brush = d3.brushX()
            .extent([[margin.left, 0], [width - margin.right, height - margin.bottom]])
            .on("end", updateChart); // brushended
        

        svg.append("g")
            .attr('class', 'brush')
            .call(brush);
        */

        // Clip
        clip = svg.append('g')
            .attr("clip-path", "url(#clip)");

        svg.append("defs")
            .append("svg:clipPath")
            .attr('id', 'clip')
            .append("svg:rect")
            .attr("x", margin.left)
            .attr("y", 0)
            .attr("width", width - margin.left - margin.right)
            .attr("height", height);


        // Y ticks Regen
        let axisLeft2 = d3.axisLeft(yScaleR);
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .call(axisLeft2.ticks(4, "0f").tickFormat((x) => { return x + " mm" }))
            .style("font-size", "12px");

        // Grid X
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(axisLeft.tickSize(-width + margin.left + margin.right, 0).tickFormat(""));

        // Grid X
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(axisLeft2.tickSize(-width + margin.left + margin.right, 0).tickFormat(""));

        // Add the lines group
        const lines = clip
            .append("g")
            .attr('id', 'lines');

        //const X = d3.map(data, (d) => { return d3.timeParse("%Y-%m-%d")(d.date) });
        //const Y1 = d3.map(data.chart, (d) => { return d.night });
        //const Y2 = d3.map(data, (d) => { return d.longterm });
        //const I = d3.range(data.length);
        //const defined = (d, i) => Y1[i];
        //const D = d3.map(data, defined);
        //const line = (y) => d3.line().defined(i => D[i]).curve(d3.curveLinear).x(i => xScale(X[i])).y(y)(I);
        //const area = (y0, y1) => d3.area().defined(i => D[i]).curve(d3.curveLinear).x(i => xScale(X[i])).y0(y0).y1(y1)(I);

	/* Temperatur */
        lines.append("path")
            .datum(temperature)
            .attr('id', 'temperature')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "brown")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale (d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d, i) => { return yScaleT (d.night) })
                .defined((d) => {return d.night !== null})
            );

	if (cast !== undefined )
	{
        lines.append("path")
            .datum(cast.forecasts)
            .attr('id', 'temperaturecast')
            .attr('class', "line dashed")
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 4.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%dT%H:%M:%S")(d.date)) })
                .y((d, i) => { return yScaleT(d.temperatureMin) })
                .defined((d) => {return d.night !== null})
            );
        lines.append("path")
            .datum(cast.forecasts)
            .attr('id', 'temperaturecast')
            .attr('class', "line dashed" )
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 4.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%dT%H:%M:%S")(d.date)) })
                .y((d, i) => { return yScaleT(d.temperatureMax) })
                .defined((d) => {return d.night !== null})
            );
	}

	
        lines.append("path")
            .datum(temperature)
            .attr('id', 'temperature')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d, i) => { return yScaleT(night) })
            );

        lines.append("path")
            .datum(radiation)
            .attr('id', 'radiation')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d, i) => { return yScaleS(d.rad) })
                .defined((d) => {return d.rad !== null})
            );


	if (cast !== undefined)
	{
        lines.append("path")
            .datum(cast.forecasts)
            .attr('id', 'radiation')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%dT%H:%M:%S")(d.date)) })
                .y((d, i) => { return yScaleS(forcastRadiation (d)) })
                .defined((d) => {return d.rad !== null})
            );
	}

        /* Behandlung */
        lines.append("path")
            .datum(behandlung)
            .attr('id', 'radiation')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "green")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d, i) => { return yScaleS(d.rad) })
            );

        // Add the line
        /*lines.append("path")
            .datum(data.filter(defined))
            .attr('id', 'path2')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d, i) => { return yScale(d.actual) })
                .defined(((d) => { return d.actual !== null;} ))
            );*/

        //var colors = ['rgba(255,255,255,0)', '#084594'];
        /*var getColor = d3.scaleLinear()
            .domain([0, 100])
            .range(colors);*/

        const box = clip
            .append("g")
	    .attr("id", "box");

        // Striped up
        box.selectAll(".bar")
            .data(data.chart)
            .enter()
            .append("rect")
            .attr("class", "bar2")
            .style("pointer-events", "none")
            .attr("x", (d) => { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("y", margin.top)
            .attr("width", (d) => { return xScaleBand.bandwidth() })
            .attr('fill', function (d) { return (d.rain === null ? '#084594' : '#084594') })
            .attr('opacity', function (d) { return (d.rain === null ? 0 : d.rain / 100) })
            .attr("height", height - margin.bottom - margin.bottomHeight - margin.offset - margin.top);

        // Striped down
        box.selectAll(".bar")
            .data(data.chart)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .style("pointer-events", "none")
            .attr("x", (d) => { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("y", (d) => { return yScaleR(d.rain) + height - margin.bottom - yScaleR(0) })
            .attr("width", (d) => { return xScaleBand.bandwidth() })
            .attr("height", (d) => { return yScaleR(0) - yScaleR(d.rain) })
            .attr('fill', '#084594');

        const box2 = clip
            .append("g")
	    .attr("id", "box2");
        const box3 = clip
            .append("g")
	    .attr("id", "box3");

	/* Regen Vorhersage */
	if (cast !== undefined)
	{
        box2.selectAll(".bar")
            .data(cast.forecasts)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .style("pointer-events", "none")
            .attr("x", (d) => { return xScaleBand(d3.timeParse("%Y-%m-%dT%H:%M:%S")(d.date)) })
            .attr("y", (d) => { return yScaleR(d.rainFrom) + height - margin.bottom - yScaleR(0) })
            .attr("width", (d) => { return xScaleBand.bandwidth() })
            .attr("height", (d) => { return yScaleR(0) - yScaleR(d.rainFrom) })
            .attr('fill', '#ff0000');

        box3.selectAll(".bar")
            .data(cast.forecasts)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .style("pointer-events", "none")
            .attr("x", (d) => { console.log(cast); return xScaleBand(d3.timeParse("%Y-%m-%dT%H:%M:%S")(d.date)) })
            .attr("y", (d) => { return yScaleR(d.rainTo) + height - margin.bottom - yScaleR(0)})
            .attr("width", (d) => { return xScaleBand.bandwidth() })
            .attr("height", (d) => { return yScaleR(d.rainFrom) - yScaleR(d.rainTo) })
	    .attr("opacity", 0.8)
            .attr('fill', '#ffcccc');
        }
	
        svg.append('line')
            .attr('id', 'cursorLine')
            .attr('stroke', 'grey')
            .attr('display', null);

        var dotgroup = svg.append("g")
	    .attr('id', 'dotgroup');

        /*  actual */
        dotgroup.append("circle")
            .attr('id', 'dot1')
            .style('display', 'none')
            .style("stroke", "grey")
            .style("fill", 'black')
            .attr("r", 3);

        createArea();

        var tooltip = svg.append("g")
            .attr('id', 'tooltip')
            .style("pointer-events", "none");

        //var path =
        tooltip.append("path")
            .attr('id', 'pathtool')
            .data([,])
            .attr("class", "shadow")
            .attr("fill", "white")
            .attr("stroke", 'black')
            .attr("stroke-width", 1.5)
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round");

        var tooltiptext = tooltip.append("text")
            .attr('id', 'tooltiptext')
            .style("pointer-events", "none");

        // Date
        tooltiptext
            .append("tspan")
            .attr('id', 'date')
            .attr("x", 15)
            .attr("y", 0)
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value')
            .attr("x", 25)
            .attr("y", 20)
            .attr("stroke", 'black')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'delta')
            .attr("x", 25)
            .attr("y", 40)
            .attr("stroke", 'black')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');


        xlabel(svg, t("year"), width / 2, height - 5);

        ylabel(svg, t("temperature"), - height * 0.3, 15);
        ylabel(svg, t("rain"), -height * 0.85, 15);

        // Image
        logo(svg);

    }

    function createArea() {
        //const svg = d3.select(svgRef.current);

        //const X = d3.map(data, (d) => { return d3.timeParse("%Y-%m-%d")(d.date) });

        //const Y2 = d3.map(data, (d) => { return d.longterm });
        //const I = d3.range(data.length);

        //const defined = (d, i) => Y1[i];

        // Obere Fläche
        //const Y1 = 
        d3.map(data.chart, (d) => { return (yScaleT(d.night) > yScaleS(d.rad)) && (d.rad < day) && (d.night > night); });
        //const defined = (d, i) => Y1[i];

        /* Aufbauend */
        /* 3. Untere Grenze: Temperatur */
        clip.append('clipPath')
            .datum(temperature) //.filter(defined)
            .attr('id', '_clip')
            .append('path')
            .attr("d", d3.area()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScaleT(d.night === null ? 24 : d.night) })
                .y1(function (d) { return yScaleT(-2.5) })
            );

        
        /* 1. Grenzwerte */
        clip.append('clipPath')
            .attr('id', '_clip3')
            .attr('clip-path', "url(#_clip)")
            .append('rect')
            .attr('x', margin.left)
            .attr('y', yScaleT(25))
            .attr('width', width - margin.right)
            .attr('height', yScaleT(night) - yScaleT(25));


        clip.append("path")
            .datum(radiation) //.filter(defined)
            .attr('class', 'area1')
            .attr('clip-path', "url(#_clip3)")
            .attr("fill", "pink")
            .attr('opacity', 0.5)
            .attr("stroke-width", 0)
            .attr("d", d3.area()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScaleS(d.rad === null ? 24 : d.rad) })
                .y1(function (d) { return yScaleT(-2.5) })
            )


        clip.append('clipPath')
            .attr('id', '_clip5')
            .attr('clip-path', "url(#_clip)")
            .append('rect')
            .attr('x', margin.left)
            .attr('y', yScaleT(25))
            .attr('width', width - margin.right)
            .attr('height', yScaleT(night) - yScaleT(25));


        if (behandlung !== undefined) {
            clip.append("path")
                .datum(behandlung) //.filter(defined)
                .attr('class', 'area1')
                .attr('clip-path', "url(#_clip5)")
                .attr("fill", "red")
                .attr('opacity', 0.5)
                .attr("stroke-width", 0)
                .attr("d", d3.area()
                    .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                    .y0(function (d) { return yScaleS(d.rad) })
                    .y1(function (d) { return yScaleT(-2.5) })
                )
        }

    }

    function pointermoved(event) {

        /*const X = d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date));
        const I = d3.map(data, (_, i) => i);
        const i = d3.bisectCenter(X, xScale.invert(d3.pointer(event)[0] - 5));

        // cursorline
        const svg = d3.select(svgRef.current);
        svg.selectAll('#cursorLine')
            .style('display', null)
            .attr('x1', d3.pointer(event)[0] - 5)
            .attr('x2', d3.pointer(event)[0] - 5)
            .attr('y1', 0)
            .attr('y2', height - margin.bottom);

        svg
            .selectAll('#dot1')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScale(data[i].actual));
        svg
            .selectAll('#dot2')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScale(data[i].longterm));

        // Textbox with values
        var tooltiptext = svg.select("#tooltiptext");
        tooltiptext.attr("transform", `translate(${0}, ${30 - 0})`); // Text

        const { x, y, width: w, height: h } = tooltiptext.node().getBBox();

        var path = svg.select("#pathtool");

        if (xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data[i].date)) === width - margin.right) {
            svg.select("#tooltip").style("display", 'none');
        }
    let rb = xScale(X[i])> width-100 ? width-100 : xScale(X[i]);
        svg.select("#tooltip")
            .style("display", null)
            .attr("text-anchor", "middle")
            .attr("transform", `translate(${rb},0)`);

        path
            .style('display', null)
            .transition()
            .duration(100)
            .attr('d', `M 0 20 l 10 -10 H${20 + w} V${25 + h} H10 V25 Z`);

        svg.select("#date")
            .transition()
            .duration(200)
            .text(data[i].date);

        svg.select("#value")
            .transition()
            .duration(200)
            .text(Math.round(data[I[i]].actual * 10) / 10 + " °C");

        svg.select("#delta")
            .transition()
            .duration(200)
            .text('ΔT ' + Math.round((data[I[i]].actual - data[I[i]].longterm) * 10) / 10 + " °C");

        let x_pos = d3.pointer(event)[0];
        if (x_pos < 400)
            {
                //svg.selectAll('#legend').attr('display', 'none');
                svg.selectAll('#legend').attr('opacity', 0.1)
            }
        else
        {
            //svg.selectAll('#legend').attr('display', null);
            svg.selectAll('#legend').attr('opacity', 1)
        }*/

    }

    function pointerleft() {
        const svg = d3.select(svgRef.current);

        svg.selectAll('#cursorLine')
            .style('display', 'none');

        svg
            .selectAll('#dot1')
            .style('display', 'none')

        svg.select("#tooltip")
            .style("display", 'none');

        svg.selectAll('#legend').attr('opacity', 1)
    }

    var idleTimeout
    function idled() { idleTimeout = null; }



    function updateChart(event) {

        const svg = d3.select(svgRef.current);

        var extentX = event.selection
        var t1;

        //let off_set = (width - margin.left - margin.right) / 365 * 2;

        if (!extentX) {
            if (!idleTimeout) return idleTimeout = setTimeout(idled, 350); // waiting a bit

            //var min = d3.min(data, (d) => { return d.date })
            //var max = d3.max(data, (d) => { return d.date })

            xScale.domain(d3.extent(data, function (d) { return d3.timeParse("%Y-%m-%d")(d.date) }));
            xScaleBand
                .domain(d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date)))
                .range([margin.left, width - margin.right]).padding(0);
        }
        else {
            var from = xScale.invert(extentX[0]);
            var to = xScale.invert(extentX[1]);

            //console.log(xScale.invert(extentX[0]), xScale.invert(extentX[1]));

            xScale.domain([xScale.invert(extentX[0]), xScale.invert(extentX[1])]);

            var t = d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date));
            t1 = t.filter(item => item >= from && item <= to);
            xScaleBand.domain(t1);

            // This remove the grey brush area as soon as the selection has been done
            svg.select(".brush").call(brush.move, null)

        }


        // Bottom
        xAxis.transition()
            .duration(1000)
            .call(d3.axisBottom(xScale));

        // Bottom grid
        xGrid.transition()
            .duration(1000)
            .call(d3.axisBottom(xScale).tickSize(- height + margin.top + margin.bottom, 0).tickFormat(""));

        // Linie längjähriges Mittel
        /*svg.selectAll("#path1")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale(d.longterm) }));

        // Linie ausgewähltes Jahr
        svg.selectAll("#path2")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale(d.actual) }));

        svg.selectAll(".area1")
            .transition()
            .duration(1000)
            .attr("d", d3.area()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(d.longterm < d.actual ? d.longterm : d.actual) })
                .y1(function (d) { return yScale(d.actual) })
            )

        svg.selectAll(".area2")
            .transition()
            .duration(1000)
            .attr("d", d3.area()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(d.longterm > d.actual ? d.longterm : d.actual) })
                .y1(function (d) { return yScale(d.actual) })
            )*/

        // react
        svg.selectAll(".bar")
            .transition()
            .duration(1000)
            .attr("x", d => { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("width", () => { return xScaleBand.bandwidth() });
        svg.selectAll(".bar2")
            .transition()
            .duration(1000)
            .attr("x", d => { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("width", () => { return xScaleBand.bandwidth() });



    }

    const nightUp = (() => {
        setNight((prev) => { return prev + 1 });
    })

    const nightDown = (() => {
        setNight((prev) => { return prev - 1 });
    })

    const dayUp = (() => {
        setDay((prev) => { return prev + 1 });
    })

    const dayDown = (() => {
        setDay((prev) => { return prev - 1 });
    })

    const red1Up = (() => {
        setRed1((prev) => { return prev + 5 });
    })

    const red1Down = (() => {
        setRed1((prev) => { return prev - 5 });
    })

    const red2Up = (() => {
        setRed2((prev) => { return prev + 5 });
    })

    const red2Down = (() => {
        setRed2((prev) => { return prev - 5 });
    })

    return (
        <>
            <div className="stagearea grid p-2">
                <Stack spacing={1}>

                    <StackRow title="" component={<StationButton />} />

                    <Item>
                        <DatePicker
                            title="year"
                            value={fromyjs}
                            callback={handleSelector}
                            view={["year"]}
                            format="YYYY"
                        />
                    </Item>
                    <Item>
                        <Box
                            sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                            className="p-1">
                            <Typography component={'h5'} sx={{ verticalAlign: "middle" }}>
                                {t("nachttemperatur")}
                            </Typography>

                            <SpinButton value={night} up={nightUp} down={nightDown} />
                        </Box>
                    </Item>
                    <Item>
                        <Box
                            sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                            className="p-1">
                            <Typography component={'h5'} sx={{ verticalAlign: "middle" }}>
                                {t("globalstrahlung")}
                            </Typography>

                            <SpinButton value={day} up={dayUp} down={dayDown} />
                        </Box>
                    </Item>
                    <Item>
                        <Box
                            sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                            className="p-1">
                            <Typography component={'h5'} sx={{ verticalAlign: "middle" }}>
                                {t("reduktion")} 1-5 {t("days")}
                            </Typography>

                            <SpinButton value={red1} up={red1Up} down={red1Down} />
                        </Box>
                    </Item>
                    <Item>
                        <Box
                            sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                            className="p-1">
                            <Typography component={'h5'} sx={{ verticalAlign: "middle" }}>
                                {t("reduktion")} 6-10 {t("days")}
                            </Typography>

                            <SpinButton value={red2} up={red2Up} down={red2Down} />
                        </Box>
                    </Item>
                </Stack>


                <div id="svgchart">
                    {data === undefined ? <CircularProgress /> : <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>}
                </div>

            <Stack spacing={1}>
                    <Item>
                        <DatePicker
                            title="behandlung"
                            value={frombjs}
                            callback={handleBehandlung}
                            view={["year", "month", "day"]}
                            format="DD MMM YYYY"
                        />
                    </Item>

                </Stack>

                <Grid container flexDirection={"row-reverse"}>

                    <Grid item>
                        <GraphicButton object="svg_chart" filename={t('temperature_mids')} />
                    </Grid>
                    {/*
                    <Grid item className="p-2">
                        <ExcelButton callback={downloadExcel} />
                    </Grid>
		    */}

                </Grid>
            </div>
        </>
    );

}
