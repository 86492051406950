import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';
import { GraphContext } from '../routes/GraphContext';

import exportDiagramm from "./ExportSVG";

export default function GraphicButton ({object, filename}) {

    const { t, i18n } = useTranslation();

    const [ parameter, setParameter ] = useContext (GraphContext);

    const exportDiagramm = (() => {
    
        console.log ("Downloading ...", object);
        const svg = document.getElementById(object);
    
        if (svg === null)
            return;
    
        //get svg source.
        var serializer = new XMLSerializer();
        var source = serializer.serializeToString(svg);
    
        //add name spaces.
        if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }
    
        //add xml declaration
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
    
        //convert svg source to URI data scheme.
        var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);
    
	let date = new Date();
	const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
	var station = parameter.station_name === undefined ? "Laimburg" : parameter.station_name;
        saveAs(url, station + "-" + filename + "-" +  formattedDate + ".svg");
        });
 
    return (
        <>
        <Button 
            onClick={exportDiagramm}
            color="primary"
            style={{
                borderRadius: 2,
                backgroundColor: "#65626",//"#656263", #B1914E
                margin: "10px",
                fontSize: "14px"
                }}
                className="m-2"
                variant="contained"
            >
                {t("export_graphic")}
            </Button>
        </>
    )

}
