import * as d3 from "d3";
import { useContext, useState } from "react";
import { GraphContext } from "../routes/GraphContext";
import Button from '@mui/material/Button';

export default function ToggleButton ({active, index, setting, text}) {

    const [parameter, setParameter] = useContext(GraphContext);

    const [ ac, setAc ] = useState(active);

    const onClick = (() => {
        
        setAc (!ac);
        //active = !active;
        setting (index, !ac, text);
    })



return (

    <>
        <Button   
            sx={{fontSize: '12x', padding: '2px'}}
            variant={ac ? "contained" : "outlined"} 
            active={ac}
            onClick={onClick}
        >
            {text}
        </Button>
    </>

)

}