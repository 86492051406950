// Stationinfo.js
import * as d3 from "d3";
import { useState, useEffect, useRef, useContext } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { linechart } from "../d3linechart";
import { GraphContext } from "../../GraphContext";
import { preferences } from "../../../preferences";

import SensorButton from "../../../components/SensorButton.js";
import GraphicButton from '../../../components/GraphicButton';

import { setup } from '../../../components/d3/charts/genchart.js';
import { xlabel, ylabel, logo } from '../../../components/d3/charts/chart.js';

import { } from '../brushX.js';

export default function Sunchart (  ) {

        const { t, i18n } = useTranslation();
	const svgRef = useRef();

	const [data, setData] = useState([]);
	const [ ready, setReady ] = useState(false);

	const [parameter, setParameter] = useContext(GraphContext);
	const [ sensors, setSensors ] = useState ([]);

	const getWidth = () => window.innerWidth 
	  	|| document.documentElement.clientWidth 
  		|| document.body.clientWidth;

	const [resize, setResize] = useState(getWidth());

	const resizeHandler = (() => {
		var r = getWidth();
	    setResize(r);
	});

	window.addEventListener('resize', resizeHandler);

	function draw (data) {
		
		const svg = d3.select(svgRef.current);
		setup (svg, data, sensors, parameter.level, 20, 50, 60, 20, t);
		logo (svg);
	}

	useEffect (() => {
		setReady (false);
		let query = preferences.address+"/query/charts/valuesSun.php?station="+parameter.station+"&from="+parameter.from+"&to="+parameter.to+"&level="+parameter.level;
		var dat = d3.json(query)
			.then ((data) => {
				data.timeline.map((d, i) => {
						d = d3.timeParse("%Y-%m-%d %H:%M:%S")(d);	
			    });
			    data.minTime= d3.timeParse("%Y-%m-%d %H:%M:%S")(data.minTime);
			    data.maxTime= d3.timeParse("%Y-%m-%d %H:%M:%S")(data.maxTime);
			    setData(data);
                            data.sensors.map((item) => {
                                    var s = sensors.filter(obj => {return obj.cat === item.cat})
                                    if (s.length > 0 ) item.active = s[0].active; else item.active=true;
                            })
			    if (data.sensors.length > 0)
			    {
				    setSensors (data.sensors);
				    setReady (true);
			    }

			});
	}, [parameter]);


	useEffect (() => {
	    (data !== null && sensors !== undefined) && (sensors.length > 0) && draw(data, sensors);
	}, [i18n.language, resize, sensors]);


	const handleClick = (index) => () => {
		let s = sensors[index];
		s.active = !s.active;
		setSensors ([...sensors]);
	};

	return (
		<>
	    {ready &&
		<>
		<Box sx={{ flexGrow: 1, padding: '10px' }}>
		<Grid container spacing={0}>
		
		<Grid item container xs={12} sx={{ justifyContent: 'space-between' }}>
		    <Typography variant="h6">
			{t("sun")}
		    </Typography>
		</Grid>
		
		<Grid item xs={12}>

			<div className="flex-row">
				{data.sensors !== undefined &&
					data.sensors.map ((sensor, index) => 
					{
						return (
							<SensorButton key={index} sensor={sensors[index]} svgref="tempchart" index={index} callback={handleClick(index)} />
						)
					})
				}
			</div>

			<div id="svgchart">
				<svg ref={svgRef} id="sunchart" className="d3chart"></svg>
			</div>

		<GraphicButton object={"sunchart"} filename={t("sun")}/>
		</Grid>
		
		</Grid>
		</Box>
		</>
		}
		</>
	);
}
