import * as d3 from "d3";

import * as React from 'react';
import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { getCurrentYear } from '../../js/datetime';

import { GraphContext } from "../GraphContext";
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from '@mui/material/styles';
import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import { xlabel, ylabel, logo } from '../../components/d3/charts/chart';
import { legend, addLine, addArea } from '../../components/d3/legend/legend';

import ComboBox from '../../components/ComboBox';
import DatePicker from '../../components/DatePicker';
import SensorComboBox from '../../components/SensorComboBox';
import Title from "../../components/Title";

import StackRow from '../../components/StackRow';
import StationButton from '../../components/StationButton';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

export const Saturation = () => {

    const { trackPageView } = useMatomo()
    const location = useLocation();

    const [t, i18n] = useTranslation();
    const [parameter] = useContext(GraphContext);
    const [sensors, setSensors] = useState();
    const [fromdjs, setFromdjs] = useState(dayjs());
    const [data, setData] = useState();

    const [opensnack, setOpensnack] = useState(false);

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());

    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const [year, setYear] = useState(getCurrentYear());
    const [classification, setClassification] = useState(1);
    const [sensor, setSensor] = useState(1);
    const [sensorstring, setSensorstring] = useState(); // "airtemp2m"

    const [ methode, setMethode ] = useState(1);

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelector = (event) => {

        setFromdjs(event);
        let date = event.$d;
        setYear(1900 + date.getYear());
    }

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Boxplot Dampfdruck Defizid', // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined) {
            switch (classification) {
                case 1:
                    draw2(svg);
                    break;
                case 2:
                case 3:
                    draw(svg)
                    break;
            }
        }

    }, [methode, resize, data]); // 

    /* Sensors */
    useEffect(() => {

        let station = parameter.station;
        var url = preferences.address + '/rest/sensors.php?station=' + station;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                data.map((sensor) => {
                    sensor.main = true;
                    sensor.min = false;
                    sensor.max = false;
                    return 0;
                })

                setSensors(data);
                setSensorstring("airtemp2m");
                setSensor(2);
            });
    }, [parameter])

    useEffect(() => {

        clear();
        setData();

        if (sensorstring !== undefined) {
            switch (classification) {
                case 1:
                    d3.json(preferences.address + "/query/models/saturation/custom/get.php?station=" + parameter.station + "&sensor=" + sensorstring + "&year=" + year)
                        .then((data) => {
                            setData(data);
                        });
                    break;
                case 2:
                    d3.json(preferences.address + "/query/models/saturation/season/get.php?station=" + parameter.station + "&sensor=" + sensorstring + "&year=" + year)
                        .then((data) => {
                            setData(data);
                        });
                    break;
                case 3:
                    d3.json(preferences.address + "/query/models/saturation/month/get.php?station=" + parameter.station + "&sensor=" + sensorstring + "&year=" + year)
                        .then((data) => {
                            setData(data);
                        });
                    break;
                default:
                    break;
            }
        }
    }, [i18n.language, parameter, year, classification, sensorstring]);

    const downloadExcel = () => {

        setOpensnack(true);

        var url;
        switch (classification) {
            case 1:
                url = preferences.address + '/query/models/saturation/custom/excel.php?station=' + parameter.station + "&sensor=" + sensorstring + "&year=" + year;
                break;
            case 2:
                url = preferences.address + '/query/models/saturation/season/excel.php?station=' + parameter.station + "&sensor=" + sensorstring + "&year=" + year;
                break;
            case 3:
                url = preferences.address + '/query/models/saturation/month/excel.php?station=' + parameter.station + "&sensor=" + sensorstring + "&year=" + year;
                break;
            default:
                break;
        }


        let block = {};
        block.station = parameter.station;
        block.sensor = sensorstring;
        block.year = year;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                setOpensnack(false);

            });

    }


    function clear() {

        // Clear
        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove();
        setData();

    }

    function draw(svg) {

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 10,
            right: 20,
            left: 80,
            bottom: 40
        }; // margin of chart


        svg
            .attr("viewBox", [0, 0, svg.style('width'), svg.style('height')])
            .style("overflow", "visible")
            .style("-webkit-tap-highlight-color", "transparent")
            .on("pointerenter pointermove", pointermoved)
            .on("pointerleave", pointerleft)
            .on("touchstart", event => event.preventDefault());

        // Getting width
        let width = parseInt(svg.style("width"));
        //console.log (width);

        let height = 400;
        //let width = svg.attr("width");

        let inner_width = width - margin.right - margin.left;
        //let inner_height = height - margin.top - margin.bottom;

        let min = 0;
        let max = 0;

        if (data !== undefined) {
            let keys = Object.keys(data.seasons);

            min = d3.min(keys.map((l) => { return d3.min(data.seasons[l].map((d) => { return d.val })) }));
            let minC = d3.min(data.current.map((d) => { return d.val }));
            min = d3.min([0, minC, min]);
            max = d3.max(keys.map((l) => { return d3.max(data.seasons[l].map((d) => { return d.val })) }));
            let maxC = d3.max(data.current.map((d) => { return d.val }));
            max = d3.max([d3.max(data.current.map((d) => { return d.val })), max, maxC]);
        }

        svg
            .attr("width", width)
            .attr("height", height);

        //let seasons = data.keys;
        // Create scale

        let xScaleBand = d3.scaleBand()
            .domain(data.keys) // seasons
            .range([0, width - margin.right - margin.left])
            .padding(0.1);

        let xScaleBandLabel = d3.scaleBand()
            .domain(data.labels.map((d) => { return t(d) })) // seasons
            .range([0, width - margin.right - margin.left])
            .padding(0.1);

        //let s = xScaleBand.bandwidth() / 2;

        /*let xScaleLinear = d3.scaleLinear()
            .domain([d3.min(data.keys), d3.max(data.keys)])
            .range([margin.left + s, width - s - margin.right]);*/

        // Add scales to axis
        var x_axis = d3.axisBottom()
            .scale(xScaleBandLabel);

        // Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + (height - margin.bottom) + ")")
            .call(x_axis);

        // Create scale
        var yScale = d3.scaleLinear()
            .domain([min, max])
            .range([height - margin.bottom, margin.top]);

        // Add scales to axis
        var y_axis = d3.axisLeft()
            .scale(yScale)

        // Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .call(y_axis.ticks(10, "0.1f").tickFormat((x) => { return x + " " + data.unit }));

        // Grid horizontal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ", 0)")
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(y_axis.tickSize(-inner_width, 0).tickFormat(""));

        // rectangle for the main box

        let s = xScaleBand.bandwidth() / 2;

        let datagroup = svg.append('g').attr('id', 'datagroup');
        let dataline = svg.append('g').attr('id', 'dataline');
        // Show the main vertical line

        datagroup
            .selectAll("vertLines")
            .data(data.seasons)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) })
            .attr("y1", function (d) { return yScale(d3.max(d.map((e) => { return e.val }))) })
            .attr("y2", function (d) { return yScale(d3.min(d.map((e) => { return e.val }))) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)

        datagroup
            .selectAll("upperLines")
            .data(data.seasons)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) - s / 4 })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + s / 4 })
            .attr("y1", function (d) { return yScale(d3.max(d.map((e) => { return e.val }))) })
            .attr("y2", function (d) { return yScale(d3.max(d.map((e) => { return e.val }))) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)

        datagroup
            .selectAll("lowerLines")
            .data(data.seasons)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) - s / 4 })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + s / 4 })
            .attr("y1", function (d) { return yScale(d3.min(d.map((e) => { return e.val }))) })
            .attr("y2", function (d) { return yScale(d3.min(d.map((e) => { return e.val }))) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)


        const colors = ["#641e16", "#78281f", "#512e5f", "#154360", "#1b4f72", "#0e6251", "#0b5345", "#0b5345",
            "#7d6608", "#7e5109", "#784212", "#6e2c00", "#7b7d7d", "#626567", "#4d5656", "#424949", "#1b2631"];

        datagroup
            .selectAll("boxes")
            .data(data.seasons)
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", function (d, index) { return xScaleBand(index + 1) })
            .attr("y", function (d) { return yScale(d3.quantile(d.map((e) => { return e.val }), 0.75)) })
            .attr("height", function (d) { return (yScale(d3.quantile(d.map((e) => { return e.val }), 0.25)) - yScale(d3.quantile(d.map((e) => { return e.val }), 0.75))) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", colors[sensor])
            .attr("rx", 5);

        datagroup
            .selectAll("horLines")
            .data(data.seasons)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + 2 * s })
            .attr("y1", function (d) { return yScale(d3.quantile(d.map((e) => { return e.val }), 0.5)) })
            .attr("y2", function (d) { return yScale(d3.quantile(d.map((e) => { return e.val }), 0.5)) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)

        const l = [];
        //console.log (data.seasons);
        try {
            data.seasons.map((e, i) => {
                e.map((j, k) => { let t = {}; t.season = j.season; t.val = j.val; l.push(t); return 0; }); return 0;
            });
        } catch (err) { console.log(err); }

        var jitterWidth = 50;
        datagroup
            .selectAll("points")
            .data(l)
            .enter()
            .append("circle")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("cx", function (d, index) { return xScaleBand(d.season) - jitterWidth / 2 + Math.random() * jitterWidth })
            .attr("cy", function (d, index) { return yScale(d.val) })
            .attr("r", 2)
            .style("fill", "white")
            .attr("stroke", "blue");

        // Add the line current year
        dataline.append("path")
            .datum(data.current)
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x(function (d, index) { return xScaleBand(d.season) })
                .y(function (d) { return yScale(d.val) })
            )

        dataline.selectAll("circles")
            .data(data.current)
            .enter()
            .append("circle")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("fill", "white")
            .attr("stroke", "orange")
            .attr("cx", function (d) { return xScaleBand(d.season) })
            .attr("cy", function (d) { return yScale(d.val) })
            .attr("r", 4)

        xlabel(svg, t(data.xLabel), width / 2, height - 5);

        ylabel(svg, t(data.yLabel), -height * 0.5, 15);
        logo(svg)

    }

    const handleChangeClassification = (event) => {
        setClassification(event.target.value);
    };

    const handleChangeMethode = (event) => {
        setMethode(event.target.value);
        //console.log (event.target.value);
    };


    let c = [t("year"), t("seasons"), t("months")];

    const action = (
        <React.Fragment>
        </React.Fragment>
    )


    /*  */
    let xScale;
    let yScale;
    let yScale2;
    let brush;

    let height = 400;
    const margin = {
        top: 10,
        right: 100,
        left: 80,
        bottom: 40
    }; // margin of chart

    let min = 0;
    let max = 0;

    let xAxis;
    let xGrid;

    let width;
    let path;

    let tooltiptext;

    function idled() { idleTimeout = null; }

    function draw2(svg) {

        var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);

        // Clear
        svg.selectAll("*").remove();

        // Getting width
        width = parseInt(svg.style("width"));

        let inner_width = width - margin.right - margin.left;
        //let inner_height = height - margin.top - margin.bottom;

        min = 0;
        //max = d3.max(data.hours.map((d) => d.val));
        max = d3.max([d3.max(data.calc.map((d) => d.pm)), d3.max(data.calc.map((d) => d.ca)), d3.max(data.calc.map((d) => d.cr)), d3.max(data.calc.map((d) => d.pt)), d3.max(data.calc.map((d) => d.hargreaves))]);


        // SVG
        svg
            .attr("width", width) // + margin.left + margin.right
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


        // setup SVG                                                                                                                        
        svg
            .attr("viewBox", [0, 0, svg.style('width'), svg.style('height')])
            .style("overflow", "visible")
            .style("-webkit-tap-highlight-color", "transparent")
            .on("pointerenter pointermove", pointermoved)
            .on("pointerleave", pointerleft)
            .on("touchstart", event => event.preventDefault());

        // Getting width                                                                                                                    
        width = parseInt(svg.style('width'));

        // SVG                                                                                                                              
        svg
            .attr("width", width) // + margin.left + margin.right                                                                                       .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // Add X axis --> it is a date format                                                                                               
        xScale = d3.scaleTime()
            .domain([d3.timeParse("%Y-%m-%d")(year + "-01-01"), d3.timeParse("%Y-%m-%d")((year + 1) + "-01-01")])
            .range([margin.left, width - margin.right])

        xAxis = svg.append("g")
            .attr("transform", "translate(0," + (height - margin.bottom) + ")")
            .call(d3.axisBottom(xScale))
            .style("font-size", "12px");

        // X grid
        let bottomScale = d3.axisBottom(xScale);
        xGrid = svg.append("g")
            .attr('id', 'xgrid')
            .attr('transform', 'translate(0 ,' + (height - margin.bottom) + ')')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(bottomScale.tickSize(-height + margin.bottom + margin.top, 0).tickFormat(""));

        // Y grid 
        yScale = d3.scaleLinear()
            .domain([0, 5])
            .range([height - margin.bottom, margin.top]);


        let axisLeft = d3.axisLeft(yScale);
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ', 0)')
            .call(axisLeft.ticks(10, "0.1f").tickFormat((x) => { return x + " kPa" }))
            .style("font-size", "12px");
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(axisLeft.tickSize(-width + margin.left + margin.right, 0).tickFormat(""));

        yScale2 = d3.scaleLinear()
            .domain([0, 10])
            .range([height - margin.bottom, margin.top]);
        let axisRight = d3.axisRight(yScale2);
        svg.append("g")
            .attr('transform', 'translate(' + (width - margin.right) + ', 0)')
            .call(axisRight.ticks(10, "0.1f").tickFormat((x) => { return x + " mm/d" }))
            .style("font-size", "12px");
        /*svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(axisLeft.tickSize(-width + margin.left + margin.right, 0).tickFormat(""));*/

        // brush                                                                                                                            
        brush = d3.brushX()
            .extent([[margin.left, 0], [width - margin.right, height - margin.bottom]])
            .on("end", updateChart); // brushended  
        svg.append("g")
            .attr('class', 'brush')
            .call(brush);

        // Clip
        let clip = svg.append('g')
            .attr("clip-path", "url(#clip)");

        svg.append("defs")
            .append("svg:clipPath")
            .attr('id', 'clip')
            .append("svg:rect")
            .attr("x", margin.left)
            .attr("y", 0)
            .attr("width", width - margin.left - margin.right)
            .attr("height", height);


        // Add the lines group                                                                                                             
        const lines = clip
            .append("g")
            .attr('id', 'lines');

        lines.append("path")
            .datum(data.hours)
            .attr('id', 'pathH')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "red")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.season)) })
                .y((d, i) => { return yScale(d.val) })
                .defined(((d) => { return d.val !== null; }))
            );

        /* VPA */
        lines.append("path")
            .datum(data.days)
            .attr('id', 'path_v1')
            .attr('class', "line")
            .attr("display", methode===4 ? "" : "none")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.season)) })
                .y((d, i) => { return yScale(d.val) })
                .defined(((d) => { return d.val !== null; }))
            );

        lines.append("path")
            .datum(data.days_calc)
            .attr('id', 'path_v2')
            .attr('class', "line")
            .attr("display", methode===4 ? "" : "none")
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.season)) })
                .y((d, i) => { return yScale(d.val) })
                .defined(((d) => { return d.val !== null; }))
            );

        /* Penman */
        lines.append("path")
            .datum(data.calc)
            .attr('id', 'path1')
            .attr('class', "line")
            .attr("display", methode===1 ? "" : "none")
            .attr("fill", "none")
            .attr("stroke", d3.schemeSet1[1])
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d, i) => { return yScale2(d.pm) })
                .defined(((d) => { return d.pm !== null; }))
            );

        /* Radiative comp */
        lines.append("path")
            .datum(data.calc)
            .attr('id', 'path2')
            .attr('class', "line")
            .attr("display", methode===5 ? "" : "none")
            .attr("fill", "none")
            .attr("stroke", d3.schemeSet1[2])
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d, i) => { return yScale2(d.cr) })
                .defined(((d) => { return d.cr !== null; }))
            );

        /* Aerodynamic comp */
        lines.append("path")
            .datum(data.calc)
            .attr('id', 'path3')
            .attr('class', "line")
            .attr("display", methode===5 ? "" : "none")
            .attr("fill", "none")
            .attr("stroke", d3.schemeSet1[3])
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d, i) => { return yScale2(d.ca) })
                .defined(((d) => { return d.ca !== null; }))
            );
        /* Priestley Taylor  */
        lines.append("path")
            .datum(data.calc)
            .attr('id', 'path4')
            .attr('class', "line")
            .attr("display", methode===2 ? "" : "none")
            .attr("fill", "none")
            .attr("stroke", d3.schemeSet1[4])
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d, i) => { return yScale2(d.pt) })
                .defined(((d) => { return d.pt !== null; }))
            );

        /* Haergreaves */
        lines.append("path")
            .datum(data.calc)
            .attr('id', 'path5')
            .attr('class', "line")
            .attr("display", methode===3 ? "" : "none")
            .attr("fill", "none")
            .attr("stroke", d3.schemeSet1[6])
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d, i) => { return yScale2(d.hargreaves) })
                .defined(((d) => { return d.hargreaves !== null; }))
            );

        logo(svg);

        let legend = svg.append("g")
            .attr("id", "legend")
            .attr('transform', 'translate(' + (width - 350) + ' ,0)');

        /*
        addLine(svg, 0, 5, d3.schemeSet1[1], "Penman Monteith", "#path1");
        addLine(svg, 1, 5, d3.schemeSet1[2], "Radiative component", "#path2");
        addLine(svg, 2, 5, d3.schemeSet1[3], "Aerodynamic component", "#path3");
        addLine(svg, 3, 5, d3.schemeSet1[4], "Priestley Taylor", "#path4");
        addLine(svg, 4, 5, d3.schemeSet1[6], "Hargreaves", "#path5");
        addLine(svg, 5, 5, "black", "VPD Basis Tageswerte", "#path_v1");
        addLine(svg, 6, 5, "steelblue", "VPD Basis Stundenwerte", "#path_v2");
        */

        svg.append('line')
            .attr('id', 'cursorLine')
            .attr('stroke', 'grey')
            .attr('display', null);

        var dotgroup = svg.append("g").attr('id', 'dotgroup');

        /*  actual */
        dotgroup.append("circle")
            .attr('id', 'dot1')
            .style('display', 'none')
            .style("stroke", "red")
            .style("fill", 'red')
            .attr("r", 3);

        /* Penman ...  */
        dotgroup.append("circle")
            .attr('id', 'dot2')
            .attr("display", methode===1 ? "" : "none")
            .style("stroke", d3.schemeSet1[1])
            .style("fill", d3.schemeSet1[1])
            .attr("r", 3);

        /* Priestley Taylor ...  */
        dotgroup.append("circle")
            .attr('id', 'dot3')
            .attr("display", methode===2 ? "" : "none")
            .style("stroke", d3.schemeSet1[4])
            .style("fill", d3.schemeSet1[4])
            .attr("r", 3);

        /* Haergreaves ...  */
        dotgroup.append("circle")
            .attr('id', 'dot4')
            .attr("display", methode===3 ? "" : "none")
            .style("stroke", d3.schemeSet1[6])
            .style("fill", d3.schemeSet1[6])
            .attr("r", 3);


        var tooltip = svg.append("g")
            .attr('id', 'tooltip')
            .style("pointer-events", "none");

        path = tooltip.append("path")
            .attr('id', 'pathtool')
            .data([,])
            .attr("class", "shadow")
            .attr("fill", "white")
            .attr("stroke", 'black')
            .attr("stroke-width", 1.5)
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round");

        tooltiptext = tooltip.append("text")
            .attr('id', 'tooltiptext')
            .style("pointer-events", "none");

        tooltiptext
            .append("tspan")
            .attr('id', 'date')
            .attr("x", 15)
            .attr("y", 0)
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');
        tooltiptext
            .append("tspan")
            .attr("class", "bold")
            .attr('id', 'value1')
            .attr("x", 15)
            .attr("y", 20)
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');
        tooltiptext
            .append("tspan")
            .attr('id', 'value2')
            .attr("class", "bold")
            .attr("x", 15)
            .attr("y", 40)
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

    }

    function pointermoved(event) {

        if (classification > 1)
            return;

        const X = d3.map(data.hours, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d.season));
        const I = d3.map(data.hours, (_, i) => i);
        const i = d3.bisectCenter(X, xScale.invert(d3.pointer(event)[0] - 5));

        const X2 = d3.map(data.days, d => d3.timeParse("%Y-%m-%d")(d.season));
        const I2 = d3.map(data.days, (_, i2) => i2);
        const i2 = d3.bisectCenter(X2, xScale.invert(d3.pointer(event)[0] - 5));

       // cursorline
        const svg = d3.select(svgRef.current);
        svg.selectAll('#cursorLine')
            .style('display', null)
            .attr('x1', d3.pointer(event)[0] - 5)
            .attr('x2', d3.pointer(event)[0] - 5)
            .attr('y1', margin.top)
            .attr('y2', height - margin.bottom);


        svg
            .selectAll('#dot1')
            .style('display', data.hours[i2].val=== null ? "none" : null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScale(i > 0 ? data.hours[i].val : 0));
        svg
            .selectAll('#dot2')
            .style('display', data.calc[i2].pm=== null ? "none" : null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScale2(i > 0 ? data.calc[i2].pm : 0));
        svg
            .selectAll('#dot3')
            .style('display', data.calc[i2].pt=== null ? "none" : null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScale2(i > 0 ? data.calc[i2].pt : 0));
        svg
            .selectAll('#dot4')
            .style('display', data.calc[i2].hargreaves=== null ? "none" : null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScale2(i > 0 ? data.calc[i2].hargreaves : 0));

        // Textbox with values
        var tooltiptext = svg.select("#tooltiptext");
        tooltiptext.attr("transform", `translate(${0}, ${30 - 0})`); // Text

        const { x, y, width: w, height: h } = tooltiptext.node().getBBox();

        var path = svg.select("#pathtool");

        if (xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.hours[i].season)) === width - margin.right) {
            svg.select("#tooltip").style("display", 'none');
        }

        let rb = xScale(X[i]) > width - 100 ? width - 100 : xScale(X[i]);


        svg.select("#tooltip")
            .style("display", data.hours[I[i]].val=== null ? "none" : "")
            .attr("text-anchor", "middle")
            .attr("transform", `translate(${rb},0)`);

        path
            .style('display', null)
            .transition()
            .duration(100)
            .attr('d', `M 0 20 l 10 -10 H${20 + w} V${25 + h} H10 V25 Z`);

        svg.select("#date")
            .transition()
            .duration(200)
            .text(data.hours[i].season);

        svg.select("#value1")
            .transition()
            .duration(200)
            .text(data.hours[I[i]].val + " kPa");

       svg.select("#value2")
            .transition()
            .duration(200)
            .text(Math.round(data.calc[I2[i2]].pm * 10) / 10 + " mm/d");

        /*svg.select("#delta")
            .transition()
            .duration(200)
            .text('ΔT ' + Math.round((data[I[i]].actual - data[I[i]].longterm) * 10) / 10 + " °C");*/

        let x_pos = d3.pointer(event)[0];


        /*if (x_pos < 400) {
            svg.selectAll('#legend').attr('opacity', 0.1)
        }
        else {
            svg.selectAll('#legend').attr('opacity', 1)
        }

        if (data[I[i]].actual === null) {
            svg.select("#tooltip").style("display", 'none');
            svg.select("#dot1").style("display", 'none');
            svg.select("#dot2").style("display", 'none');
        }
        else {
            svg.select("#tooltip").style("display", null);
        }*/

    }


    function pointerleft() {

        if (classification > 1)
            return;

         const svg = d3.select(svgRef.current);

        svg.selectAll('#cursorLine')
            .style('display', 'none');

        svg
            .selectAll('#dot1')
            .style('display', 'none')
        svg
            .selectAll('#dot2')
            .style('display', 'none')

        svg.select("#tooltip")
            .style("display", 'none');

        svg.selectAll('#legend').attr('opacity', 1)

    }


    var idleTimeout;

    function updateChart(event) {

        const svg = d3.select(svgRef.current);

        var extentX = event.selection
        var t1;

        if (!extentX) {

            if (!idleTimeout) return idleTimeout = setTimeout(idled, 350); // waiting a bit

            //var min = d3.min(data, (d) => { return d.date })
            //var max = d3.max(data, (d) => { return d.date })

            //let dates = d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.season));

            xScale// = d3.scaleTime()
                .domain([d3.timeParse("%Y-%m-%d")(year + "-01-01"), d3.timeParse("%Y-%m-%d")((year + 1) + "-01-01")]);

        }
        else {
            var from = xScale.invert(extentX[0]);
            from.setHours(0);
            from.setMinutes(0);
            from.setSeconds(0);
            var to = xScale.invert(extentX[1]);
            to.setHours(0);
            to.setMinutes(0);
            to.setSeconds(0);
            //console.log(xScale.invert(extentX[0]), xScale.invert(extentX[1]));

            xScale.domain([from, to]);

            // This remove the grey brush area as soon as the selection has been done
            svg.select(".brush").call(brush.move, null)

        }


        // Bottom
        xAxis.transition()
            .duration(1000)
            .call(d3.axisBottom(xScale));

        // Bottom grid
        xGrid.transition()
            .duration(1000)
            .call(d3.axisBottom(xScale).tickSize(- height + margin.top + margin.bottom, 0).tickFormat(""));

        // Linie längjähriges Mittel

        svg.selectAll("#pathH")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.season)) })
                .y((d) => { return yScale(d.val) })
                .defined(((d) => { return d.val !== null; })));

        svg.selectAll("#path_v1")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.season)) })
                .y((d) => { return yScale(d.val) })
                .defined(((d) => { return d.val !== null; })));

        svg.selectAll("#path_v2")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.season)) })
                .y((d) => { return yScale(d.val) })
                .defined(((d) => { return d.val !== null; })));

        // Linie ausgewähltes Jahr
        svg.selectAll("#path1")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale2(d.pm) })
                .defined(((d) => { return d.pm !== null }))
            );

        svg.selectAll("#path2")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale2(d.cr) })
                .defined(((d) => { return d.cr !== null }))
            );

        svg.selectAll("#path3")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale2(d.ca) })
                .defined(((d) => { return d.ca !== null }))
            );

        svg.selectAll("#path4")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale2(d.pt) })
                .defined(((d) => { return d.pt !== null }))
            );

        svg.selectAll("#path5")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale2(d.hargreaves) })
                .defined(((d) => { return d.hargreaves !== null }))
            );




    }

    const handleCloseSnackbar = (event: SyntheticEvent | Event, reason?: string) => {

    }

    return (
        <>
            <div className="stagearea grid p-2">
                <Title text="Dampfdruck Defizit" />

                <Stack spacing={0}>
                    <StackRow title={t("station")} component={<StationButton />} />

                    <Item>
                        <ComboBox
                            title={"classification"}
                            value={classification} items={c}
                            callback={handleChangeClassification} />
                    </Item>

                    <Item>
                        <DatePicker
                            title="year"
                            value={fromdjs}
                            callback={handleSelector}
                            view={["year"]}
                            format="YYYY"
                        />
                    </Item>

                    {classification===1 &&
                    <Item>


                     <ComboBox
                            title={"methode"}
                            value={methode} items={['Penman Monteith', ['Priestley Taylor'], ['Hargreaves'], ['VPD'], ['Radiative & Aerodynamic components']]}
                            callback={handleChangeMethode} />

                    </Item>
}
 
                </Stack>

                <div id="svgchart">
                    {data === undefined ? <CircularProgress /> : <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>}
                </div>

                <Grid container flexDirection={"row-reverse"}>

                    <Grid item>
                        <GraphicButton object={"svg_chart"} filename={t("boxplot") + "-" + t(sensorstring)} />
                    </Grid>

                    <Grid item className="p-2">
                        <ExcelButton callback={downloadExcel} />
                    </Grid>
                </Grid>
            </div>

            <Snackbar
                open={opensnack}
                onHideDuration={6000}
                onClose={handleCloseSnackbar}
                message="Downloading ..."
                action={action}
            />

        </>
    );

}
