import { useTranslation} from 'react-i18next';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
      border: 0,
      borderRadius: 3,
      height: 32,
      padding: '0 10px',
    },
  });

export default function ComboBox (props) {

    const { t } = useTranslation();
    
    const classes = useStyles();

    return (

        <>
            <Box
                style={{
                        display: 'flex',
                        justifyContent: "space-between",
                        alignItems: "center" }}
                        className="p-1">
        
                <Typography component={'h5'} sx={{ verticalAlign: "middle" }}>
                    {t(props.title)}
                </Typography>

                <FormControl
                    fullWidth
                    sx={{
                        minWidth: "250px"
                    }}
                >
                
                    <Select
                        sx={{
                            padding: "0px",
                            fontSize: "14px",
                            textAlign: "justify",
                            "&.MuiMenuItem": {
                                fontSize: "14px"
                            }
                        }}
                        className={classes.root}
                        labelId="select-label"
                        id="select"
                        value={props.value}
                        onChange={props.callback}
                    >
                        {props.items.map((item, index) => {
                            return (
                            <MenuItem key={index} value={index+1}>
                                {t(item)}
                            </MenuItem>
                            )
                        })}
                    </Select>

                </FormControl>

            </Box>
        </>

    )
}