// Graph
import { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Dialog from '@mui/material/Dialog';

import { GraphContext } from "../routes/GraphContext";


// Main part with time buttons ans graph
const LevelButton = () => {
    const { t, i18n } = useTranslation();
    const [parameter, setParameter] = useContext(GraphContext);

    const data = [t('Tageswerte'), t('Stundenwerte'), t('Rohwerte')];

    const [number, setNumber] = useState (parameter.level !== undefined ? parameter.level : 1);

    const handleListItemClick = (event) => {
        setOpen(false);
        setNumber(event);
        setParameter({ ...parameter, level: (parseInt(event)) });
    };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <>

            {data.map((item, i) => (
                number === i &&
		<div key={i} className="small_screen">
                <Button
                    key={i}
                    color={"primary"}
                    onClick={handleClickOpen}
                    variant="contained"
                    sx={{
                        fontSize: "12px",
                        padding: "4px",
                        whiteSpace: 'nowrap'
                    }}
                >
                    {item} 
                </Button>
		</div>
            ))}

	    <div className="large_screen">
	    <ButtonGroup>
            {data.map((item, index) => (
                <Button
                    key={index}
                    color={"primary"}
                    onClick={() => handleListItemClick(index+1)}
                    variant={(number-1) == index ? "contained" : "outlined"}
                    sx={{
                        fontSize: "12px",
                        padding: "4px",
                        whiteSpace: 'nowrap'
                    }}
                >
                    {item} 
                </Button>
            ))}
		</ButtonGroup>
		</div>

            <Dialog
                onClose={handleClose}
                open={open}>

                <List sx={{ pt: 0 }}>
                    {data.map((item, index) => (
                        <ListItem key={index} disableGutters>
                            <ListItemButton key={index} onClick={() => handleListItemClick(index+1)}>
                                <ListItemText style={{ color: '#000000' }} primary={item} />
                            </ListItemButton>
                        </ListItem>
                    ))}

                </List>
            </Dialog>
        </>
    )
}

export default LevelButton; 