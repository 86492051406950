import * as d3 from "d3";
import { useState, useContext } from "react";

import { GraphContext } from "../GraphContext";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';

import { PhenologieOverview } from './PhenologieOverview';


export function AgronomicContent () {

	const [parameter, setParameter] = useContext(GraphContext);

	const [value, setValue] = useState(0);

	function TabPanel(props) {
		const { children, value, index, ...other } = props;
	  
		return (
		  <div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		  >
			{value === index && (
			  <Box sx={{ p: 3 }}>
				<Typography>{children}</Typography>
			  </Box>
			)}
		  </div>
		);
	  }
	  
	  TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired,
	  };
	  
	  function a11yProps(index) {
		return {
		  id: `simple-tab-${index}`,
		  'aria-controls': `simple-tabpanel-${index}`,
		};
	  }

	  const handleChange = (event, newValue) => {
		setValue(newValue);
	 };

	 return (

		<>
		<div className="stagearea grid p-2">

			<h2>Reports</h2>

			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
					<Tab label="Phänologie" {...a11yProps(0)} />
					</Tabs>
				</Box>

				<TabPanel value={value} index={0}>
					<PhenologieOverview />
				</TabPanel>

			</Box>

			<div className="flex-row p-4 expand">

			</div>

			<div id="pdf">
			</div>


		</div>
		</>
		);

}

