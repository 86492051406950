// Graph
import * as d3 from "d3";

import { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { GraphContext } from "../GraphContext";
import { preferences } from "../../preferences";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Title from "../../components/Title";
import MeteoTable from "../../components/MeteoTable";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Main part with time buttons ans graph
const StationStat = () => {

	const { t, i18n } = useTranslation();

	const [parameter, setParameter] = useContext(GraphContext);
	const [station, setStation] = useState({  sensors: [] });
    const [data, setData] = useState();

	const [url, setUrl] = useState("");


    useEffect(() => {

        d3.json(preferences.address + "/query/climate/generalsum.php?station=" + parameter.station)
            .then((data) => {

                setData(data);
            });
    }, [parameter]);

	const StyledTableCell = styled (TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
		  backgroundColor: '#406060',
		  color: theme.palette.common.white,
		  fontSize: 14,
		},
		[`&.${tableCellClasses.body}`]: {
		  fontSize: 12,
		},
	  }));
	
	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	}));

	return (

		<>


				<Grid item>
					<Title text={t("DiewärmstenJahre")}/>
					{data !== undefined &&
						<MeteoTable
							header={["index", "year", "temperature_C"]}
							cols={["year", "value"]}
							rows={data.temperature.years.up}/>
					}
				</Grid>

				<Grid item>
				<Title text={t("DiekältestenJahre")}/>
					{data !== undefined &&
						<MeteoTable
							header={["index", "year", "temperature_C"]}
							cols={["year", "value"]}
							rows={data.temperature.years.down}/>
					}
				</Grid>

	
				<Grid item>
					<Title text={t("DiewärmstenMonate")}/>
					{data !== undefined &&
						<MeteoTable
							header={["index", "year", "month", "temperature_C"]}
							cols={["year", "month", "value"]}
							rows={data.temperature.months.up}/>
					}
				</Grid>

				<Grid item>
					<Title text={t("DiekältestenMonate")}/>
					{data !== undefined &&
						<MeteoTable
							header={["index", "year", "month", "temperature_C"]}
							cols={["year", "month", "value"]}
							rows={data.temperature.months.down}/>
					}
				</Grid>

				<Grid item>
					<Title text={t("DiewärmstenTage")}/>
					{data !== undefined &&
						<MeteoTable
							header={["index", "date", "temperature_C"]}
							cols={["date", "value"]}
							rows={data.temperature.days.up}/>
					}
				</Grid>

				<Grid item>
					<Title text={t("DiekältestenTage")}/>
					{data !== undefined &&
						<MeteoTable
							header={["index", "date", "temperature_C"]}
							cols={["date", "value"]}
							rows={data.temperature.days.down}/>
					}
				</Grid>


				<Grid item>
					<Title text={t("DieregenreichstenJahre")}/>
					{data !== undefined &&
						<MeteoTable
							header={["index", "year", "precipitation_mm"]}
							cols={["year", "value"]}
							rows={data.rain.years.up}/>
					}
					
				</Grid>

				<Grid item>
				<Title text={t("DieregenärmstenJahre")}/>
				{data !== undefined &&
						<MeteoTable
							header={["index", "year", "precipitation_mm"]}
							cols={["year", "value"]}
							rows={data.rain.years.down}/>
					}

				</Grid>

				<Grid item>
					<Title text={t("Die regenreichsten Monate")}/>
					{data !== undefined &&
							<MeteoTable
								header={["index", "year", "month", "precipitation_mm"]}
								cols={["year", "month", "value"]}
								rows={data.rain.months.up}/>
						}
				</Grid>
				<Grid item>
					<Title text={t("DieregenärmstenMonate")}/>
						{data !== undefined &&
								<MeteoTable
								header={["index", "year", "month", "precipitation_mm"]}
								cols={["year", "month", "value"]}
									rows={data.rain.months.down}/>
							}
				</Grid>


				<Grid item>
					<Title text={t("DieregenreichstenTage")}/>
						{data !== undefined &&
								<MeteoTable
									header={["index", "date", "precipitation_mm"]}
									cols={["date", "value"]}
									rows={data.rain.days.up}/>
							}
				</Grid>

		</>
	);
}

export default StationStat; 
