import * as React from 'react';
import { useTranslation} from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';

import { sqlToJsDate } from "../../../js/datetime";
import { format } from 'date-fns';

const formatDate = (da) => {
    if (!(da === undefined))
        return format(sqlToJsDate(da), 'dd/MM/yyyy');
    else
        return "";
}

const StyledTableCell = styled (TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
	  backgroundColor: '#406060',
	  color: theme.palette.common.white,
	  fontSize: 14,
	},
	[`&.${tableCellClasses.body}`]: {
	  fontSize: 12,
	},
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export default function TableStation({ station }) {

    const { t } = useTranslation();


    const array = [ {name: 'name', value: station.name},
                    {name: 'description', value: station.description_de},
                    /*{name: 'marke', value: station.marke},
                    {name: 'model', value: station.model},*/
                    {name: 'functional', value: (station.inservice !== null ? formatDate(station.inservice) : "")},
                    {name: 'lat', value: station.lat},
                    {name: 'lon', value: station.lon},
                    {name: 'sealevel', value: station.sealevel}
                  ]

    return (
        <>

            <TableContainer component={Paper}>
                <Table size="small" aria-label="station table">
                    <TableHead>
                    </TableHead>

                    <TableBody>

                        {array.map((row, index) => {

                            return (

                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                        {t(row.name)}
                                    </StyledTableCell>

                                    <StyledTableCell component="th" scope="row">
                                        {row.value}
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};