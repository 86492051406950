import { useState, useContext, useEffect } from "react";
import { GraphContext } from "../../../GraphContext";
import Skeleton from '@mui/material/Skeleton';

import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from "@mui/material/styles";

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Title from "../../../../components/Title";

import { useTranslation } from 'react-i18next';

import { getCurrentYear } from '../../../../js/datetime';
import { preferences } from "../../../../preferences";

export const DaysTable = ({ year, month }) => {

  const { t } = useTranslation();

  const [parameter] = useContext(GraphContext);

  const [data, setData] = useState(null);

  useEffect(() => {

    setData(null);

    let station = parameter.station;

    let url = preferences.address + '/query/tables/mreport/days.php?station=' + station + '&year=' + year + '&month=' + month;

    fetch(url)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .then((data) => {
        setData(data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [month, year, parameter])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#008051',
      color: theme.palette.common.white,
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    backgroundColor: '#00516f',
    color: "#ffffff",
    fontSize: 16
  }));

  return (
    <>

      {data == null ? <Skeleton variant="rectangular" animation="wave" width={'150%'} height={20} /> :
        <Title text={t(data.title)} />
      }

      {data == null ? <Skeleton variant="rectangular" animation="wave" width={'100%'} height={200} /> :
        <TableContainer component={Paper} className="p-1 mt-2">
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <StyledTableHead>
              <TableRow>
                <StyledTableCell>{t('year')}</StyledTableCell>
                <StyledTableCell align="right">{t("iceday")} (max &lt; 0°C)</StyledTableCell>
                <StyledTableCell align="right">{t("frostday")} (min &lt; 0°C)</StyledTableCell>
                <StyledTableCell align="right">{t("summerday")} (max &gt; 25°C)</StyledTableCell>
                <StyledTableCell align="right">{t("tropicday")} (max &gt; 30°C)</StyledTableCell>
                <StyledTableCell align="right">{t("desertday")} (max &gt; 35°C)</StyledTableCell>
              </TableRow>
            </StyledTableHead>

            <TableBody>

              {data.rows.map((row, index) => {
                let i = Math.floor(Math.random() * 10000);
                if (index < 4 || data.rows[index].year !== data.rows[index > 0 ? index - 1 : 0].year)
                  return (
                    <StyledTableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index > 2 &&  t("since")} {row.year} {(index === 3 && ((getCurrentYear() - 30) === parseInt(row.year))) && "(" + t("mid_30") + ")"} {index === 4 && "(" + t("mid_long") + ")"}
                      </TableCell>
                      <TableCell align="right">{row.ice}</TableCell>
                      <TableCell align="right">{row.frost}</TableCell>
                      <TableCell align="right">{row.summer}</TableCell>
                      <TableCell align="right">{row.tropic}</TableCell>
                      <TableCell align="right">{row.desert}</TableCell>
                    </StyledTableRow>
                  )
                  else
                    return ""
              })}

            </TableBody>

          </Table>
        </TableContainer>
      }
    </>
  );

}
