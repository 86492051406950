import { useState, useEffect } from 'react';
import StationInfo from "./StationInfo";
import StationStat from "./StationStat";
import StationButton from "../../components/StationButton";
import StackRow from "../../components/StackRow";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

// Main part with time buttons ans graph
const StationContent = () => {

    const { t, i18n } = useTranslation();
    const { trackPageView } = useMatomo();
    const location = useLocation();

    // Track page view                                                                                                                 
    useEffect(() => {
      trackPageView({
        documentTitle: 'Info Stationen', // optional                                                                                        
        href: 'https://meteo.laimburg.it'+location.pathname, // optional                                                       
        /*customDimensions: [
          {
            id: 1,
            value: 'loggedIn',
          },
        ], // optional*/
      })
    }, [trackPageView, location])

	return (

		<>

			<div className="stagearea grid p-2">

				{/* import StackRow from "../../../components/StackRow"; */}
				<StackRow text={t("station")} component={<StationButton />} />

				<Grid className="par" container spacing={5}>

					{/* Main part */}
					<StationInfo/>

					{/* Statistik */}
					<StationStat/> 
				</Grid>

			</div>
		</>

	);
}

export default StationContent; 
