import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import { preferences } from "../../preferences";

import { getCurrentYear, } from '../../js/datetime';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import DatePicker from '../../components/DatePicker';

import { borders, addLine, addArea } from '../../components/d3/legend/legend';
import { xlabel, ylabel, logo } from '../../components/d3/charts/chart';
import { timeformat } from '../../components/d3/d3timeformat';

import { useMatomo } from '@datapunt/matomo-tracker-react'

export const TemperatureOverview = ({ description, list }) => {

    const { trackPageView } = useMatomo()
    const { t, i18n } = useTranslation();

    const location = useLocation();

    const [parameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const [data, setData] = useState([]);

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        d3.json(preferences.address + "/query/climate/temperature/overview/get.php?station=" + parameter.station + "&year=" + year)
            .then((data) => {
                setData(data);
            });
    }, [parameter, year]);

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Temperatur Tagesmittelwerte', // optional                                                                       
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])


    useEffect(() => {
        const svg = d3.select(svgRef.current);
        draw(svg);

        let labels = [t("temperature_mids"), t("temperature_maps"), t("above_middle"), t("underneath_middle")]
        borders(svg, labels, 300, 70, 10);
        addLine(svg, 0, 2.5, "black", labels[0]);
        addLine(svg, 1, 1.5, "black", labels[1]);
        addArea(svg, 2, "red", labels[2]);
        addArea(svg, 3, "blue", labels[3]);

    }, [resize, data, i18n.language]);


    const downloadExcel = () => {

        var url = preferences.address + '/query/climate/temperature/overview/excel.php';

        let block = {};
        block.station = parameter.station;
        block.year = year;
        block.lang = i18n.language;

        let filename = ''
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try { filename = response.headers.get('content-disposition').split('filename=')[1]; }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" })
                saveAs(file);

            });

    }



    const handleSelector = (event) => {

        setFromdjs(event);
        let date = event.$d;
        setYear(1900 + date.getYear());
    }

    var xScale;
    var xScaleBand;
    let brush;
    let xAxis;
    let xGrid;
    let clip;

    let yScale;
    let yScale2;

    var height = 600;// - margin.top - margin.bottom;
    var width = 0;
    var margin = { top: 10, right: 20, bottom: 40, left: 70, bottomHeight: 100, offset: 20 }

    var min;
    var max;

    function draw(svg) {

        // Import time format
        timeformat (t);

        // Clear
        svg.selectAll("*").remove();

        // setup SVG
        svg
            .attr("viewBox", [0, 0, svg.style('width'), svg.style('height')])
            .style("overflow", "visible")
            .style("-webkit-tap-highlight-color", "transparent")
            .on("pointerenter pointermove", pointermoved)
            .on("pointerleave", pointerleft)
            .on("touchstart", event => event.preventDefault());

        // Getting width
        width = parseInt(svg.style('width'));

        // SVG
        svg
            .attr("width", width) // + margin.left + margin.right
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // Add X axis --> it is a date format
        xScale = d3.scaleTime()
            .domain([d3.timeParse("%Y-%m-%d")(year + "-01-01"), d3.timeParse("%Y-%m-%d")((year + 1) + "-01-01")])
            .range([margin.left, width - margin.right])
            
       

        let dates = d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date));
        let filtered = dates.filter((d) => { return (d.getYear() + 1900) === year });
        xScaleBand = d3.scaleBand()
            //.domain(d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date)))
            //.domain([d3.timeParse("%Y-%m-%d")(year+"01-01"), d3.timeParse("%Y-%m-%d")(year+"12-31")])
            .domain(filtered)
            .range([margin.left, width - margin.right]).padding(0);

        /* X axis rain */
        xAxis = svg.append("g")
            .attr("transform", "translate(0," + (height - margin.bottom) + ")")
            .call(d3.axisBottom(xScale))
            .style("font-size", "12px");

        let bottomScale = d3.axisBottom(xScale);
        //bottomScale.ticks (width/100);

        // Add Y axis
        min = d3.min([d3.min(data, function (d) { return + d.actual; }), -10]);
        max = d3.max([d3.max(data, function (d) { return + d.actual; }), 30]);

        // X grid
        xGrid = svg.append("g")
            .attr('id', 'xgrid')
            .attr('transform', 'translate(0 ,' + (height - margin.bottom) + ')')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(bottomScale.tickSize(-height + margin.bottom + margin.top, 0).tickFormat(""));

        // Y grid
        yScale = d3.scaleLinear()
            .domain([min, max])
            .range([height - margin.bottom - margin.bottomHeight - margin.offset, margin.top]);

        // Y under
        yScale2 = d3.scaleLinear()
            .domain([0, 100])
            .range([height - margin.bottom, height - margin.bottom - margin.bottomHeight]);

        // brush
        brush = d3.brushX()
            .extent([[margin.left, 0], [width - margin.right, height - margin.bottom]])
            .on("end", updateChart); // brushended

        svg.append("g")
            .attr('class', 'brush')
            .call(brush);

        // Clip
        clip = svg.append('g')
            .attr("clip-path", "url(#clip)");

        svg.append("defs")
            .append("svg:clipPath")
            .attr('id', 'clip')
            .append("svg:rect")
            .attr("x", margin.left)
            .attr("y", 0)
            .attr("width", width - margin.left - margin.right)
            .attr("height", height);

        // Y ticks temperature
        let axisLeft = d3.axisLeft(yScale);
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .call(axisLeft.ticks(10, "0.1f").tickFormat((x) => { return x + " °C" }))
            .style("font-size", "12px");

        // Y ticks Regen
        let axisLeft2 = d3.axisLeft(yScale2);
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .call(axisLeft2.ticks(4, "0f").tickFormat((x) => { return x + " mm" }))
            .style("font-size", "12px");

        // Grid X
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(axisLeft.tickSize(-width + margin.left + margin.right, 0).tickFormat(""));

        // Grid X
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(axisLeft2.tickSize(-width + margin.left + margin.right, 0).tickFormat(""));

        // Add the lines group
        const lines = clip
            .append("g")
            .attr('id', 'lines');

        //const X = d3.map(data, (d) => { return d3.timeParse("%Y-%m-%d")(d.date) });
        const Y1 = d3.map(data, (d) => { return d.longterm });
        //const Y2 = d3.map(data, (d) => { return d.longterm });
        //const I = d3.range(data.length);
        const defined = (d, i) => Y1[i];
        //const D = d3.map(data, defined);
        //const line = (y) => d3.line().defined(i => D[i]).curve(d3.curveLinear).x(i => xScale(X[i])).y(y)(I);
        //const area = (y0, y1) => d3.area().defined(i => D[i]).curve(d3.curveLinear).x(i => xScale(X[i])).y0(y0).y1(y1)(I);

        //.attr("x", function (d) { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScaleBand(ld) })

        lines.append("path")
            .datum(data)
            .attr('id', 'path1')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "grey")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .y((d, i) => { return yScale(d.longterm) })
                .defined(((d) => { return d.longterm !== null; }))
            );

        // Add the line
        lines.append("path")
            .datum(data)
            .attr('id', 'path2')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .y((d, i) => { return yScale(d.actual) })
                .defined(((d) => { return d.actual !== null; }))
            );

        const box = clip
            .append("g");

        // Striped up
        box.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "bar2")
            .style("pointer-events", "none")
            //.attr("x", function (d) { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScaleBand(ld) })
            .attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("y", margin.top)
            .attr("width", (d) => { return xScaleBand.bandwidth() })
            .attr('fill', function (d) { return (d.rain === null ? '#084594' : '#084594') })
            .attr('opacity', function (d) { return (d.rain === null ? 0 : d.rain / 100) })
            .attr("height", height - margin.bottom - margin.bottomHeight - margin.offset - margin.top);

        // Striped down
        box.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .style("pointer-events", "none")
            .attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("y", (d) => { return yScale2(d.rain) + height - margin.bottom - yScale2(0) })
            .attr("width", (d) => { return xScaleBand.bandwidth() })
            .attr("height", (d) => { return yScale2(0) - yScale2(d.rain) })
            .attr('fill', '#084594');

        svg.append('line')
            .attr('id', 'cursorLine')
            .attr('stroke', 'grey')
            .attr('display', null);

        var dotgroup = svg.append("g").attr('id', 'dotgroup');

        /*  actual */
        dotgroup.append("circle")
            .attr('id', 'dot1')
            .style('display', 'none')
            .style("stroke", "grey")
            .style("fill", 'black')
            .attr("r", 3);

        createArea();

        var tooltip = svg.append("g")
            .attr('id', 'tooltip')
            .style("pointer-events", "none");

        var path = tooltip.append("path")
            .attr('id', 'pathtool')
            .data([,])
            .attr("class", "shadow")
            .attr("fill", "white")
            .attr("stroke", 'black')
            .attr("stroke-width", 1.5)
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round");

        var tooltiptext = tooltip.append("text")
            .attr('id', 'tooltiptext')
            .style("pointer-events", "none");

        // Date
        tooltiptext
            .append("tspan")
            .attr('id', 'date')
            .attr("x", 15)
            .attr("y", 0)
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value')
            .attr("x", 25)
            .attr("y", 20)
            .attr("stroke", 'black')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'delta')
            .attr("x", 25)
            .attr("y", 40)
            .attr("stroke", 'black')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');


        xlabel(svg, t("year"), width / 2, height - 5);

        ylabel(svg, t("temperature"), - height * 0.3, 15);
        ylabel(svg, t("rain"), -height * 0.85, 15);

        // Image
        logo(svg);

    }

    function createArea() {
        //const svg = d3.select(svgRef.current);

        //const X = d3.map(data, (d) => { return d3.timeParse("%Y-%m-%d")(d.date) });
        const Y1 = d3.map(data, (d) => { return d.actual });
        //const Y2 = d3.map(data, (d) => { return d.longterm });
        //const I = d3.range(data.length);

        const defined = (d, i) => Y1[i];

        // Obere Fläche
        clip.append('clipPath')
            .datum(data.filter(defined)) //.filter(defined)
            .attr('id', 'clippath_up')
            .append('path')
            .attr('id', 'clippath_up_path')
            .attr("d", d3.area()
                //.x((d, i) => {let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(d.longterm) })
                .y1(function (d) { return yScale(max) })
            );

        clip.append("path")
            .datum(data.filter(defined))
            .attr('clip-path', "url(#clippath_up)")
            .attr('class', 'area1')
            .attr("fill", "red")
            .attr('opacity', 0.5)
            .attr("stroke-width", 0)
            .attr("d", d3.area()
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(min) })
                .y1(function (d) { return yScale(d.actual) })
            )

        // Untere Fläche
        clip.append('clipPath')
            .datum(data.filter(defined)) //.filter(defined)
            .attr('id', '_clip_down')
            .append('path')
            .attr('id', 'clippath_down_path')
            .attr("d", d3.area()
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(max) })
                .y1(function (d) { return yScale(d.actual) })
            );
        clip.append("path")
            .datum(data.filter(defined))
            .attr('clip-path', "url(#_clip_down)")
            .attr('class', 'area2')
            .attr("fill", "blue")
            .attr('opacity', 0.5)
            .attr("stroke", "#69b3a2")
            .attr("stroke-width", 0)
            .attr("d", d3.area()
                //.x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(d.longterm) })
                .y1(function (d) { return yScale(min) })
            )
    }

    function pointermoved(event) {

        const X = d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date));
        const I = d3.map(data, (_, i) => i);
        const i = d3.bisectCenter(X, xScale.invert(d3.pointer(event)[0] - 5));

        // cursorline
        const svg = d3.select(svgRef.current);
        svg.selectAll('#cursorLine')
            .style('display', null)
            .attr('x1', d3.pointer(event)[0] - 5)
            .attr('x2', d3.pointer(event)[0] - 5)
            .attr('y1', margin.top)
            .attr('y2', height - margin.bottom);

        svg
            .selectAll('#dot1')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScale(i > 0 ? data[i].actual : 0));
        svg
            .selectAll('#dot2')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScale(i > 0 ? data[i].longterm : 0));

        // Textbox with values
        var tooltiptext = svg.select("#tooltiptext");
        tooltiptext.attr("transform", `translate(${0}, ${30 - 0})`); // Text

        const { x, y, width: w, height: h } = tooltiptext.node().getBBox();

        var path = svg.select("#pathtool");

        if (xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data[i].date)) === width - margin.right) {
            svg.select("#tooltip").style("display", 'none');
        }

        let rb = xScale(X[i]) > width - 100 ? width - 100 : xScale(X[i]);
        svg.select("#tooltip")
            .style("display", null)
            .attr("text-anchor", "middle")
            .attr("transform", `translate(${rb},0)`);

        path
            .style('display', null)
            .transition()
            .duration(100)
            .attr('d', `M 0 20 l 10 -10 H${20 + w} V${25 + h} H10 V25 Z`);

        svg.select("#date")
            .transition()
            .duration(200)
            .text(data[i].date);

        svg.select("#value")
            .transition()
            .duration(200)
            .text(Math.round(data[I[i]].actual * 10) / 10 + " °C");

        svg.select("#delta")
            .transition()
            .duration(200)
            .text('ΔT ' + Math.round((data[I[i]].actual - data[I[i]].longterm) * 10) / 10 + " °C");

        let x_pos = d3.pointer(event)[0];

        if (x_pos < 400) {
            svg.selectAll('#legend').attr('opacity', 0.1)
        }
        else {
            svg.selectAll('#legend').attr('opacity', 1)
        }

        if (data[I[i]].actual === null) {
            svg.select("#tooltip").style("display", 'none');
            svg.select("#dot1").style("display", 'none');
            svg.select("#dot2").style("display", 'none');
        }
        else {
            svg.select("#tooltip").style("display", null);
        }

    }

    function pointerleft() {
        const svg = d3.select(svgRef.current);

        svg.selectAll('#cursorLine')
            .style('display', 'none');

        svg
            .selectAll('#dot1')
            .style('display', 'none')

        svg.select("#tooltip")
            .style("display", 'none');

        svg.selectAll('#legend').attr('opacity', 1)
    }

    var idleTimeout
    function idled() { idleTimeout = null; }



    function updateChart(event) {

        const svg = d3.select(svgRef.current);

        var extentX = event.selection
        var t1;

        if (!extentX) {

            if (!idleTimeout) return idleTimeout = setTimeout(idled, 350); // waiting a bit

            //var min = d3.min(data, (d) => { return d.date })
            //var max = d3.max(data, (d) => { return d.date })

            let dates = d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date));
            let filtered = dates.filter((d) => { return (d.getYear() + 1900) === year });

            xScale// = d3.scaleTime()
                .domain([d3.timeParse("%Y-%m-%d")(year + "-01-01"), d3.timeParse("%Y-%m-%d")((year + 1) + "-01-01")]);

            xScaleBand
                .domain(filtered)
        }
        else {
            var from = xScale.invert(extentX[0]);
            from.setHours(0);
            from.setMinutes(0);
            from.setSeconds(0);
            var to = xScale.invert(extentX[1]);
            to.setHours(0);
            to.setMinutes(0);
            to.setSeconds(0);
            //console.log(xScale.invert(extentX[0]), xScale.invert(extentX[1]));

            xScale.domain([from, to]);

            var t = d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date));
            t1 = t.filter(item => item >= from && item <= to);
            t1.forEach((d) => { d.setDate(d.getDate() - 1) });
            xScaleBand.domain(t1);

            // This remove the grey brush area as soon as the selection has been done
            svg.select(".brush").call(brush.move, null)

        }


        // Bottom
        xAxis.transition()
            .duration(1000)
            .call(d3.axisBottom(xScale));

        // Bottom grid
        xGrid.transition()
            .duration(1000)
            .call(d3.axisBottom(xScale).tickSize(- height + margin.top + margin.bottom, 0).tickFormat(""));

        // Linie längjähriges Mittel
        svg.selectAll("#path1")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .y((d) => { return yScale(d.longterm) })
                .defined(((d) => { return d.longterm !== null; })));

        // Linie ausgewähltes Jahr
        svg.selectAll("#path2")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .y((d) => { return yScale(d.actual) }));


        // Probe
        svg.selectAll("#clippath_up_path")
            .transition()
            .duration(1000)
            .attr("d", d3.area()
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(d.longterm) })
                .y1(function (d) { return yScale(max) })
            );

        svg.selectAll(".area1")
            .transition()
            .duration(1000)
            .attr("d", d3.area()
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(min) })
                .y1(function (d) { return yScale(d.actual) })
            )
        svg.selectAll("#clippath_down_path")
            .transition()
            .duration(1000)
            .attr("d", d3.area()
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(max) })
                .y1(function (d) { return yScale(d.actual) })
            );
        svg.selectAll(".area2")
            .transition()
            .duration(1000)
            .attr("d", d3.area()
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(d.longterm) })
                .y1(function (d) { return yScale(min) })
            )

        // react
        svg.selectAll(".bar")
            .transition()
            .duration(1000)
            .attr("x", d => { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("width", () => { return xScaleBand.bandwidth() });
        svg.selectAll(".bar2")
            .transition()
            .duration(1000)
            .attr("x", d => { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("width", () => { return xScaleBand.bandwidth() });



    }

    return (
        <>
            <Stack spacing={1}>

                <Item>
                    <DatePicker
                        title="year"
                        value={fromdjs}
                        callback={handleSelector}
                        view={["year"]}
                        format="YYYY"
                    />
                </Item>
            </Stack>


            <div id="svgchart">
                <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
            </div>

            <Grid container flexDirection={"row-reverse"}>

                <Grid item>
                    <GraphicButton object="svg_chart" filename={t('temperature_mids')} />
                </Grid>

                <Grid item className="p-2">
                    <ExcelButton callback={downloadExcel} />
                </Grid>

            </Grid>
        </>
    );

}
