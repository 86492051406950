import { useTranslation} from 'react-i18next';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
      border: 0,
      borderRadius: 3,
      height: 32,
      padding: '0 10px',
    },
  });

export default function ComboBox ({title, value, unit}) {

    const { t } = useTranslation();
    
    const classes = useStyles();

    return (

        <>
            <Box
                style={{
                        display: 'flex',
                        justifyContent: "space-between",
                        alignItems: "center" }}
                        className="p-1">
        
                <Typography component={'h5'} sx={{ verticalAlign: "middle" }}>
                    {t(title)}
                </Typography>

                <FormControl
                    fullWidth
                    sx={{
                        color: "black",
                        minWidth: "250px"
                    }}
                >
                
                {value} {unit}

                </FormControl>

            </Box>
        </>

    )
}