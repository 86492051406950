// Graph
import * as d3 from "d3";

import { useTranslation } from 'react-i18next';

import { useState, useEffect, useContext } from "react";
//import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { format } from 'date-fns'
//import { ButtonGroup, Container, Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { GraphContext } from "../GraphContext";
import { sqlToJsDate } from "../../js/datetime";
import { preferences } from "../../preferences";
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import ReactPlaceholder from 'react-placeholder';
import Grid from '@mui/material/Grid';
import Title from "../../components/Title";

import { Boxplot } from './parts/Boxplot';
import { Scatterplot } from './parts/Scatterplot';
import { Proplot } from './parts/Proplot';
import StackRow from "../../components/StackRow";

// Main part with time buttons ans graph

import StationButton from "../../components/StationButton";

import { TabPanel } from "../../components/TabPanel";

export const SeasonsContent = () => {

	const { t } = useTranslation();

	const [parameter, setParameter] = useContext(GraphContext);
	const [station, setStation] = useState({ sensors: [] });
	const [value, setValue] = useState(0);

	const [url, setUrl] = useState("");


	useEffect(() => {

		{/*  */ }
		let par = { parameter }
		let query = preferences.address + "/rest/station.php?id=" + par.parameter.station;

		d3.json(query).then((data) => {
			setStation(data);
		})

	}, [parameter])

	useEffect(() => {


	}, [station])

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired,
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};


	return (


		<>
			<div className="stagearea grid p-2">


				{/* <Title text="Darstellung Sensoren" /> */}
				{/* import StackRow from "../../../components/StackRow"; */}
				<StackRow text={t("station")} component={<StationButton />} />

				<Box sx={{ width: '100%' }}>

					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={value} 
							onChange={handleChange} 
							variant="scrollable" 
							aria-label="basic tabs example"
						>
							<Tab label="Boxplot" {...a11yProps(0)} />
							<Tab label="Scatterplot" {...a11yProps(1)} />
							<Tab label="Proplot" {...a11yProps(2)} />
						</Tabs>
					</Box>

					<TabPanel value={value} index={0}>
						<Boxplot />
					</TabPanel>

					<TabPanel value={value} index={1}>
						<Scatterplot />
					</TabPanel>

					<TabPanel value={value} index={2}>
						<Proplot />
					</TabPanel>


				</Box>

				<div className="flex-row p-4 expand">
				</div>

				<div id="pdf">
				</div>


			</div>

		</>

	);
}
