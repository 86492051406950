import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({

    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const theme = createTheme();

theme.typography.p = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};


export default function StackRow({ text, component }) {

    const { t, i18n } = useTranslation();

    return (
        <>
            <Item>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <ThemeProvider theme={theme}>
                    <Typography className="stackrow" component={'p'} sx={{ verticalAlign: "middle" }}>
                        {t(text)}
                    </Typography>
                    </ThemeProvider>

                    {component}

                </Box>
            </Item>
        </>
    )
}
