import * as d3 from "d3";

import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Main part with time buttons and graph
export default function MeteoTable (props) 
{

    const { t, i18n } = useTranslation();

    const StyledTableCell = styled (TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
		  backgroundColor: '#40516f',
		  color: theme.palette.common.white,
		  fontSize: 16,
		},
		[`&.${tableCellClasses.body}`]: {
		  fontSize: 12,
		},
	  }));
	
	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	}));

    return (
        		
			<TableContainer component={Paper}>
                <Table size="small" aria-label="general table">
                    <TableHead>
                        <TableRow>
                            {props.header.map((item, index) =>{
                                return (
                                <StyledTableCell key={index}>{t(item)}</StyledTableCell>    
                                )
                            })}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {props.rows.map((row, i) => {
                            return <StyledTableRow key={i}>
                                <StyledTableCell align="right" scope="row">{i+1}</StyledTableCell>
                                {props.cols.map(function(k, index) {
                                    return (
                                        <StyledTableCell key={index} align="right" scope="row">{t(row[k])}</StyledTableCell>
                                    )
                                })}
                            </StyledTableRow>;
                        })}
                    </TableBody>

                </Table>
            </TableContainer>
    )
 }
