import * as d3 from "d3";
import { ug } from "date-fns/esm/locale";

let svg;

var myColorsA = ['rgba(0,0,0,0)', d3.hsl("orange")];
var colorsWind = ['rgba(0,0,0,1)', 'rgba(0,255,0,1)', 'rgba(255,0,0,1)', 'rgba(0,0,255,1)', 'rgba(0,0,0,1)', 'rgba(0,255,0,1)',];

var colors = ['rgba(0,0,0,0)', '#084594'];// '#084594'
var getColor = d3.scaleLinear()
	.domain([0, 5])
	.range(colors);

var getColorTime = d3.scaleLinear()
	.domain([0, 600])
	.range(myColorsA);

var getColorWind = d3.scaleLinear()
	.domain([0, 90, 180, 270, 360, 450])
	.range(colorsWind);


// Dimensionen
let margins;

// 
let boxes;

// 
let clip;

let xScale;
let yScale = [];

let xScaleBand;
let xGrid;

let xAxis;

let data;

let brush;

let active = {};

let items = [];


function dimensions (sv, dat, itemList=[], top, bottom, left, right) {

	if (dat !== undefined)
		data = dat;

	items = itemList;

	if (typeof (data.timeline) == "undefined")
		return;

	svg = sv;
	// Clean
    svg.selectAll("*").remove();

    	/*var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);*/

	// variable
	var width = parseInt(svg.style('width'));
	var height = data.height;

	margins = { width: width, height: height, top: top, right: right, bottom: bottom, left: left }

	var width_netto = width - margins.left - margins.right;

	// SVG
	svg.attr('width', width)
		.attr('height', height)
		.append('g')
		.attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

	xScale = d3.scaleTime()
		.domain([data.minTime, data.maxTime])
		.range([margins.left, margins.width - margins.right]);

	let bottomScale = d3.axisBottom(xScale);
	
	// X Axis
	xAxis = svg.append("g")
		.attr('id', 'xAxis')
		.attr('transform', 'translate(0 ,' + (margins.height - margins.bottom) + ')')
		.call(bottomScale);

	xScaleBand = d3.scaleBand()
		.domain(d3.map(data.timeline, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d)))
		.range([margins.left, margins.width - margins.right]).padding(0);

	// Grid
	xGrid = svg.append("g")
		.attr('id', 'xgrid')
		.attr('transform', 'translate(0 ,' + (margins.height - margins.bottom ) + ')')
		.style("pointer-events", "none")
		.attr("class", "gridline")
		.call(bottomScale.tickSize(-(margins.height - margins.bottom - margins.top), 0).tickFormat(""));


	// ---------------
	clip = svg.append('g')
		.attr("clip-path", "url(#clip)")

	// ??
	svg.append("defs")
		.append("svg:clipPath")
		.attr('id', 'clip')
		.append("svg:rect")
		.attr("x", margins.left)
		.attr("y", 0)
		.attr("width", margins.width - margins.left - margins.right)
		.attr("height", margins.height + 500);

	// Every box its axis
	
	boxes = data.boxes;

	/* Y Scale & Y-ticks */
	if (typeof (boxes) !== "undefined") {
		boxes.map((box, i) => {

			var box2 = clip.append("g").attr('id', "box_" + i);
			
			switch (box.typ) {
				case ("line"):

					yScale[i] = d3.scaleLinear()
						.domain([box.min, box.max])
						.range([ box.offset + margins.height - margins.bottom, margins.top + box.offset]);

					let axisLeft = d3.axisLeft(yScale[i]);

					// Ticks
					svg.append("g")
						.attr('transform', 'translate(' + margins.left + ' ,0)')
						.call(axisLeft.ticks(10, "0.1f").tickFormat((x) => {return x + " " + (data.sensors === null ? "": data.sensors[0].unit)}));

					//grid
					svg.append("g")
						.attr('transform', 'translate(' + margins.left + ' ,0)')
						.style("pointer-events", "none")
						.attr("class", "gridline")
						.call(axisLeft.tickSize(-width_netto, 0).tickFormat(""));

					break;

				case ("stick"):
				case ("time"):
				case ("direction"):
					yScale[i] = d3.scaleLinear()
						.domain([box.min, box.max])
						.range([margins.top + box.offset + box.height, margins.top + box.offset]);

					box2
						.append("rect")
						.attr("width", margins.width - margins.right - margins.left)
						.attr('fill', 'none')
						.attr('stroke', '#000000')
						.attr("stroke-width", 1.5)
						.attr("x", margins.left)
						.attr("y", margins.top + box.offset)
						.attr("height", 20);
					break;
			}
		})
	}

	/* Dot group */
	var dotgroup = svg.append("g").attr('id', 'dotgroup');

	/* Data */
	let index = 0;
	if (typeof (boxes) !== "undefined") {
		boxes.map((p, i) => {

			var box = clip.select("#box_" + i);
			
			p.buckets.map((a, j) => {
				switch (p.typ) {

					case ("line"):
						dotgroup.append("circle")
								.attr('id', 'dot_' + index)
								.style('display', 'none')
								.style("stroke", "grey")
								.style("fill", d3.schemeSet1[index])
								.attr("r", 3)
								.attr('display', items[index] !== undefined && items[index].active ? null : 'none');

						var line = d3.line()
							.x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
							.y((d) => { return (d.value === null ? null : yScale[i](d.value)) })
							.defined(((d) => { return d.value !== null;} ));

						if (a.typ == 1 || a.typ == 3) {
							if (items[index] !== undefined) {
							box.append("path")
								.attr('id', 'path_' + index)
								.datum(a.list)
								.style("pointer-events", "none")
								.attr("fill", "none")
								.attr("stroke", d3.schemeSet1[index])
								.attr("stroke-width", 1.5)
								.attr("d", line)
								.attr('display', items[index].active ? null : 'none');
							}
								/*box.append('g')
								.selectAll("circles")
								.datum(a.list)
								.enter()
								.append("circle")
									.attr("fill", "white")
									.attr("stroke", "black")
									.attr("cx", (d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
									.attr("cy", (d) => { return (d.value === null ? null : yScale[i](d.value)) })
									.attr("r", 2)
								.defined(( (d) => { return d.value > 0;} ));*/

						}
						else {
							box.append('g')
								.selectAll("#rect_" + index)
								.data(a.list)
								.enter().append("rect")
								.attr("class", "bar" + index)
								.attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
								.attr("y", (d) => { return yScale[index](d.value) })
								.attr("width", () => { return xScaleBand.bandwidth() })
								.attr('fill', d3.schemeSet1[index])
								.attr("height", (d) => { return yScale[index](0) - yScale[index](d.value) })
								;
						}
						break;
					case ("stick"):
						box.append('g')
							.selectAll("#rect_" + index)
							.data(a.list)
							.enter().append("rect")
							.attr("class", "bar" + index)
							.attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
							.attr("y", margins.top + p.offset)
							.attr("width", () => { return xScaleBand.bandwidth() })
							.attr('fill', function (d) { return (d.value == null ? "rgba(0,0,0,0)" : getColor(d.value)) })
							.attr('stroke', 'none')
							.attr("height", 20);
						break;
					case ("time"):
						box.append('g')
							.selectAll("#rect_" + index)
							.data(a.list)
							.enter().append("rect")
							.attr("class", "bar" + index)
							.attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
							.attr("y", margins.top + p.offset)
							.attr("width", () => { return xScaleBand.bandwidth() })
							.attr('fill', function (d) { return (d.value == null ? "rgba(0,0,0,0)" : getColorTime(d.value)) })
							.attr('stroke', 'none')
							.attr("height", 20);
						break;
					case ("direction"):
						box.append('g')
							.selectAll("#rect_" + index)
							.data(a.list)
							.enter().append("rect")
							.attr("class", "bar" + index)
							.attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
							.attr("y", margins.top + p.offset)
							.attr("width", () => { return xScaleBand.bandwidth() })
							.attr('fill', function (d) { return (d.arc == null ? "rgba(0,0,0,0)" : getColorWind(d.arc)) })
							.attr('stroke', 'none')
							.attr("height", 20);
						break;
				}
				index++;
			})

		})
	}

}

// Dec
function rest (svg, items=[])
{
	//items = items;

	svg
		.attr("viewBox", [0, 0, svg.style('width'), svg.style('height')])
		.style("overflow", "visible")
		.style("-webkit-tap-highlight-color", "transparent")
		.on("pointerenter pointermove", pointermoved)
		.on("pointerleave", pointerleft)
		.on("touchstart", event => event.preventDefault());

	brush = d3.brushX()
		.extent([[margins.left, margins.top], [margins.width - margins.right, margins.height - margins.bottom]])
		.on("end", updateChart); // brushended

	svg.append("g")
		.attr('class', 'brush')
		.call(brush);

	let index = 0;

	// Legend
	index = 0;
	if (typeof (boxes) !== "undefined") {

		var tooltip = svg.append("g")
			.style("pointer-events", "none")
			.attr('id', 'tooltip');

		/*  */
		var path = tooltip.append("path")
			.attr('id', 'path')
			.data([,])
			.attr("class", "shadow")
			.attr("fill", "white")
			.attr("stroke", 'black')
			.attr("stroke-width", 1.5)
			.attr("stroke-linejoin", "round")
			.attr("stroke-linecap", "round");

		svg.append('line')
			.attr('id', 'cursorLine')
			.attr('stroke', 'grey')
			.attr('display', null);

		var tooltiptext = tooltip.append("text")
			.attr('id', 'tooltiptext')
			.style("pointer-events", "none")
			.attr('id', 'tooltiptext');

		// Date
		tooltiptext
			.append("tspan")
			.attr('id', 'date')
			.attr('text-anchor', 'start')
			.attr('font-size', 'smaller');

		// Tooltiptext Values
		index = 0;
		let h = 0;
		if (typeof (boxes) !== undefined) {
			boxes.map((p, i) => {
				p.buckets.map((a, j) => {
					tooltiptext
						.append("tspan")
						.attr('id', 'value' + index)
						.attr("x", 15)
						.attr("y", 20 + h * 20)
						.attr("stroke", d3.schemeSet1[index])
						.attr('text-anchor', 'start')
						.attr('display', items[index].active ? null : 'none')
						.attr('font-size', 'smaller');

					if (items[index] !== undefined)
						if (items[index].active) h = h+1;
					index++;
				})
			})
		}
	}


}

function pointermoved (event) {

	if (data === undefined)
		return;

	/* Time points */
	const X = d3.map(data.timeline, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d));
	/* Index points */
	const I = d3.map(data.timeline, (_, i) => i);

	//const boxes = data.boxes;
	//const X = d3.map(data.timeline, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d));
	const i = d3.bisectCenter(X, xScale.invert(d3.pointer(event)[0] - 5));

	//svg.selectAll('#dotgroup')
	//	.style('display', null);
	

	// dots
	var dotgroup = svg.select('#dotgroup')

	// cursorline
	svg.selectAll('#cursorLine')
		.style('display', null)
		.attr('x1', xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.timeline[i])))
		.attr('x2', xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.timeline[i])))
		.attr('y1', margins.top)
		.attr('y2', margins.height - margins.bottom);


	// Tooltip format
	var format = d3.timeFormat("%d.%m.%Y "); // - %H:%M

	// Tooltip text
	let index = 0;
	let ind = 0;
	let k = 0;
	boxes.map((p, m) => {
		p.buckets.map((a, n) => {

			svg.select("#date")
				.attr("x", 15)
				.attr("y", (_, i) => `${index * 1.1}`)
				.transition()
				.duration(200)
				.text(format(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.timeline[i])))
				.attr('font-size', '0.9em')
				.attr('font-weight', 'bold');

			switch (p.typ) {
				case ("line"):
					if (a.list[I[i]] !== undefined)
					{
						dotgroup
							.select('#dot_' + index)
							.style('display', null)
							.attr("cx", xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.timeline[i])))
							.attr("cy", yScale[m](a.list[i].value))
							//.attr("display", a.list[i].value === null ? "none": null);
							.attr("display", items[index].active ? null : "none");

						svg.select("#value" + index)
							.transition()
							.duration(200)
							.attr("y", 20 + k * 20)
							.text(a.list[I[i]].value !== null ? Math.round(a.list[I[i]].value * 10) / 10 + " " + a.sensor.unit : null);
						let rb = xScale(X[i]) > margins.width - 100 ? margins.width-100 : xScale(X[i]);
						svg.select("#tooltip")
							.style("display", null)
							.attr("text-anchor", "middle")
							.attr("transform", `translate(${rb},0)`);
					}
					break;
				case ("stick"):
				case ("time"):
				case ("direction"):
					if (a.list[I[i]] !== undefined)
					{
						svg.select("#value" + index)
							.transition()
							.duration(200)
							.text(Math.round(a.list[I[i]] != undefined ? a.list[I[i]].value * 10 : 0) / 10 + " " + a.sensor.unit);
						svg.select("#tooltip")
							.style("display", null)
							.attr("text-anchor", "middle")
							.attr("transform", `translate(${xScale(X[i])},0)`);
					}
					break;
			}
			if (items[index] !== undefined)
				if (items[index].active) k = k+1;
		
			index++;
			if (a.list[i] !== undefined)
				if (a.list[i].value !== null)
					ind += 1;
		})
	})

	// Textbox with values
	var tooltiptext = svg.select("#tooltiptext");
	tooltiptext.attr("transform", `translate(${0},${30 - 0})`);

	const { x, y, width: w, height: h } = tooltiptext.node().getBBox();

	var path = svg.select("#path");

	if (xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.timeline[i])) === margins.width - margins.right) {
		svg.select("#tooltip")
			.style("display", 'none');
	}

	path
		.style('display', null)
		.transition()
		.duration(200)
		.attr('d', `M 0 20 l 10 -10 H${w + 20} V${h + 25} H10 V25 Z`);
}

function pointerleft() {

	if (data === undefined)
		return;

	const boxes = data.boxes;

	svg.select("#tooltip")
		.style("display", 'none');

	svg.node().value = null;
	svg.dispatch("input", { bubbles: true });

	let index = 0;
	boxes.map((p, i) => {
		p.buckets.map((a, j) => {
			a.list.map((d, f) => {
				svg.select("#dot_" + index++)
					.style('display', 'none');
			})
		})
	})

	var cursorLine = svg.selectAll('#cursorLine')
		.style('display', 'none');

	svg.selectAll('#path')
		.style('display', 'none')
}


// A function that update the chart for given boundaries
function updateChart (event) {

	var extentX = event.selection

	var t1;

	// If no selection, back to initial coordinate. Otherwise, update X axis domain
	if (!extentX) {
		if (!idleTimeout) return idleTimeout = setTimeout(idled, 350); // waiting a bit

		xScale.domain([data.minTime, data.maxTime]);
		xScaleBand.domain(d3.map(data.timeline, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d)));
	}
	else {
		var from = xScale.invert(extentX[0]);
		var to = xScale.invert(extentX[1]);

		xScale.domain([xScale.invert(extentX[0]), xScale.invert(extentX[1])]);

		var t = d3.map(data.timeline, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d));
		t1 = t.filter(item => item >= from && item <= to);
		xScaleBand.domain(t1);

		// This remove the grey brush area as soon as the selection has been done
		svg.select(".brush").call(brush.move, null)
	}

	// Update axis and circle position
	xAxis.transition()
		.duration(1000)
		.call(d3.axisBottom(xScale));

	// Bottom
	xGrid.transition()
		.duration(1000)
		.call(d3.axisBottom(xScale).tickSize(-margins.height + margins.top + margins.bottom, 0).tickFormat(""));

	// Data
	var index = 0;
	if (typeof (boxes) != "undefined") {
		boxes.map((p, i) => {
			p.buckets.map((a, j) => {
				switch (p.typ) {
					case ("line"):
					var line = d3.line()
							.x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
							.y((d) => { return (d.value === null ? null : yScale[i](d.value)) })
							.defined(((d) => { return d.value !== null} ));
							//.attr('display', items[index].active ? null : 'none');
						if (a.typ == 1 || a.typ == 3) {
							clip.select("#path_" + index)
								.transition()
								.duration(1000)
								.attr('d', line);
						}
						else {
							clip.select("#box_" + i).selectAll(".bar" + index)
								.transition()
								.duration(1000)
								.attr("x", d => { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
								.attr("width", () => { return xScaleBand.bandwidth() });
						}
						break;
					case ("stick"):
					case ("time"):
					case ("direction"):
						clip.select("#box_" + i).selectAll(".bar" + index)
							.transition()
							.duration(1000)
							.attr("x", d => { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
							.attr("width", () => { return xScaleBand.bandwidth() })
							.attr("y", margins.top + p.offset);
						break;
				}
				index++;
			})
		})
	}
}


var idleTimeout
function idled() { idleTimeout = null; }

// Beschriftungen: Titel, x-Axis, Y-Axis
function label (svg, title, xLabel, yLabel) {

    svg.append("text")
	.attr("x", svg.attr("width") / 2 )
	.attr("y", 10)
	.style("text-anchor", "middle")
	.style("font-size", "18px")
	.text(title);

    
    //Create X axis label   
    svg.append("text")
	.attr("x", svg.attr("width") / 2 )
	.attr("y",  svg.attr("height") - 5 )
	.style("text-anchor", "middle")
	.style("font-size", "14px")
	.text(xLabel);

    //Create Y axis label
    svg.append("text")
	.attr("transform", "rotate(-90)")
	.attr("y", 10)
	.attr("x",0 - (svg.attr("height") / 2))
	.attr("dy", "1em")
	.style("text-anchor", "middle")
	.style("font-size", "14px")
	.text(yLabel);
}


// Beschriftungen: Titel, x-Axis, Y-Axis
function xlabel (svg, label, x, y) {

	svg.append("text")
	.attr("class", "x label")
	.attr("text-anchor", "middle")
	.attr("x", x)
	.attr("y", y)
	.style("font-size", "14px")
	.text( label );

}

function ylabel (svg, label, x, y) {

	// Y Label
	svg.append("text")
		.attr("class", "y label")
		.attr("text-anchor", "middle")
		.attr("transform", "rotate(-90)")
		.attr("x", x)
		.attr("y", y)
		.style("font-size", "14px")
		.text( label );

}


function logo (svg) 
{
	let width = parseInt(svg.style('width'));
	let height = parseInt(svg.style('height'));

	var pathgroup = svg
		.append('g')
		.attr('id', 'logo')
		.attr('transform', "rotate(180 0 0) translate(-"+width+", -"+height+") scale(0.15 0.15)")

	pathgroup
		.append('g')
		.attr('transform', "translate(102.7689,2.1254)")
		.append('path')
			.attr('d', "m 0,0 c 0,0 6.693,32.806 1.22,68.312 -8.469,54.941 -72.966,74.051 -83.801,57.987 C -93.068,110.751 -57.196,65.706 1.22,68.312")
			.attr('style', "fill:none;stroke:#00945f;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1")
		
	pathgroup
		.append('g')
		.attr('transform', "translate(102.8458,70.4371)")
		.append('path')
			.attr('d', "m 0,0 c 16.721,15.418 32.492,39.06 37.786,72.748 5.392,34.311 -13.397,51.901 -22.874,51.901 -9.476,0 -28.159,-17.59 -22.947,-51.901 C -3.558,43.278 12.812,15.418 28.448,0")
			.attr('style', "fill:none;stroke:#00945f;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1")

	pathgroup
		.append('g')
		.attr('transform', "translate(132.9323,2.1254)")
		.append('path')
			.attr('d', "m 0,0 c 0,0 -6.899,32.806 -1.425,68.312 8.469,54.941 72.965,74.051 83.8,57.987 C 92.863,110.751 56.99,65.706 -1.425,68.312")
			.attr('style', "fill:none;stroke:#00945f;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1")

	pathgroup
		.append('g')
		.attr('transform', "translate(132.9323,2.1254)")
		.append('path')
			.attr('d', "m 0,0 c 54.171,7.068 95.957,52.416 95.957,107.304 0,59.792 -49.584,108.262 -110.748,108.262 -61.164,0 -110.748,-48.47 -110.748,-108.262 0,-54.887 41.783,-100.234 95.952,-107.303")
			.attr('style', "fill:none;stroke:#00945f;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1")

}

export { dimensions, rest, label, xlabel, ylabel, logo };
