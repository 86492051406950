import { useState, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import Layout from './routes/Layout';
import { createTheme, colors, ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from 'react-auth-kit';
import './i18n';

import './App.css';
//import { GlobalStyles } from "./GlobalStyle";

const theme = createTheme({
  palette: {
    primary: {
      main: "#40516f",
    },
    secondary: {
      main: "#355E3B",
    },
  },
  typography: {
    fontFamily: [
      'Avenir',
      'Roboto',
      'sans-serif',
    ].join(','),
  },
  /*typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },*/
  overrides: {
    MuiInputBaseInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "green"
        },
        "&:hover $notchedOutline": {
          borderColor: "red"
        },
        "&$focused $notchedOutline": {
          borderColor: "purple"
        },
        "&&& $input": {
          padding: "1px"
        }
      }
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
	  color: 'black',
        },
      },
    },
    MuiTableHeader: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          backgroundColor: '#008051',
          color: "white",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          //backgroundColor: '#008051',
          //color: "white",
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: "#fff",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          //backgroundColor: '#008051',
          //color: "white",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          width: '100%',
          backgroundColor: 'var(--color-gray-100)',
        },
        icon: {
          color: 'var(--color-secondary)',
        },
      },
    },
  },

});

const App = () => {

  return (
    <>
    {/*<GlobalStyles /> */}
    <AuthProvider authType = {'cookie'}
                  authName={'_auth'}
                  cookieDomain={window.location.hostname}
                  cookieSecure={false}>
       <ThemeProvider theme={theme} >
          <BrowserRouter>
            <Layout />
          </BrowserRouter>
        </ThemeProvider>
    </AuthProvider>
       
    </>
  );
}

export default App;
