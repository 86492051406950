// Graph

import { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Dialog from '@mui/material/Dialog';
import DatePicker from './DatePicker';
import { GraphContext } from "../routes/GraphContext";
import dayjs from 'dayjs';


import { getCurrentYear, now, addDays, addMonths, addYears } from "../js/datetime";

// Main part with time buttons ans graph
const TimeButton = (props) => { // {setFrom, setTo}

	const { t, i18n } = useTranslation();

	const [parameter, setParameter] = useContext(GraphContext);

	/*  */
	const [from, setFrom] = useState(addDays(-3));
	const [to, setTo] = useState(now());

	const [fromdjs, setFromdjs] = useState(dayjs());
	const [todjs, setTodjs] = useState(dayjs());

	const data = [t("year"), t("month"), t("week"), t("3 days"), t("custom")];

	const [number, setNumber] = useState(parameter.time !== undefined ? parameter.time : 3);

	const handleFrom = (event) => {

		setFromdjs(event);
		let date = event.$d;
		let format = (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
		setFrom(format);

		setParameter({ ...parameter, from: format });
	}

	const handleTo = (event) => {

		setTodjs(event);
		let date = event.$d;
		let format = (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
		setTo(format);

		setParameter({ ...parameter, to: format });
	}

	const handleListItemClick = (event) => {
		setOpen(false);

		setNumber(event);

		if (event === 0) {
			let from = addYears(-1);
			let to = now();
			setParameter({ ...parameter, from: from, to: to, time: event });
		}
		if (event === 1) {
			let from = addMonths(-1);
			let to = now();
			setParameter({ ...parameter, from: from, to: to, time: event });
		}
		if (event === 2) {
			let from = addDays(-7);
			let to = now();
			setParameter({ ...parameter, from: from, to: to, time: event });
		}
		if (event === 3) {
			let from = addDays(-3);
			let to = now();
			setParameter({ ...parameter, from: from, to: to, time: event });
		}
	};

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
	};

	return (
		<>
			<div className="flex">
			{number == "4" &&
				<>
					<div className="flex-item" >
						<DatePicker className="flex-item" value={fromdjs} callback={handleFrom} />
					</div>
					<div className="flex-item" >
						<DatePicker value={todjs} callback={handleTo} />
					</div>
				</>}


			{data.map((item, i) => (
				number === i &&
				<div key={i} className="small_screen">
				<Button
				    key={i}
					color={"primary"}
					onClick={handleClickOpen}
					variant="contained"
					sx={{
						fontSize: "12px",
						padding: "4px",
						whiteSpace: 'nowrap'
					}}
				>
					{item}
				</Button>
				</div>
			))}
			<div className="large_screen">
			<ButtonGroup>
			{data.map((item, index) => (
				<Button
					key={index}
					color={"primary"}
					variant={number == index ? 'contained' : 'outlined'}
					onClick={() => handleListItemClick(index)}
					sx={{
						fontSize: "12px",
						padding: "4px",
						whiteSpace: 'nowrap'
					}}
				>
					{item}
				</Button>
			))}
			</ButtonGroup>
			</div>
			</div>

			<Dialog
				onClose={handleClose}
				open={open}>

				<List sx={{ pt: 0 }}>
					{data.map((item, index) => (
						<ListItem key={index} disableGutters>
							<ListItemButton onClick={() => handleListItemClick(index)}>
								<ListItemText style={{ color: '#000000' }} primary={item} />
							</ListItemButton>
						</ListItem>
					))}

				</List>
			</Dialog>
		</>
	)
}

export default TimeButton; 