import { useState, useEffect, useContext } from "react";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";

import dayjs from 'dayjs';

import { GraphContext } from "../../GraphContext";
import { getCurrentYear, getCurrentMonth } from "../../../js/datetime";
import { preferences } from "../../../preferences";


import DatePicker from '../../../components/DatePicker';
import StationButton from "../../../components/StationButton";
import Title from "../../../components/Title";
import StackRow from "../../../components/StackRow";

import { TableZS } from "./tables/Temperature";
import { t } from "i18next";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';


export const Ranking = () => {

    const { trackPageView } = useMatomo()
    const location = useLocation();

    const [parameter ] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [year, setYear] = useState(getCurrentYear());
    const [month, setMonth] = useState(getCurrentMonth());

    const [ sensors, setSensors ] = useState([]);
    
    // Track page view                                                                                                                              
    useEffect(() => {
	trackPageView({
            documentTitle: 'Ranking', // optional                                                                       
            href: 'https://meteo.laimburg.it'+location.pathname, // optional
            customDimensions: [
		{
		    id: 1
		},
            ], // optional
	})
    }, [trackPageView, location])

    useEffect(() => {

        let station = parameter.station;

        let url = preferences.address + '/rest/activesensors.php?station=' + station;
        
        fetch(url)
            .then((response) => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw Error(response.statusText);
                }
            })
            .then((data) => {
                setSensors(data)
            })
            .catch((err) => {
                console.log(err);
            });
    }, [month, year, parameter])

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelector = (event) => {

        setFromdjs(event);

        let date = event.$d;

        setYear(parseInt(1900 + date.getYear()));
        setMonth(date.getMonth() + 1);
    }

    return (
            <>
            <div className="stagearea grid p-2">

            <Title text={t("ranking")} />

            <Stack spacing={0}>

            <StackRow text="station" component={<StationButton />} />

            <Item>
            <DatePicker
        title="month"
        value={fromdjs}
        callback={handleSelector}
        view={["month", "year"]}
        format="MMM YYYY"
            />
            </Item>
            </Stack>

            <Grid className="par" container spacing={5}>

	{sensors.map((item) => {return item.name})}

        {sensors.map((item, index) => {
	    return (
		    <TableZS key={item+"TB"} sensor={item} month={month} year={year} />
	    )
	})}
	
	{/*
	    <TableZS sensor="airtemp2m" month={month} year={year} />
	    <TableZS sensor="soiltemp1" month={month} year={year} />
            <TableZS sensor="soiltemp2" month={month} year={year} />
            <TableZS sensor="rain" month={month} year={year} />
            <TableZS sensor="globalrad" month={month} year={year} />
            <TableZS sensor="humidity" month={month} year={year} />
	 */}

        </Grid>

        </div>
            </>
    );
}
