import * as React from 'react';
import { useState } from "react";
import { Typography } from '@mui/material'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Avatar from '@mui/material/Avatar';
import { useSignIn } from 'react-auth-kit'

import { preferences } from "../preferences";

const LoginDialog = ({ open, setOpen }) => {

	const [ username, setUsername ] = useState("");
	const [ password, setPassword ] = useState("");
	const [error, setError ] = useState();

	const signIn = useSignIn()

	const handleToClose = () => {

		setOpen(false);
		setError (false);
	};

	const handleLogin = async () => {

		let block = {};
		block.username = username;
		block.password = password;

		fetch (preferences.address + '/login.php', {
			method: 'POST',
			mode: 'cors',
			cache: "no-cache",
			body: JSON.stringify(block)
			})
			.then((response) => response.json())
			.then((data) => {
			
				if (data.bearer !== null)
				{
				signIn (
					{
						token: data.bearer,
						expiresIn: data.expires,
						tokenType: "Bearer",
						authState: data.authUserState,
					}
				)
				setOpen(false);
				setError (false);
				}
				else {
					// Error
					setError(true);
				}	
		});
	};

	return (
		<>
			<Dialog open={open} onClose={handleToClose} >
				<DialogTitle>{"Login"}</DialogTitle>
				<DialogContent>

					<div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>

						<Avatar
							alt="Remy Sharp"
							src="/static/images/avatar/1.jpg"
							sx={{ width: 72, height: 72 }}
						/>

						<TextField
							autoFocus
							margin="dense"
							id="username"
							label="Username"
							type="name"
							value={username}
							onChange={(e) =>{setUsername(e.target.value)}}
							error={error}
							fullWidth
						/>

						<TextField
							autoFocus
							margin="dense"
							id="password"
							label="Password"
							type="password"
							value={password}
							onChange={(e) =>{setPassword(e.target.value)}}
							error={error}
							fullWidth
						/>

						{error &&
							<Typography>Wrong Username or Password</Typography>
						}

					</div>



				</DialogContent>
				<DialogActions>
					<Button onClick={handleToClose}
						color="primary" variant="contained" autoFocus>
						Cancel
					</Button>
					<Button onClick={handleLogin}
						color="primary" variant="contained" autoFocus>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default LoginDialog;
