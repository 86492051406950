import { useState, useContext, useEffect } from "react";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { saveAs } from 'file-saver';


import { GraphContext } from "../GraphContext";
import "../../styles.css";

import dayjs from 'dayjs';

import { preferences } from "../../preferences";

import { getCurrentYear, getCurrentMonth } from '../../js/datetime';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';

export const PhenologieOverview = ({description, list}) => {

    const [parameter, setParameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [year, setYear] = useState (getCurrentYear ());
    const [month, setMonth] = useState (getCurrentMonth());

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelector = (event) => {

        setFromdjs(event);

        let date = event.$d;
        let format = (1900+date.getYear())+"-"+(date.getMonth()+1)+"-"+date.getDate();

        setYear (1900+date.getYear());
        setMonth (date.getMonth()+1);
	}

    return (
        <>
            <Stack spacing={1}>
                
                <Item>
                    <Box 
                        sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                        className="p-1">

                        <Typography sx={{verticalAlign: "middle"}}>
                            Jahr
                        </Typography>
                        
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                            <DesktopDatePicker className="datepicker" 
                                views={["year"]}
                                sx={{'& .MuiInputBase-input':{
                                    padding: '4px 8px',
                                    },
                                    '& .MuiFormControl-root':{
                                    padding: '0px',
                                    }
                                }}
                                format="YYYY"
                                value={fromdjs}
                                onChange={handleSelector}
                            />
                        </LocalizationProvider>
                        
                    </Box>
                </Item>

            </Stack>
       
    </>
    );

}