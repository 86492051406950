import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { GraphContext } from "../../GraphContext";
import { preferences } from '../../../preferences';

import Typography from '@mui/material/Typography';

import * as React from 'react';
import { useState, useContext, useEffect } from "react";
import ActiveButton from "./ActiveButton";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Snackbar from "@mui/material/Snackbar";
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";


import ToggleButton from '../../../components/ToggleButton';
import DatePicker from '../../../components/DatePicker';

import { getCurrentYear, now, addDays, addMonths, addYears } from '../../../js/datetime';

const DownloadDialog = ({ open, setOpen, closeDialog }) => {

	const { t, i18n } = useTranslation();

	const [parameter, setParameter] = useContext(GraphContext);

	const [sensors, setSensors] = useState([]);

	const [fromdjs, setFromdjs] = useState(dayjs().set('date', 1));
	const [todjs, setTodjs] = useState(dayjs());

	const [from, setFrom] = useState(addDays(-28));
	const [to, setTo] = useState(now());

	const [button, setButton] = useState(1);
	const [level, setLevel] = useState(3);

	const [opensnack, setOpensnack] = useState(false);

	const Item = styled(Paper)(({ theme }) => ({

		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	}));

	function now() {
		let date = new Date();
		return (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
	}

	const downloadFile = () => {

		// Snak bar
		setOpensnack(true);

		switch (button) {
			case (1):
				downloadExcel();
				break;
			case (2):
				download_CSV();
				break;
			case (3):
				download_JSON();
				break;

		}

		setOpen(false);
	};

	function setMain(index, avg) {
		if (avg) { sensors[index].main = true; } else { sensors[index].main = false; }
	}

	function setMin(index, avg) {
		if (avg) { sensors[index].min = true; } else { sensors[index].min = false; }
	}

	function setMax(index, avg) {
		if (avg) { sensors[index].max = true; } else { sensors[index].max = false; }
	}




	/* Sensors */
	useEffect(() => {

		let station = parameter.station;
		var url = preferences.address + '/rest/sensors.php?station=' + station;

		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				data.map((sensor) => {
					sensor.main = true;
					sensor.min = false;
					sensor.max = false;
				})
				setSensors(data);
			});
	}, [parameter])

	//console.log (dayjs());


	const downloadExcel = () => {

		closeDialog();

		var url = preferences.address + '/query/download/excel.php';
		setOpensnack (true);

		let block = {};
		block.sensors = sensors;
		block.station = parameter.station;
		block.level = level;
		block.from = from;
		block.to = to;
		block.lang = i18n.language;

		let filename = '';
		fetch(url, {
			method: 'POST',
			mode: 'cors',
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
			},
			body: JSON.stringify(block)
			})
			.then((response) => {
			    setOpensnack(false);
			    try {
				filename = response.headers.get('content-disposition').split('filename=')[1];
			    }
			    catch (error)
			    {filename = 'Data_Export.xlsx'}
				return response.blob() })
			.then((data) => {
				var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
				saveAs(file);
			});

	}

	const download_CSV = (() => {

		var url = preferences.address + '/query/download/csv.php';

		let block = {};
		block.sensors = sensors;
		block.station = parameter.station;
		block.level = level;
		block.from = from;
		block.to = to;
		block.lang = i18n.language;

		let filename = '';
		fetch(url, {
			method: 'POST',
			mode: 'cors',
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
			},
			body: JSON.stringify(block)
			})
			.then((response) => {
			    setOpensnack(false);
			    try {
				filename = response.headers.get('content-disposition').split('filename=')[1];
			    }
			    catch (error)
			    {filename = 'Data_Export.csv'}
				return response.text() })
			.then((data) => {
				var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
				saveAs(file);
			});


	})

	const download_JSON = (() => {
		console.log("Download JSON");
		/*var link = document.createElement("a");
		//link.download = "Export.json";
		link.setAttribute("download", "Export.json");
		link.href = "http://meteo.localhost/queries/values.php?sensor=8&from=2023-3-12&to=2023-3-12&level=3";
		link.click();

		var url = preferences.address + '/query/download/csv.php';
		let fileName = "Export.json";

		const f = fetch(uri)
			.then((response) => response.json())
			.then((data) => {
				var file = new File([JSON.stringify(data)], fileName, { type: "application/json;charset=utf-8" });
				saveAs(file);
			});*/

		var url = preferences.address + '/query/download/json.php';

		let block = {};
		block.sensors = sensors;
		block.station = parameter.station;
		block.level = level;
		block.from = from;
		block.to = to;
		block.lang = i18n.language;

		let filename = '';
		fetch(url, {
			method: 'POST',
			mode: 'cors',
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			headers: {
			},
			body: JSON.stringify(block)
			})
			.then((response) => {
			    setOpensnack(false);
			    try {
				filename = response.headers.get('content-disposition').split('filename=')[1];
			    }
			    catch (error)
			    {filename = 'Data_Export.json'}
				return response.text() })
			.then((data) => {
				var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
				saveAs(file);
			});

	})


	const handleClickToOpen = () => {
		//setOpen(true);
	};

	const handleToClose = () => {
		//setOpen(false);
		//props.closeDialog();
	};

	const datePickerHandleFrom = (event) => {
		setFromdjs(event);
		let date = event.$d;
		let format = (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
		setFrom(format);
	}

	const datePickerHandleTo = (event) => {
		setTodjs(event);
		let date = event.$d;
		let format = (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
		console.log(format)
		setTo(format);
	}

	const setExcel = (() => {
		setButton(1);
	})

	const setCSV = (() => {
		setButton(2);
	})

	const setJSON = (() => {
		setButton(3);
	})


	const setRow = (() => {
		setLevel(1);
	})

	const setHour = (() => {
		setLevel(2);
	})

	const setDay = (() => {
		setLevel(3);
	})

	const setMonth = (() => {
		setLevel(4);
	})

	const setYear = (() => {
		setLevel(5);
	})


	const handleCloseSnackBar = (event: SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
	};

	const action = (
		<React.Fragment>
		</React.Fragment>
	);



	return (
		<>
			<Dialog open={open} onClose={handleToClose} >
				<DialogTitle>{t("Download")}</DialogTitle>
				<DialogContent>

					<DialogContentText>
					
						<Stack className="stackdialog" spacing={0} sx={{ minWidth: '500px' }}>
							<Item>
								<Box
									sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
									className="p-1"
								>
									<Typography>
										{t("format")}
									</Typography>
									<ButtonGroup size="small" className="p-3" variant="text" aria-label="Time">
										<ActiveButton key="1" callbackfunction={setExcel} active={button == 1 ? true : false} buttontext="Excel" />
										<ActiveButton key="2" callbackfunction={setCSV} active={button == 2 ? true : false} buttontext="CSV" />
										<ActiveButton key="3" callbackfunction={setJSON} active={button == 3 ? true : false} buttontext="JSON" />
									</ButtonGroup>

								</Box>
							</Item>

							<Item>
								<DatePicker title={t("from")} value={fromdjs} callback={datePickerHandleFrom} />
							</Item>

							<Item>
								<DatePicker title={t("to")} value={todjs} callback={datePickerHandleTo} />
							</Item>

							<Item>
								<Box
									sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
									className="p-1">
									<Typography>
										{t("resolution")}
									</Typography>

									<ButtonGroup size="small" className="p-3" variant="text" aria-label="Time">

										<ActiveButton key="1" callbackfunction={setRow} active={level == 1 ? true : false} buttontext={t("rowly")} />
										<ActiveButton key="2" callbackfunction={setHour} active={level == 2 ? true : false} buttontext={t("hourly")} />
										<ActiveButton key="3" callbackfunction={setDay} active={level == 3 ? true : false} buttontext={t("daily")} />
										<ActiveButton key="4" callbackfunction={setMonth} active={level == 4 ? true : false} buttontext={t("monthly")} />
										<ActiveButton key="5" callbackfunction={setYear} active={level == 5 ? true : false} buttontext={t("annually")} />

									</ButtonGroup>
								</Box>
    							</Item>
    									<Item>
										<Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }} className="p-1">

											<div>{t("list_sensors")}</div>

										</Box>
									</Item>



							{sensors.map((sensor, index) => {
								return (
									<Item key={index}>
										<Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }} className="p-1">

											<ToggleButton 
												key={index}
												active={sensor.main} 
												index={index}
												setting={setMain} text={t(sensor.description)+" "+(sensor.level!==null ? sensor.level : "") } 
												sx={{ flexGrow: '10', margin:'2px' }}
											/>
											<div className="flex-grow"></div>
											{sensor.typ===1 && <ToggleButton active={sensor.min} size="" index={index} setting={setMin} text="min" />}
											{sensor.typ===1 && <ToggleButton active={sensor.max} size="" index={index} setting={setMax} text="max" />}

										</Box>
									</Item>
								)
							})}

						</Stack>

					</DialogContentText>


				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialog}
						color="primary" variant="contained" autoFocus>
						Cancel
					</Button>
					<Button onClick={downloadFile}
						color="secondary" variant="contained" autoFocus>
						Download
					</Button>
				</DialogActions>
			</Dialog>


			<Snackbar
				open={opensnack}
				autoHideDuration={6000}
				onClose={handleCloseSnackBar}
				message="Downloading ..."
				action={action}
			/>
		</>
	)
}

export default DownloadDialog;
