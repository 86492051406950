import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
	  
    return (
	    <div
	role="tabpanel"
	hidden={value !== index}
	id={`simple-tabpanel-${index}`}
	aria-labelledby={`simple-tab-${index}`}
	{...other}
	    >
	    {value === index && (
		    <Box sx={{ p: 3 }}>
		    <Typography component={'div'} >{children}</Typography>
		    </Box>
	    )}
	</div>
    );
}

export { TabPanel };
