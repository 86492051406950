
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { Cols } from './Cols';

export const Row = ({ loc, date, row, keys, color }) => {

    return (
        <>
            <TableRow sx={{ overflow: 'auto', backgroundColor: color !== undefined ? color : "#ffffff"}}>
                <TableCell align="right">
                    {date}
                </TableCell>

                {keys.map((key, index) => {
		    //console.log (key.name, row[key.name]);
                    return (
                        <Cols key={date+key.name} loc={loc} date={date} values={row[key.name]} index={index} cat={key.name} />
                    )
                })}

            </TableRow>
        </>
    )

}