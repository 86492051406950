import * as d3 from "d3";
import * as ss from "simple-statistics";

import { useState, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import { preferences } from "../../preferences";


import SensorComboBox from '../../components/SensorComboBox';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';

import { xlabel, ylabel, logo } from '../../components/d3/charts/chart';

import StationButton from "../../components/StationButton";
import StackRow from "../../components/StackRow";

import { useMatomo } from '@datapunt/matomo-tracker-react'

export const Development = ({ description, list }) => {


    const { trackPageView } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter] = useContext(GraphContext);

    const [sensor, setSensor] = useState(1);
    const [sensorstring, setSensorstring] = useState(); // "airtemp2m"
    const [sensors, setSensors] = useState([]);

    const [data, setData] = useState();
    const [variety, setVariety] = useState(1);

    const [combo1, setCombo1] = useState(1);
    const [combo2, setCombo2] = useState(5);

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        let station = parameter.station;
        var url = preferences.address + '/rest/sensors.php?station=' + station;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                data.map((sensor) => {
                    sensor.main = true;
                    sensor.min = false;
                    sensor.max = false;
                    return 0;
                })

                setSensors(data);
                setSensorstring("airtemp2m");
                setSensor(2);
            });
    }, [parameter])


    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined) {
            draw(svg);
        }

    }, [resize, data]);

    useEffect(() => {

        d3.json(preferences.address + "/query/climate/temperature/development/get.php?station=" + parameter.station + "&sensor=" + sensorstring)
            .then((data) => {
                setData(data);
            });
    }, [parameter, variety, combo1, combo2, sensorstring]);


    // Track page view                                                                                                                           
    useEffect(() => {
        trackPageView({
            documentTitle: 'Phänologie Stadiumvergleich', // optional                                                                                             
            href: 'https://meteo.laimburg.it' + location.pathname, // optional                                                                         
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ], // optional                                                                                                                           
        })
    }, [trackPageView, location, i18n.language])


    const downloadExcel = () => {

        var url = preferences.address + '/query/climate/temperature/development/excel.php';

        let block = {};
        block.station = parameter.station;
	block.sensorstring = sensorstring;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);

            });

	    }
    
    const handleSensor = (event) => {

        //let station = parameter.station;                                                                                                                                                                                                                                                
        let index = event.target.value - 1;

        setSensor(event.target.value);
        setSensorstring(sensors[index].cat);
    }


    function rSquared(x, y, coefficients) {

        let regressionSquaredError = 0
        let totalSquaredError = 0

        function yPrediction(x, coefficients) {
            return coefficients[0] + coefficients[1] * x
        }

        let yMean = y.reduce((a, b) => a + b) / y.length

        for (let i = 0; i < x.length; i++) {
            regressionSquaredError += Math.pow(y[i] - yPrediction(x[i], coefficients), 2)
            totalSquaredError += Math.pow(y[i] - yMean, 2)
        }

        return 1 - (regressionSquaredError / totalSquaredError)

    }

    function draw(svg) {

        var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 20,
            right: 40,
            bottom: 40,
            left: 80,
        }; // margin of chart

        let width = parseInt(svg.style("width"));

        let min = d3.min(data.list.map((d) => { return d.year }));
        let max = d3.max(data.list.map((d) => { return d.year }));

        // Anzahl Jahre
        let height = 600;
        svg.attr("height", height);


        //Append group and insert axis

        let xScaleBand = d3.scaleBand()
            .domain(data.list.map((d) => d.year))
            .range([margin.left, width - margin.right])
	    .padding(0.1);
        let xScaleBandRow = d3.scaleBand()
            .domain(data.list.map((d) => d.year))
            .range([margin.left, width - margin.right]);
        let xAxis = d3.axisBottom(xScaleBand);
        svg.append("g")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis.tickFormat((x) => { return x }).ticks(20));
        //let xAxisBand = d3.axisBottom(xScaleBand);

        // X Axis
        let xScale = d3.scaleLinear()
            .domain([min, max])
            .range([margin.left+xScaleBandRow.bandwidth()/2, width - margin.right - xScaleBandRow.bandwidth()/2]);
        /*let xAxis = d3.axisBottom(xScale);
        svg.append("g")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis.tickFormat((x) => { return x }).ticks(20));
        svg.append("g")
            .attr("class", "gridline")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis.tickSize(-height + margin.top + margin.bottom, 0).tickFormat("").ticks(20));*/

        // Y Axis
        var yScale = d3.scaleLinear()
            .domain([ d3.min([ 0, d3.min(data.list.map((d) => { return d.days }))]), d3.max([0, d3.max(data.list.map((d) => { return d.days }))]) ])
            .range([height - margin.bottom, margin.top]);
        let yAxis = d3.axisLeft(yScale);
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(yAxis.tickFormat((x) => { return x + " " + data.unit }))
        //let yGrid =
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(yAxis.tickSize(-width + margin.left + margin.right, 0).tickFormat("").ticks(20));

        let clip = svg.append('g')
            .attr("clip-path", "url(#clip)");


        // Add the circles
        /*svg.selectAll("circles")
            .data(data.list)
            .enter()
            .append("circle")
            .attr("fill", "white")
            .attr("stroke", "black")
            .attr("cx", (d, i) => { return xScale(d.year) })
            .attr("cy", (d) => { return yScale(d.days) })
            .attr("r", 3)
            .attr("stroke-width", 2.5)
            .attr('display', (d) => { return d.days === null ? 'none' : '' })
            .append("title")
            .text((d) => { return d.year });*/

        clip.selectAll(".bar")
	    .data(data.list)
	    .enter()
            .append("rect")
	    .attr("class", "bar")
            .style("pointer-events", "none")
	    .attr("x", function (d) { return xScaleBand(d.year) })
            .attr("y", (d) => { return yScale(d.days>0 ? d.days : 0) }) //+ height - margin.bottom - yScale(0)
	    .attr("width", (d) => { return xScaleBand.bandwidth()})
            .attr("height", (d) => { return yScale(d.days > 0 ? 0 : d.days) - yScale(d.days > 0 ? d.days : 0) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
	    .attr("opacity", 0.75)
            .attr('fill', d3.schemeCategory10 [sensor-1 > 10 ? sensor-10 : sensor-1]);

	// Beschriftung
	//let max_width = d3.max(data.list.map((d) => {Math.round(d.days*10)/10}));//.offsetWidth
	if (xScaleBand.bandwidth() > 40)
	{
	    let labels = svg.append("g");
            labels.selectAll(".bar")
		.data(data.list)
		.enter()
		.append("rect")
		.attr("class", "bar")
		.attr("x", function (d) { return xScaleBand(d.year) })
		.attr("y", (d) => { return yScale(d.days > 0 ? d.days : 0) + 10}) //+ height - margin.bottom - yScale(0)
		.attr("width", (d) => { return xScaleBand.bandwidth()})
		.attr("height", 20)
		.attr("stroke", "black")
		.attr("stroke-width", 1.5)
		.attr('fill', "white")
		.attr("opacity", 0.80)
		.attr("rx", 5).attr("ry", 5);

            labels.selectAll(".label")
		.data(data.list)
		.enter()
		.append ("text")
		.attr("class", "label")
		.attr("x", function (d) { return xScaleBand(d.year)+xScaleBand.bandwidth()/2 })
		.attr("y", (d) => { return yScale(d.days > 0 ? d.days : 0) + 10+15})
		.text ((d) => Math.round(d.days*10)/10);
	}

        let learn = [];
	let year = new Date().getFullYear();
	let fList = data.list.filter((d) => {return d.year < year});
        fList.map((d) => { if (d.days !== null) learn.push([d.year, d.days]); return 0; })
        const reg = ss.linearRegression(learn);
        let lm = ss.linearRegressionLine(reg);


        clip.append("path")
            .datum(fList)
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function (d, i) { return xScale(d.year) })
                .y(function (d) { return yScale(lm(d.year)) })
            )

        let x = fList.map((d) => { return d.year });
        let y = fList.map((d) => { return d.days });

        try {
            let r = rSquared(x, y, [reg.b, reg.m]);
            //console.log(r);
            xlabel(svg, "y=" + Math.round(reg.m * 100000) / 100000 + "*x +(" + Math.round(reg.b * 1000) / 1000 + ")", width / 3, 15);
            xlabel(svg, "R²=" + Math.round(r * 1000) / 10 + "%", width * 2 / 3, 15);
        } catch (error) { console.log(error) };

        xlabel(svg, t("years"), width / 2, height - 5);
        ylabel(svg, t(data.description), -height * 0.5, 15);
        logo(svg);

        //bordersH (svg, ["", ""], 260, 70, 0);
        //addAreaH (svg, 0, "orange", t("dry"))

        //let colors = ['rgba(255,255,255,1)', '#084594', '#000000'];
        //label_mid (svg, colors, 0, 20, 80, "mm", width - 50, 30);

        //addAreaH (svg, 1, "#084594", t("rain"))

    }

    /*const excelDownloadCallback = () =>
    {

        d3.json(preferences.address + "/query/climate/dry_excel.php?station=" + parameter.station + "&period=" + period + "&level=" + threshold)
            .then((data) => {
                var file = new File([data], "Export.xlsx", { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
            });

    }*/

    const handleSelector = (event) => {
        setVariety(event.target.value);
    };

    return (
        <>
            <div className="stagearea grid p-2">


                <StackRow text={t("station")} component={<StationButton />} />

                <Stack spacing={0}>


		<Item>

                    {sensors !== undefined &&
			<SensorComboBox
                            title={"sensor"}
                            value={sensor} items={sensors}
                            callback={handleSensor} />
                    }

		</Item>

                </Stack>

                <div id="svgchart">
                    <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
                </div>

                <Grid container flexDirection={"row-reverse"}>

                    <Grid item>
                        <GraphicButton object="svg_chart" filename={t("dry")} />
                    </Grid>

                    <Grid item className="p-2">
                        <ExcelButton callback={downloadExcel} />
                    </Grid>

                </Grid>
            </div>
        </>
    );

}
