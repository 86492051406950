// Graph
import { useState, useEffect, useContext } from "react";
import { useTranslation} from 'react-i18next';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { GraphContext } from "../routes/GraphContext";

import { preferences } from "../preferences";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import { useIsAuthenticated } from 'react-auth-kit';

// Main part with time buttons ans graph
const StationButton = () => {

    const isAuthenticated = useIsAuthenticated();
    
    const { t } = useTranslation();

    const [ parameter, setParameter ] = useContext(GraphContext);

    const [ data, setData ] = useState([])

    const fetchData = () => {

	if (isAuthenticated())
	{
	    fetch(preferences.address+"/query/stations.php?all=true")
	        .then(response => response.json())
		.then((response) => {
		    setData(response);
		});
	}
	else
	    fetch(preferences.address+"/query/stations.php")
	        .then(response => response.json())
		.then((response) => {
		    setData(response);
		});
	    
    }

	useEffect(() => {

		if (parameter.station === undefined)
			setParameter ({...parameter, station: 1, station_name: "Laimburg"});

        fetchData();
    }, [parameter]);

	const handleListItemClick = (event) => {
		setOpen (false);
 		setParameter ({...parameter, station: event, station_name: data[event-1].name});
	};

	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
	};

	return (
		<>
			
			{data.map((item, i) => (
				item.id === parameter.station && 
					<div key={i} className="small_screen">
					<Button
						sx={{fontSize: "12px", padding: "4px"}}
						variant={parameter.station===item.id ? 'contained' : 'outlined'}
						onClick={handleClickOpen} color="warning"
						key={i} data-id={item.id}
					>
						{t(item.name)}
					</Button>
					</div>
			))}
			<div className="large_screen">
			<ButtonGroup>
			{data.map((item, i) => (
				<Button 
					sx={{fontSize: "12px", padding: "4px"}}
					variant={parameter.station===item.id ? 'contained' : 'outlined'}
					onClick={() => handleListItemClick(item.id)} color="warning"
					key={i} data-id={item.id}
				>
					{t(item.name)}
				</Button>
				
			))}
			</ButtonGroup>
			</div>
		
			<Dialog onClose={handleClose} open={open}>

			<DialogTitle>{t("Stationen")}</DialogTitle>

			<List sx={{ pt: 0 }}>
				{data.map((item) => (
					<ListItem disableGutters key={item.id}>
						<ListItemButton onClick={() => handleListItemClick(item.id)}>
							<ListItemText style={{ color: '#000000' }} primary={item.name} />
						</ListItemButton>
					</ListItem>
				))}

			</List>
			</Dialog>
		</>
	)
}

export default StationButton; 
