import { useState, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { GraphContext } from "../../../GraphContext";
import Skeleton from '@mui/material/Skeleton';
import { preferences } from "../../../../preferences";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Row } from './Row';
import { Head } from './Head';

import { Suspense } from 'react';

const arrayRange = (start, stop, step) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );


export const OverviewYearTable = ({ year }) => {

    const { t, i18n } = useTranslation();

    const [parameter, setParameter] = useContext(GraphContext);

    const [data, setData] = useState({ 1: null, 2: null, 3: null, 4: null, 5: null, 6: null, 7: null, 8: null, 9: null, 10: null, 11: null, 12: null });
    const [head, setHead] = useState({ keys: [] })

    useEffect(() => {

        let station = parameter.station;

        //for (let month = 1; month < 13; month++)
        let url = preferences.address + '/query/tables/year/head.php?station=' + station;

        fetch(url)
            .then(response => response.json())
            .then((dat) => {
                setHead(dat);
            })
    }, [parameter])


    useEffect(() => {

        let station = parameter.station;

        //for (let month = 1; month < 13; month++)
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
            let url = preferences.address + '/query/tables/year/yeardetailsrow.php?station=' + station + '&year=' + year + '&month=' + month;

            fetch(url)
                .then(response => response.json())
                .then((dat) => {
                    switch (month) {
                        case 1:
                            setData((previous) => ({ ...previous, 1: dat }));
                            break;
                        case 2:
                            setData((previous) => ({ ...previous, 2: dat }));
                            break;
                        case 3:
                            setData((previous) => ({ ...previous, 3: dat }));
                            break;
                        case 4:
                            setData((previous) => ({ ...previous, 4: dat }));
                            break;
                        case 5:
                            setData((previous) => ({ ...previous, 5: dat }));
                            break;
                        case 6:
                            setData((previous) => ({ ...previous, 6: dat }));
                            break;
                        case 7:
                            setData((previous) => ({ ...previous, 7: dat }));
                            break;
                        case 8:
                            setData((previous) => ({ ...previous, 8: dat }));
                            break;
                        case 9:
                            setData((previous) => ({ ...previous, 9: dat }));
                            break;
                        case 10:
                            setData((previous) => ({ ...previous, 10: dat }));
                            break;
                        case 11:
                            setData((previous) => ({ ...previous, 11: dat }));
                            break;
                        case 12:
                            setData((previous) => ({ ...previous, 12: dat }));
                            break;
                    }
                });
        });

    }, [year, parameter])


    useEffect(() => {

        let station = parameter.station;

        //for (let month = 1; month < 13; month++)
        ["total", "avg", "long"].map((month) => {
            let url = preferences.address + '/query/tables/year/yeardetailstotal.php?station=' + station + '&year=' + year;

            fetch(url)
                .then(response => response.json())
                .then((dat) => {
                    setData((previous) => ({ ...previous, total: dat }));
                });
        });

    }, [year, parameter])

    useEffect(() => {

        let station = parameter.station;

        //for (let month = 1; month < 13; month++)
        ["total", "avg", "long"].map((month) => {
            let url = preferences.address + '/query/tables/year/yeardetailsmid.php?station=' + station + '&year=' + year;

            fetch(url)
                .then(response => response.json())
                .then((dat) => {
                    setData((previous) => ({ ...previous, mid: dat }));
                });
        });

    }, [year, parameter])

    useEffect(() => {

        let station = parameter.station;

        //for (let month = 1; month < 13; month++)
        ["total", "avg", "long"].map((month) => {
            let url = preferences.address + '/query/tables/year/yeardetailslong.php?station=' + station + '&year=' + year;

            fetch(url)
                .then(response => response.json())
                .then((dat) => {
                    setData((previous) => ({ ...previous, long: dat }));
                });
        });

    }, [year, parameter])

    const getSize = () => {
        
        return {
          width: window.innerWidth,
          height: window.innerHeight
        };
      };

    const [windowSize, setWindowSize] = useState(getSize);
    const [loc, setLoc] = useState(0);

    useEffect(() => {
        const handleResize = () => {
          setWindowSize(getSize());
        };
      
        window.addEventListener('resize', handleResize);
      
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect (() => {
        
        if (windowSize.width > 1650) // 1960
        {
            setLoc(0);
        }
        else if (windowSize.width > 1300) // 1725
        {
            setLoc(1);
        }
        else if (windowSize.width > 1230) // 1580
        {
            setLoc(2);
        }
        else if (windowSize.width > 1100) // 1275
        {
            setLoc(3);
        }
        else if (windowSize.width > 500) // 850
        {
            setLoc(4);
        }
        else if (windowSize.width > 400) // 725
        {
            setLoc(5);
        }
        else if (windowSize.width > 300) // 300
        {
            setLoc(6);
        }
      }, [windowSize]);


  

    return (
        <>
            {/* 
                <p>{windowSize.width}</p>
                <p>LOC: {loc}</p>
            */}

            <Suspense fallback="...loading">

            <Box sx={{ 
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'inherit',
                marginTop: '20px',
                }}>
                
                <TableContainer component={Paper} className="p-1 mt-2">
                    <Table sx={{ minWidth: 350, tableLayout: 'fixed' }} aria-label="simple table" size="small">

                        <Head head={head} loc={loc}/>
                        
                        <TableBody>

                            {data === null ? <Skeleton variant="rectangular" animation="wave" width={'100%'} height={20} /> :

                                arrayRange(1, 12, 1).map((i) => {
                                    let index = Math.floor(Math.random() * 10000);
                                    return (
                                        <>
                                            {data[i] === null ? <Skeleton variant="rectangular" animation="wave" width={'100%'} height={20} /> :
                                                <Row key={index} date={i} row={data[i].rows} keys={head.keys} loc={loc} />
                                            }
                                        </>
                                    )
                                })
                            }

                            {/*  Zusammenfassungen */}
                            {data === null ? <Skeleton variant="rectangular" animation="wave" width={'100%'} height={20} /> :
                                data.total === undefined ? '' :
                                    <>
                                        {data.total === undefined ? <Skeleton variant="rectangular" animation="wave" width={'100%'} height={20} /> :
                                            <Row key="total" date="total" row={data.total.rows} keys={head.keys} loc={loc} color="#eeeeee" />
                                        }
                                    </>
                            }

                            {data === null ? <Skeleton variant="rectangular" animation="wave" width={'100%'} height={20} /> :
                                data.mid === undefined ? '' :
                                    <>
                                        {data.mid === undefined ? <Skeleton variant="rectangular" animation="wave" width={'100%'} height={20} /> :
                                            <Row key="mid" date="mid" row={data.mid.rows} keys={head.keys} loc={loc} color="#eeeeee" />
                                        }
                                    </>
                            }
	
                            {data === null ? <Skeleton variant="rectangular" animation="wave" width={'100%'} height={20} /> :
                                data.long === undefined ? '' :
                                    <>
                                        {data.long === undefined ? <Skeleton variant="rectangular" animation="wave" width={'100%'} height={20} /> :
                                            <Row key="long" date="long" row={data.long.rows} keys={head.keys} loc={loc} color="#eeeeee" />
                                        }
                                    </>
                            }
                            

                        </TableBody>

                    </Table>
                </TableContainer>
            </Box>
            </Suspense>
           
        </>
    );

}
