import { green } from "@mui/material/colors";

import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function ColorButton ({name, active, callback, color}) {

let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    salmon: theme.palette.augmentColor({
      color: {
        main: color !== undefined ? color : green[500],
      },
      name: 'salmon',
    }),
  },
    components: {
    MuiButton: {
        defaultProps: {
            disableElevation: true
        }
        }
    }
});

    return (
        <ThemeProvider theme={theme}>
                <Button 
                  className="flex-item"
                  sx={{
                          fontSize: '12px',
                          padding: '2px 4px',
                          border: '1px solid' 
                      }}
                  variant={active ? "contained" : "outlined"}
                  onClick={callback}
                  color='salmon'
                >
                    {name}
                </Button>
        </ThemeProvider>
    )

}