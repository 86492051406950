import * as React from 'react';
import { useTranslation} from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import { sqlToJsDate } from "../../../js/datetime";
import { format } from 'date-fns';

const formatDate = (da) => {
    if (!(da === undefined))
        return format(sqlToJsDate(da), 'dd/MM/yyyy');
    else
        return "";
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#40516f',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export default function TableSensors({ station }) {

	const { t, i18n } = useTranslation();

    return (
        <>

            <TableContainer component={Paper}>
                <Table size="small" aria-label="sensor table">
                    <TableHead>

                        <TableRow>
                            <StyledTableCell>{t('index')}</StyledTableCell>
                            <StyledTableCell>{t('sensor')}</StyledTableCell>
                            <StyledTableCell>{t('measurement')}</StyledTableCell>
                            <StyledTableCell>{t('unit')}</StyledTableCell>
                            <StyledTableCell>{t('lastmeasurement')}</StyledTableCell>

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {station.sensors.map((sensor, i) => {
                            return <StyledTableRow key={i}>
                                <StyledTableCell>{i + 1}</StyledTableCell>
                                <StyledTableCell>{t(sensor.description)} {sensor.level} </StyledTableCell>
                                <StyledTableCell align="right">{Math.round(sensor.lastvalue.value * 10) / 10}</StyledTableCell>
                                <StyledTableCell align="right">[{sensor.unit}]</StyledTableCell>
                                <StyledTableCell>{sensor.lastvalue.datetime}</StyledTableCell>
                            </StyledTableRow>;
                        })}

                    </TableBody>
                </Table>
            </TableContainer>

        </>
    )
};