import { useState, useContext, useEffect } from "react";

import TableCell from '@mui/material/TableCell';


var index = 1;
function getRandomInt() {
    //console.log (index);
    return index++;
  }

export const Cols = ({ values, index, cat, loc }) => {
    
    return (
        <>
            {(cat === 'airtemp2m') &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">-</TableCell>
                        </>
                        :
                        <>
                            <TableCell key={getRandomInt()} align="right">
                                {Math.round(values.value * 10) / 10}
                            </TableCell>
                            {(loc === 0) &&
                                <>
                                    {values.minVal === undefined ? '' : <TableCell key={getRandomInt()} align="right">{values.minVal}</TableCell>}
                                    {values.minavg === undefined ? '' : <TableCell key={getRandomInt()} align="right">{values.minavg}</TableCell>}

                                    {values.maxVal === undefined ? '' : <TableCell key={getRandomInt()} align="right">{values.maxVal}</TableCell>}
                                    {values.maxavg === undefined ? '' : <TableCell key={getRandomInt()} align="right">{values.maxavg}</TableCell>}
                                </>
                            }
                        </>
                    }
                </>
            }

            {(cat === 'globalrad' && loc < 6) &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                        </>
                        :
                        <>
                            <TableCell key={getRandomInt} align="right">
                                {Math.round(values.value * 10) / 10}
                            </TableCell>
                        </>
                    }
                </>
            }

            {(cat === 'dursunshine' && loc < 5) &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                        </>
                        :
                        <>
                            <TableCell key={getRandomInt()} align="right">
                                {values.value.hour}:
                                {values.value.min < 10 && 0}{values.value.min}:
                                {values.value.sec < 10 && 0}{values.value.sec}
                            </TableCell>
                        </>
                    }
                </>
            }

            {cat === 'humidity' &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                        </>
                        :
                        <>
                            <TableCell key={getRandomInt()} align="right">
                                {Math.round(values.value * 10) / 10}
                            </TableCell>

                            {values.min === undefined ? '' : <TableCell key={getRandomInt()} align="right">{values.min}</TableCell>}
                        </>
                    }
                </>
            }

            {cat === 'rain' &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                        </>
                        :
                        <>
                            <TableCell key={getRandomInt()} align="right">
                                {Math.round(values.value * 10) / 10}
                            </TableCell>
                            {(loc === 0 && values.duration !== undefined) &&
                                <TableCell key={getRandomInt} align="right">
                                    {values.duration.day > 0 ? values.duration.day : ''}
                                    {values.duration.hour < 10 && 0}{values.duration.hour}:
                                    {values.duration.min < 10 && 0}{values.duration.min}:
                                    {values.duration.sec < 10 && 0}{values.duration.sec}
                                </TableCell>
                            }
                        </>
                    }
                </>
            }

            {((cat === 'soiltemp1' || cat === 'soiltemp2') && loc < 3) &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                        </>
                        :
                        <>
                            <TableCell key={getRandomInt()} align="right">
                                {Math.round(values.value * 10) / 10}
                            </TableCell>
                        </>
                    }
                </>
            }


            {cat === 'windspeed' &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                        </>
                        :
                        <>
                            <TableCell key={getRandomInt()} align="right">
                                {Math.round(values.value * 10) / 10}
                            </TableCell>
                            {values.arc === undefined ? '' : <TableCell key={getRandomInt()} align="right">{values.arc}</TableCell>}
                        </>
                    }
                </>
            }

            {(cat === 'level' && loc < 2) &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                        </>
                        :
                        <>
                            <TableCell key={getRandomInt()} align="right">
                                {Math.round(values.value * 10) / 10}
                            </TableCell>
                        </>
                    }
                </>
            }

            {(cat === 'meteorology' && loc < 4) &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">-</TableCell>
                            <TableCell align="right">-</TableCell>
                        </>
                        :
                        <>
                            {/* 
                                {values.rows[0] === undefined ? '' : <TableCell key={index} align="right">{values.rows[0].rain}</TableCell>}
                                {values.rows[0] === undefined ? '' : <TableCell key={index} align="right">{values.rows[0].ices}</TableCell>}
                                {values.rows[0] === undefined ? '' : <TableCell key={index} align="right">{values.rows[0].frost}</TableCell>}
                                {values.rows[0] === undefined ? '' : <TableCell key={index} align="right">{values.rows[0].summer}</TableCell>}
                                {values.rows[0] === undefined ? '' : <TableCell key={index} align="right">{values.rows[0].tropic}</TableCell>}
                                {values.rows[0] === undefined ? '' : <TableCell key={index} align="right">{values.rows[0].desert}</TableCell>}
                            */}
                                {values === undefined ? '' : <TableCell key={getRandomInt()} align="right">{values.rain}</TableCell>}
                                {values === undefined ? '' : <TableCell key={getRandomInt()} align="right">{values.ice}</TableCell>}
                                {values === undefined ? '' : <TableCell key={getRandomInt()} align="right">{values.frost}</TableCell>}
                                {values === undefined ? '' : <TableCell key={getRandomInt()} align="right">{values.summer}</TableCell>}
                                {values === undefined ? '' : <TableCell key={getRandomInt()} align="right">{values.tropic}</TableCell>}
                                {values === undefined ? '' : <TableCell key={getRandomInt()} align="right">{values.desert}</TableCell>}

                        </>
                    }
                </>
            }

        </>
    )

}