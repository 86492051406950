import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import brush from "./Brush.png";
import SubTitle from "./SubTitle";

export default function Info ({ text, component }) {

    const { t, i18n } = useTranslation();

    return (
        <>
	<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
	
	<SubTitle text={t("Bedienungsanleitung")} />

        </AccordionSummary>
            <AccordionDetails>

	{i18n.language=='de' &&
	 <>
	 <p>
	 Seit Beginn des Jahres 2024 können über eine neue Software Wetterdaten des Versuchszentrums Laimburg eingesehen und abgerufen werden. Die als Meteo-App bezeichnete Webapplikation ist für alle Interessierten über Smartphone und Computer frei zugänglich (https://meteo.laimburg.it). Die grafische Darstellung der aktuellen Witterungsdaten und der langjährigen Verläufe erfolgt mit wenigen Mausklicks, zudem können Wetterdaten bei Bedarf auch heruntergeladen werden.
         </p>

																																      <p>
																																      Der Anfang der Aufzeichnung von Wetterdaten an der Laimburg geht auf das Jahr 1965 zurück. Die anfänglich händisch erfolgte Aufzeichnung wurde im Laufe der Jahrzehnte mit elektronischen Messgeräten automatisiert. Auch die Standorte wurden in der Zwischenzeit erweitert und umfassen mittlerweile auch die Außenbetriebe Eyrs, Fragsburg und Dietenheim. Die Wetterstationen Laimburg und Eyrs werden in Zusammenarbeit mit dem Amt für Meteorologie und Lawinenwarnung betreut. Weitere Informationen zu den vier Wetterstationen werden unter folgendem Link aufgelistet: https://meteo.laimburg.it/info.
	 </p>
																																      

	 <p>
Die Meteo-App ermöglicht den Zugriff auf die Daten der vier genannten Standorte mit frei wählbarem Zeitfenster und in unterschiedlicher zeitlicher Auflösung. Somit können detaillierte Informationen über den Witterungsverlauf der letzten Tage oder Wochen, aber ebenso langfristige Datenreihen über mehrere Jahrzehnte abgerufen oder dargestellt werden. Dies ermöglicht Aussagen und Einschätzungen zum aktuellen Verlauf der Witterung, sowie über die klimatischen Veränderungen in den letzten Jahrzehnten. Grundsätzlich wurde versucht, das neue Programm so selbsterklärend und nutzerfreundlich wie möglich zu gestalten. Der Navigationsbereich besteht aus fünf verschiedenen Bereichen, in denen Diagramme und Daten, Berichte, langjährige Verläufe und Informationen zu den einzelnen Stationen abgerufen werden können.
	 </p>

	 <p>
Im Bereich der langjährigen Verläufe sind auch die Monatsmittelwerte der einzelnen Jahre sowie deren Abweichung von den langjährigen Durchschnittswerten einsehbar. Bei den Niederschlägen gibt es auch die Möglichkeit, Trockenperioden auf einer Grafik farblich darzustellen. Insgesamt handelt es sich bei diesem Programm über eine sehr vielseitige Anwendung, die sowohl in der Landwirtschaft als auch in verschiedenen anderen Bereichen interessante und nutzbringende Einsatzmöglichkeiten bieten dürfte.
	 </p>

	 <p>
Im Bereich „Diagramme & Daten“ sind die Messgrößen nach Themenbereich gruppiert (Temperatur, Luftdruck und Feuchte, Niederschlag, Sonnenstrahlung und Wind). Oberhalb der Diagramme befindet sich die Legende der einzelnen Parameter, welche durch das Klicken auf die entsprechenden Felder hinzu- oder weggeschaltet werden können. Für eine detaillierte Betrachtung kann ein bestimmter Bereich, mit gedrückter linker Maustaste und Bewegung der gedrückten Maus nach rechts, ausgewählt und so in die Grafik hineingezoomt werden. Das Aufheben des Zooms bzw. Herauszoomen erfolgt per Doppelklick auf die entsprechende Grafik. Der Download der Daten erfolgt über den grünen Button „Daten Download“ im Bereich DIAGRAMME & DATEN. Damit öffnet sich ein Fenster, welches in zwei Bereiche gegliedert ist: Im oberen Bereich können der Zeitraum, die Auflösung der Daten (Rohwerte, Stundenwerte, Tageswerte) und das gewünschte Datenformat der zu herunterladenden Daten ausgewählt werden. Im unteren Bereich können die jeweiligen Sensoren ausgewählt werden. Routinemäßig werden die Daten aller Sensoren heruntergeladen.
	 </p>

	 <p>
Im Bereich BERICHTE werden die gruppierten und zusammengefassten Daten angezeigt. Drei Berichte, nämlich Monatsreport, Monatstabelle und Jahrestabelle, können in der neuen Meteo-App abgerufen werden.
	 </p>

	 <p>
    Alle Diagramme der Meteo-App können als Grafik heruntergeladen werden. Die Grafik wird als skalierbare Vektorgrafik SVG abgespeichert und kann mit geeigneten Grafikprogrammen geöffnet und weiter bearbeitet werden.
	 </p>
	</>																															     
    }
	 
	{i18n.language=='it' &&
	 <>
	 <p>
	 Dall'inizio del 2024, i dati meteorologici del centro di sperimentazione Laimburg possono essere visualizzati e consultati tramite un nuovo software. L'applicazione web, nota come Meteo-App, è liberamente accessibile a tutti gli interessati tramite smartphone e computer (https://meteo.laimburg.it). La visualizzazione grafica dei dati meteo attuali e le tendenze a lungo termine sono a portata di mouse e, se necessario, i dati meteo possono essere scaricati.
	 </p>

	 <p>
L’inizio delle registrazioni dei dati meteorologici del centro di sperimentazione Laimburg risale al 1965. Le registrazioni, inizialmente manuali, sono state automatizzate nel corso dei decenni utilizzando dispositivi di misurazione elettronici. Nel frattempo, le sedi sono state ampliate e ora comprendono anche i siti periferici di Oris, Fragsburg (Castel Verruca) e Teodone. Le stazioni meteorologiche di Laimburg e Oris sono gestite in collaborazione con l'Ufficio per la meteorologia e prevenzione valanghe. Ulteriori informazioni sulle quattro stazioni meteorologiche sono riportate al seguente link: https://meteo.laimburg.it/info.
	 </p>

	 <p>
Mentre in precedenza i valori misurati dalle stazioni meteorologiche di Laimburg non erano accessibili al pubblico e venivano pubblicati solo sotto forma di rapporti mensili o annuali, questa applicazione meteo segue la tendenza generale alla disponibilità gratuita dei dati.
	 </p>

	 <p>
La Meteo-App consente di accedere ai dati delle quattro località citate con una finestra temporale liberamente selezionabile e suddivisibile in diversi intervalli di tempo. Ciò significa che è possibile richiamare o visualizzare informazioni dettagliate sulle condizioni meteorologiche degli ultimi giorni o settimane, nonché serie di dati a lungo termine per diversi decenni. Ciò consente di fare affermazioni e valutazioni sull'andamento meteorologico attuale e sui cambiamenti climatici degli ultimi decenni. In linea di principio, il nuovo programma è stato progettato per essere il più possibile autoesplicativo e facile da usare. L'area di navigazione è composta da cinque diverse sezioni in cui è possibile richiamare grafici e dati, rapporti, tendenze a lungo termine e informazioni sulle singole stazioni.
	 </p>

	 <p>
Nella sezione “andamenti a lungo termine” è possibile visualizzare anche le medie mensili dei singoli anni e la loro deviazione dai valori medi a lungo termine. Nel caso delle precipitazioni, è anche possibile visualizzare i periodi di siccità su un grafico. Nel complesso, questo programma è un'applicazione molto versatile che può offrire interessanti e utili applicazioni in agricoltura e in vari altri settori.
	 </p>

	 <p>
Nella sezione \"grafici & dati\", le variabili misurate sono raggruppate per argomento (temperatura, pressione atmosferica e umidità, precipitazioni, radiazione solare e vento). La legenda si trova sopra i grafici; ogni singolo parametro può essere nascosto o mostrato cliccando sul buttone sovrastante. Per una visione dettagliata, è possibile selezionare un'area specifica tenendo premuto il tasto sinistro del mouse e col movimento del mouse premuto verso destra, viene ingrandito il grafico. Per annullare o ridurre lo zoom, si deve eseguire un doppio clic sul grafico corrispondente. Cliccando sul pulsante verde "Scarica dati" nell'area GRAFICI E DATI si può scaricare i dati. Si apre una finestra divisa in due aree: Nell'area superiore è possibile selezionare il periodo di tempo, la risoluzione dei dati (valori grezzi, valori orari, valori giornalieri) e il formato desiderato dei dati da scaricare. Nell'area inferiore è possibile selezionare i rispettivi sensori.
	 </p>

	 <p>
I dati raggruppati e sintetizzati sono visualizzati nell'area REPORTS. Nella nuova Meteo-App è possibile richiamare tre reports, ossia il report mensile, la tabella mensile e la tabella annuale.
	 </p>

	 <p>
Tutti i grafici dell' Meteo-App possono essere scaricati e salvati come grafica vettoriale scalabile SVG e può essere aperta e ulteriormente modificata utilizzando programmi di grafica adeguati.
	 </p>
	</>																															     
    }

        </AccordionDetails>
        </Accordion>

        </>
    )
}
