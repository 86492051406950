import * as React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';

export default function FadeMenu({title, items}) {

  const isAuthenticated = useIsAuthenticated();
  const auth_ = useAuthUser();

  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function showItem (txt)
    {
	if (txt == undefined)
	    return false;

	if (txt.length === 0)
	    return true;
	if (isAuthenticated())
	    if (auth_().role.includes(txt))
		return true;
	return false;
    }

  return (
    <>
      <Button
        sx={{color: "white", whiteSpace: 'nowrap', margin: '5px'}}
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
	endIcon={<KeyboardArrowDownIcon />}
      >
        <span>{title}</span>
      </Button>

      <Menu
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
	
        {items !== undefined &&
          items.map((item, index) => {
            if (showItem (item.auth)) return ( <MenuItem key={index} component={Link} onClick={handleClose} to={item.link}>{item.name}</MenuItem> )
        })
        }

      </Menu>
    </>
  );
}

/*
            return ( <MenuItem key={index} component={Link} onClick={handleClose} to={links[index]}>{items[index]}</MenuItem> )
*/
