// Stationinfo.js
import * as d3 from "d3";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";

import "../../styles.css";

import { GraphContext } from "../GraphContext";

import { preferences } from "../../preferences";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Title from "../../components/Title";
import TableStation from './parts/TableStation';
import TableSensors from './parts/TableSensors';


export default function StationInfo () {

//const [parameter, setParameter] = useState({station: 1, sensor:8, from:"", to:"", level: 2});
    const [ t ] = useTranslation();

const draw = (sensor, dateFrom, dateTo) => {

    let svg = d3.select("#d3chart");
    svg.selectAll("*").remove();

    // draw
    let i = {sensor};
    setParameter({...parameter, sensor:i.sensor});

    console.log("draw", parameter);
}

const clearContent = (() => {
  let svg = d3.select("#d3chart");
  svg.selectAll("*").remove();
});

const [parameter, setParameter] = useContext(GraphContext);
const [station, setStation] = useState({  sensors: [] });

const [url, setUrl] = useState("");


useEffect(() => {

  {/*  */}
  let par = { parameter }
  let query = preferences.address + "/rest/station.php?id=" + par.parameter.station;

  d3.json(query).then((data) => {
    setStation(data);
  })

}, [parameter])

useEffect(() => {

  //if (station.cat === null)
  //{
      const x1 = station.lat - 0.01;
      const y1 = station.lon - 0.01;
      const x2 = station.lat + 0.01;
      const y2 = station.lon + 0.01;

      setUrl('https://www.openstreetmap.org/export/embed.html?bbox='+x1+'%2C'+y1+'%2C'+x2+'%2C'+y2+'&layer=mapnik&marker=' + station.lon + '%2C' + station.lat);
  //}

}, [station])

    

return (

	<>

						<Grid item sx={{maxWidth: '800px'}}>
						     <Title text="description"/>
						    <Typography >
								{station.id === 1 && t("description_laimburg_1")}
								{station.id === 2 && t("description_eyrs_1")}
								{station.id === 3 && t("description_fragsburg_1")}
								{station.id === 4 && t("description_dietenheim_1")}
							</Typography>
							<Typography >
								{station.id === 1 && t("description_laimburg_2")}
								{station.id === 2 && t("description_eyrs_2")}
								{station.id === 3 && t("description_fragsburg_2")}
								{station.id === 4 && t("description_dietenheim_2")}
							</Typography>
						</Grid>

						<Grid item>
						     {/* <Title text="figure"/> */}
							<img src={process.env.PUBLIC_URL + '/station_' + station.id + '.jpg'} className="images" ></img>
						</Grid>



						<Grid item>
							{/* <Title text="position" /> */}
						
							<div className="images">
							<iframe width="425" height="350" frameBorder="0" marginHeight="0" marginWidth="0" 
							src={url}>
							</iframe><br/>
							</div>

						</Grid>
						<Grid item>

							<Title text="station" />
							<TableStation station={station} />
			
						</Grid>



						<Grid item>
					<Title text="sensors" />
					
					<TableSensors station={station} />
						</Grid>


    </>
	
);
}
