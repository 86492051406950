
import { Navbar } from "react-bootstrap";
import NavBar from "../components/NavBar";

import "../styles.css";

export default function Header () {

return (

	<>
		{/* <Navigationbar /> */}
		<NavBar />
	</>
	
	);
}
