import { useState, useContext, useEffect } from "react";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { GraphContext } from "../../../GraphContext";

import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { Row } from './Row';

import { preferences } from "../../../../preferences";

import { Head } from './Head';

import { useTranslation } from 'react-i18next';

export const OverviewTable = ({ year, month }) => {

    const [parameter, setParameter] = useContext(GraphContext);

    const [data, setData] = useState (null);

    const { t, i18n } = useTranslation();

    useEffect (() => {

        setData(null);
        
        let station = parameter.station;

        let url = preferences.address+'/query/tables/mview/monthdetails.php?station='+station+'&year='+year+'&month='+month;
        
        fetch(url)
            .then(response => response.json())
            .then((data) => {
                //Object.keys(data.rows).map ((row, index) => {
                //})
                setData(data);
            });

    }, [year, month, parameter])

    const getSize = () => {
        
        return {
          width: window.innerWidth,
          height: window.innerHeight
        };
      };

    const [windowSize, setWindowSize] = useState(getSize);
    const [loc, setLoc] = useState(0);

    useEffect(() => {
        const handleResize = () => {
          setWindowSize(getSize());
        };
      
        window.addEventListener('resize', handleResize);
      
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect (() => {
        
        if (windowSize.width > 1400) // 1850
        {
            setLoc(0);
        }
        else if (windowSize.width > 1000) // 1600
        {
            setLoc(1);
        }
        else if (windowSize.width > 800) // 1330
        {
            setLoc(2);
        }
        else if (windowSize.width > 650) // 1050
        {
            setLoc(3);
        }
        else if (windowSize.width > 500) // 860
        {
            setLoc(4);
        }
        else if (windowSize.width > 405) // 675
        {
            setLoc(5);
        }
        else if (windowSize.width > 300) // 300
        {
            setLoc(6);
        }
      }, [windowSize]);

    return (
        <>
            <Typography variant="h5" className="mt-3" />

            
            {data === null ? '' :
                Object.keys(data.rows).map(key => {
                    Object.keys(data.rows[key]).map(key2 => {
                        return (
                            <div>{t(data.rows[key][key2].val)}</div>
                        )
                    })

                })
            }

            <Box sx={{ 
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'inherit',
                marginTop: '20px',
                }}>

                <TableContainer component={Paper} className="p-1 mt-2">
                    <Table sx={{ minWidth: 50, fontSize: '1rem', tableLayout: 'fixed' }} size="small" aria-label="data table">

                        <Head data={data} loc={loc} />
   
                        {data === null ? /* <Skeleton variant="rectangular" animation="wave" width={'100%'} height={200} /> */ ''  :

                        <TableBody>
                            
			    
                            {Object.keys(data.rows).map((date, i) => {
				if (i < 10) return <Row key={"a"+i} loc={loc} date={date.substring(1).replaceAll('S', '-')} row={data.rows[date]} keys={data.keys} />
                            })}


                            {Object.keys(data.mid).map((dat, i) => {
                        	if (i === 0) return <Row key={"b"+i} loc={loc} date={t("1_decade")} row={data.mid[1]} keys={data.keys} color="#eeeeee" />
                            })}

                            {Object.keys(data.rows).map((date, i) => {
	                        if (i > 9 && i < 20) return <Row key={"c"+i} loc={loc} date={date.substring(1).replaceAll('S', '-')} row={data.rows[date]} keys={data.keys} />
                            })}

                            {Object.keys(data.mid).map((dat, i) => {
                                if (i === 1) return <Row key={"d"+i} loc={loc} date={t("2_decade")} row={data.mid[2]} keys={data.keys} color="#eeeeee"/>
                            })}

                            {Object.keys(data.rows).map((date, i) => {
                                if (i > 19) return <Row key={"e"+i} loc={loc} date={date.substring(1).replaceAll('S', '-')} row={data.rows[date]} keys={data.keys} />
                            })}

                            {Object.keys(data.mid).map((dat, i) => {
	                	    if (i === 2) return <Row key={i} loc={loc} date={t("3_decade")} row={data.mid[3]} keys={data.keys} color="#eeeeee"/>
                            })}
                           
			    {/* Last line */}
                            <Row key="end" loc={loc} date={t("total")} row={data.end} keys={data.keys} color="#dddddd"/>
			
                        </TableBody>
                        }

                    </Table>
                </TableContainer>
                         
              
            </Box>
        </>
    );

}