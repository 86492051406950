import Typography from '@mui/material/Typography';
import { useTranslation} from 'react-i18next';

export default function SubTitle ({text, level, unit}) {

    const { t, i18n } = useTranslation();

	return (

	<>
			<Typography
				/*variant="h6"*/
				/*component="h6"*/
				color='#006400'
				
				sx={{
					fontWeight: 'bold',
					border: '1',
				}}>
				<strong>{t(text)} {level !== null && level} {unit !== undefined && "[" + unit +"]" }</strong>
			</Typography>
	</>

	);
} 
