import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

export default function GraphicButton ({value, up, down}) {

    return (
        <>
	<Box>

	<strong> {value.toFixed(1)} </strong>

        <Button 
            onClick={down}
            color="primary"
	    variant="outlined"
            style={{
                borderRadius: 2,
                backgroundColor: "#65626",//"#656263", #B1914E
                marginLeft: "10px",
                fontSize: "14px"
                }}
                className="m-2"
            >
        <RemoveIcon />
        </Button>

        <Button 
            onClick={up}
            color="primary"
	    variant="outlined"
            style={{
                borderRadius: 2,
                backgroundColor: "#65626",//"#656263", #B1914E
                marginLeft: "10px",
                fontSize: "14px"
                }}
                className="m-2"
            >
            <AddIcon />
            </Button>
	</Box>
        </>
    )

}
