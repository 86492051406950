import { useState } from "react";
import { Routes, Route, Outlet } from "react-router-dom";

import GeneralContent from "../routes/general/GeneralContent";
import StationContent from '../routes/info/StationContent';
import GraphContent from './graph/GraphContent';
import Home from "../routes/Home";
import { GraphContext } from "./GraphContext";
//import { ClimateContent } from "../routes/climate/ClimateContent";

import { TemperatureContent } from "../routes/temp/TemperatureContent";
import { RainContent } from "../routes/rain/RainContent";
import { AgronomicContent } from "../routes/agronomic/AgronomicContent";
import { SeasonsContent } from "../routes/seasons/SeasonsContent";
import { Kenntage } from "../routes/temp/Kenntage";
import { Development } from "../routes/temp/Development";

import { Monatsreport } from '../routes/report/moreport/Monatsreport';
import { Monatsreportview } from '../routes/report/moview/Monatsreportview';
import { Jahresreport } from '../routes/report/year/Jahresreport';
import { Ranking } from '../routes/report/ranking/Ranking';
import { Witterung } from '../routes/report/witterung/Witterung';

import { PhenologyOverview } from '../routes/phenology/PhenologyOverview';
import { PhenologyStates } from '../routes/phenology/PhenologyStates';
import { PhenologyDiagramm } from '../routes/phenology/PhenologyDiagramm';
import { PhenologyTemp } from '../routes/phenology/PhenologyTemp';
import { PhenologyPredict } from '../routes/phenology/PhenologyPredict';


import { WinecultureOverview } from '../routes/phenology_v/WinecultureOverview';

import { Corr } from "../routes/errors/Corr";
import { Correction } from "../routes/errors/Correction";
import { Berichte } from "../routes/errors/Berichte";
import { PhenologyAdmin } from "../routes/admin/PhenologyAdmin";

import { Clever } from "../routes/models/Clever";
import { Saturation } from "../routes/models/Saturation";
import { Mills } from "../routes/models/Mills";
import { Marschall } from "../routes/models/Marschall";

import "../styles.css";

export default function Middle () {

	// Default
   const [parameter, setParameter] = useState({station: 1, from: addDays(-3), to: now(), level: 2});

   //const styles = {Height: '100%'};

   function addDays(d) {
	let date = new Date();
	date.setDate(date.getDate() + d);
	return (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
}

    function now ()
    {
      let date = new Date();
    	return (1900+date.getYear())+"-"+(date.getMonth()+1)+"-"+date.getDate();
    }

   /*const draw = (sensor, dateFrom, dateTo) => {

	    let svg = d3.select("#d3chart");
	    svg.selectAll("*").remove();

	    // draw
	    let i = {sensor};
	    setParameter({...parameter, sensor:i.sensor});
	}*/

	
return (

	<>
	   <GraphContext.Provider value={[parameter, setParameter]}>
				<Routes>
			          <Route path="/" element={<Home /> } />
 	 			  <Route path="/general" element={<GeneralContent />} />
			          <Route path="/info" element={<StationContent />} />
			          <Route path="/graph" element={<GraphContent />} />
			          {/* <Route path="/climate" element={<ClimateContent />} /> */}

			          <Route path="/temperature" element={<TemperatureContent />} />
			          <Route path="/frost" element={<TemperatureContent exttab="4" />} />
			          <Route path="/rain" element={<RainContent />} />
			          <Route path="/seasons" element={<SeasonsContent />} />
				  <Route path="/kenntage" element={<Kenntage />} />
				  <Route path="/development" element={<Development />} />

			          <Route path="/agronomic" element={<AgronomicContent />} />

			          <Route path="/report/month" element={<Monatsreport />} />
			          <Route path="/overview/year" element={<Jahresreport />} />
			          <Route path="/overview/month" element={<Monatsreportview />} />
			          <Route path="/overview/witterung" element={<Witterung />} />

			          <Route path="/phenology/overview" element={<PhenologyOverview />} />
			          <Route path="/phenology/states" element={<PhenologyStates />} />
			          <Route path="/phenology/diagramm" element={<PhenologyDiagramm />} />
			          <Route path="/phenology/correlation" element={<PhenologyTemp />} />
			          <Route path="/phenology/harvest" element={<PhenologyPredict />} />

					  <Route path="/phenology/wineculture" element={<WinecultureOverview />} />
					  

			          <Route path="/overview/stat" element={<Ranking />} />

			          <Route path="/models/clever" element={<Clever />} />
			          <Route path="/models/saturation" element={<Saturation />} />
			          <Route path="/models/mills" element={<Mills />} />
			          <Route path="/models/marschall" element={<Marschall />} />

			          <Route path="/private/witterungsberichte" element={<Berichte />} />
			          <Route path="/private/errors" element={<Corr />} />
		  		 	  <Route path="/private/correction" element={<Correction />} />
				  	  <Route path="/private/phenology" element={<PhenologyAdmin />} />

			          <Route path="*" element={<Outlet />}/>
			   </Routes>
			
		</GraphContext.Provider>
	</>
	
	);
}
