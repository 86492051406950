// Graph
import * as d3 from "d3";
//import { Button } from "react-bootstrap";
import Button from '@mui/material/Button';

// MAin part with time buttons ans graph
const ActiveButton = ({callbackfunction, active, buttontext}) => {

	return (
		<>
		 	<Button 
				variant={active ? "contained" : "outlined"}
				color={"primary"}
				onClick={callbackfunction}
				sx={{
					fontSize: "10px",
					padding: "4px",
					whiteSpace: 'nowrap'
				}}
				>
					{buttontext}
				</Button>
		</>
		)
}

export default ActiveButton; 