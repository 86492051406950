import { format } from 'date-fns'

export function sqlToJsDate(sqlDate)
	{
	    //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
	    if (sqlDate === undefined)
	    	return "";

	    var sqlDateArr1 = sqlDate.split("-");
	    //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
	    var sYear = sqlDateArr1[0];
	    var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
	    var sqlDateArr2 = sqlDateArr1[2].split(" ");
	    //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
	    var sDay = sqlDateArr2[0];
	    var sqlDateArr3 = sqlDateArr2[1].split(":");
	    //format of sqlDateArr3[] = ['hh','mm','ss.ms']
	    var sHour = sqlDateArr3[0];
	    var sMinute = sqlDateArr3[1];
	    var sqlDateArr4 = sqlDateArr3[2].split(".");
	    //format of sqlDateArr4[] = ['ss','ms']
	    var sSecond = sqlDateArr4[0];
	    var sMillisecond = sqlDateArr4[1];
	     
	    return new Date(sYear,sMonth,sDay,sHour,sMinute,sSecond,sMillisecond);
	}


	export function convertTimestamp (timestamp) {
		var d = new Date(timestamp ), // Convert the passed timestamp to milliseconds
			yyyy = d.getFullYear(),
			mm = ('0' + (d.getMonth() + 1)).slice(-2),  // Months are zero based. Add leading 0.
			dd = ('0' + d.getDate()).slice(-2),         // Add leading 0.
			hh = d.getHours(),
			h = hh,
			min = ('0' + d.getMinutes()).slice(-2),     // Add leading 0.
			ampm = 'AM',
			time;

		// ie: 2014-03-24, 3:00 PM
		time = yyyy + '-' + mm + '-' + dd;// + ', ' + h + ':' + min + ' ' + ampm;
		return time;
	}

	export function getCurrentYear ()
	{
		var date = new Date();
		return (parseInt(format(date, 'yyyy')));
	}

	export function getCurrentMonth ()
	{
		var date = new Date();
		return (format(date, 'MM'));
	}

	export function now() {
		let date = new Date();
		return (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
	}


	export function addDays(d) {
		let date = new Date();
		date.setDate(date.getDate() + d);
		return (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
	}

	export function addMonths(m) {
		let date = new Date();
		date.setMonth(date.getMonth() + m);
		return (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
	}

	export function addYears(m) {
		let date = new Date();
		date.setYear(date.getYear() + 1900 + m);
		console.log (date);
		return (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
	}