
import TableCell from '@mui/material/TableCell';
import { makeStyles } from '@mui/styles';

import { useTranslation } from 'react-i18next';


export const Cols = ({ loc, values, index, cat }) => {

    return (
        <>
	    {values !== null &&
	    <>

            {cat === 'airtemp2m' &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                            {loc === 0 && <TableCell align="right">-</TableCell>}
                            {loc === 0 && <TableCell align="right">-</TableCell>}
                        </>
                        :
                        <>
                            <TableCell align="right">
                                {Math.round(values.val * 10) / 10}
                            </TableCell>
                            {(loc === 0 && values.max !== undefined) && <TableCell align="right">{values.max}</TableCell>}
                            {(loc === 0 && values.min !== undefined) && <TableCell align="right">{values.min}</TableCell>}
                        </>
                    }
                </>
            }

            {(cat === 'airtemp' && loc < 2) &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                            {loc === 0 && <TableCell align="right">-</TableCell>}
                            {loc === 0 && <TableCell align="right">-</TableCell>}
                        </>
                        :
                        <>
                            <TableCell align="right"> 
                                {Math.round(values.val * 10) / 10} 
                            </TableCell>
                            {(loc === 0 && values.max !== undefined) && <TableCell key={index+"a"} align="right">{values.max}</TableCell>}
                            {(loc === 0 && values.min !== undefined) && <TableCell key={index+"b"} align="right">{values.min}</TableCell>}
                        </>
                    }
                </>
            }

            {cat === 'humidity' &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                            {loc === 0 && <TableCell align="right">-</TableCell>}
                        </>
                        :
                        <>
                            <TableCell key={index+"a"} align="right">
                                {Math.round(values.val * 10) / 10}
                            </TableCell>

                            {loc === 0 && values.min !== undefined && <TableCell key={index+"b"} align="right">{values.min}</TableCell>}
                        </>
                    }
                </>
            }

            {(cat === 'globalrad' && loc < 6) &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                        </>
                        :
                        <>
                            <TableCell key={index+"a"} align="right">
                                {Math.round(values.val * 10) / 10}
                            </TableCell>
                        </>
                    }
                </>
            }

            {(cat === 'dursunshine' && loc < 4) &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                        </>
                        :
                        <>
                            <TableCell key={index+"a"} align="right">
                                {values.value.day > 0 ? values.value.day : ''}
                                {values.value.day > 0 ? '-' : ''}
                                {values.value.hour < 10 && 0}{values.value.hour}:
                                {values.value.min < 10 && 0}{values.value.min}:
                                {values.value.sec < 10 && 0}{values.value.sec}
                            </TableCell>
                        </>
                    }
                </>
            }

            {cat === 'rain' &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                            {loc === 0 && <TableCell align="right">-</TableCell>}
                        </>
                        :
                        <>
                            <TableCell key={index+"a"} align="right">
                                {Math.round(values.value * 10) / 10}
                            </TableCell>
                            {(loc === 0 && values.duration !== null) &&
                                <TableCell key={index+"b"} align="right">
                                    {values.duration.day > 0 ? values.duration.day : ''}
                                    {values.duration.hour < 10 && 0}{values.duration.hour}:
                                    {values.duration.min < 10 && 0}{values.duration.min}:
                                    {values.duration.sec < 10 && 0}{values.duration.sec}
                                </TableCell>
                            }
                        </>
                    }
                </>
            }

            {((cat === 'soiltemp1' || cat === 'soiltemp2') && (loc < 3)) &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                        </>
                        :
                        <>
                            <TableCell key={index} align="right">
                                {Math.round(values.val * 10) / 10}
                            </TableCell>
                        </>
                    }
                </>
            }


            {(cat === 'windspeed' && loc < 5) &&
                <>
                    {values === undefined ?
                        <>
                            <TableCell align="right">-</TableCell>
                            {loc===0 && <TableCell align="right">-</TableCell>}
                        </>
                        :
                        <>
                            <TableCell key={index+"a"} align="right">
                                {Math.round(values.val * 10) / 10}
                            </TableCell>
                            {(loc===0 && values.arc !== undefined) && <TableCell key={index+"c"} align="right">{values.arc}</TableCell>}
                        </>
                    }
                </>
            }

            {cat === 'level' &&
                <>
                    {(loc < 2 && values !== undefined) ?
                        <>
                            <TableCell key={index} align="right">
                                {Math.round(values.val * 10) / 10}
                            </TableCell>

                        </>
                        :
                        <>
                        </>
                }
                </>
            }
	</>
	}
        </>
    )

}
