import { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';


import { useTranslation } from 'react-i18next';

export const Head = ({ data, loc }) => {

    const { t, i18n } = useTranslation();

    const StyledTableCell = styled (TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
		  backgroundColor: '#008051',
		  color: theme.palette.common.white,
		  fontSize: 16,
          padding: "2px"
		},
		[`&.${tableCellClasses.body}`]: {
		  fontSize: 12,
		},
	  }));

    return (
        <>
            <TableHead>

                {/* Sensor */}
                <TableRow>
                    <StyledTableCell sx={{backgroundColor: '#dddddd'}}>{t("parameter")}</StyledTableCell>
                    {(data === null) ?
                        '' :
                        data.keys.map((key, index) => {
                            let name = key.name;

			    
			    if (key.name === 'airtemp2m')
				return <StyledTableCell key={"a"+name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)+" "+key.level}> {t(name)} {key.level}</StyledTableCell>
			    if (key.name === 'airtemp' && loc < 2)
				return <StyledTableCell key={"a"+name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)+" "+key.level}> {t(name)} {key.level} </StyledTableCell>
			    if (key.name === 'humidity')
				return <StyledTableCell key={"a"+name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)}> {t(name)} </StyledTableCell>
			    if (key.name === 'globalrad' && loc < 6)
				return <StyledTableCell key={"a"+name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)}> {t(name)} </StyledTableCell>
			    if (key.name === 'dursunshine' && loc < 4)
				return <StyledTableCell key={"a"+name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)}> {t(name)} </StyledTableCell>
			    if (key.name === 'rain')
				return <StyledTableCell key={"a"+name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)}> {t(name)} </StyledTableCell>
			    if ((key.name === 'soiltemp1' || key.name === 'soiltemp2') && loc < 3)
				return <StyledTableCell key={"a"+name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)+" "+key.level}> {t(name)} {key.level} </StyledTableCell>
			    if (key.name === 'windspeed'  && loc < 5)
				return <StyledTableCell key={"a"+name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)}> {t(name)} </StyledTableCell>
			    if (key.name === 'level' && loc < 2)
				return <StyledTableCell key={"a"+name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)}> {t(name)} </StyledTableCell>
			    

                            /*return (
                                <>
                                    {key.name === 'airtemp2m' &&
                                        <StyledTableCell key={name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)+" "+key.level}> {t(name)} {key.level}</StyledTableCell>}
                                    
                                    {(key.name === 'airtemp' && loc < 2) &&
                                        <StyledTableCell key={name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)+" "+key.level}> {t(name)} {key.level} </StyledTableCell>}
                                    
                                    {key.name === 'humidity' &&
                                        <StyledTableCell key={name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)}> {t(name)} </StyledTableCell>}
                                    
                                    {(key.name === 'globalrad' && loc < 6) &&
                                        <StyledTableCell key={name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)}> {t(name)} </StyledTableCell>}
                                    
                                    {(key.name === 'dursunshine' && loc < 4) &&
                                        <StyledTableCell key={name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)}> {t(name)} </StyledTableCell>}
                                    
                                    {key.name === 'rain' &&
                                        <StyledTableCell key={name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)}> {t(name)} </StyledTableCell>}
                                    
                                    {((key.name === 'soiltemp1' || key.name === 'soiltemp2') && (loc < 3)) &&
                                        <StyledTableCell key={name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)+" "+key.level}> {t(name)} {key.level} </StyledTableCell>}
                                    
                                    {(key.name === 'windspeed' && (loc < 5)) &&
                                        <StyledTableCell key={name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)}> {t(name)} </StyledTableCell>}
                                    
                                    {(key.name === 'level' && (loc < 2)) &&
                                        <StyledTableCell key={name+index} sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(name)}> {t(name)} </StyledTableCell>}
                                </>
                            )*/
                        })
                    }
                </TableRow>

                {/* Units */}
                <TableRow>
                    <StyledTableCell sx={{backgroundColor: '#dddddd'}}>{t("unit")}</StyledTableCell>
                    {(data !== null) && 
                        data.keys.map((key, index) => {
                            let name = key.name;

			    
			    if (key.name === 'airtemp2m') return <StyledTableCell key={"b"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>
			    if (key.name === 'airtemp' && loc < 2) return <StyledTableCell key={"b"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>
			    if (key.name === 'humidity') return <StyledTableCell key={"b"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>
			    if (key.name === 'globalrad' && loc < 6) return <StyledTableCell key={"b"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>
			    if (key.name === 'dursunshine' && loc < 4) return <StyledTableCell key={"b"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>
			    if (key.name === 'rain') return <StyledTableCell key={"b"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>
			    if ((key.name === 'soiltemp1' || key.name === 'soiltemp2') && loc < 3) return <StyledTableCell key={"b"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>
			    if (key.name === 'windspeed'  && loc < 5) return <StyledTableCell key={"b"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>
			    if (key.name === 'level' && loc < 2) return <StyledTableCell key={"b"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>
			    

                            /*return (
                                <>
                                    {key.name === 'airtemp2m' &&
                                        <StyledTableCell key={key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}
                                    {(key.name === 'airtemp' && loc < 2) &&
                                        <StyledTableCell key={key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}
                                    {key.name === 'humidity' &&
                                        <StyledTableCell key={key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}

                                    {(key.name === 'globalrad' && loc < 6) &&
                                        <StyledTableCell key={key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}

                                    {(key.name === 'dursunshine' && loc < 4) &&
                                        <StyledTableCell key={key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}

                                    {key.name === 'rain' &&
                                        <StyledTableCell key={key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}

                                    {((key.name === 'soiltemp1' || key.name === 'soiltemp2') && (loc < 3)) &&
                                        <StyledTableCell key={key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}

                                    {(key.name === 'windspeed' && loc < 5) &&
                                        <StyledTableCell key={key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}

                                    {(loc < 2 && key.name === 'level') &&
                                        <StyledTableCell key={key.name+index} sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}
                                </>
                            )*/
                        })
                    }
                </TableRow>

                {/* Stat */}
                <TableRow>

                    <StyledTableCell key="cdate" sx={{backgroundColor: '#dddddd'}}>{t("calculation")}</StyledTableCell>
		    
		    {/*  FIXME Unique key problem*/}
                    {(data !== null) && data.keys.map((key) => /*{return (*/
			    <>
			    {key.sub.map((c, index) => {return (key.name === 'airtemp2m' && (loc === 0 || index === 0)) &&
				<StyledTableCell key={"c"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>})}

			    {key.sub.map((c, index) => {return (key.name === 'airtemp' && ((loc === 0) || (loc === 1 && index === 0))) &&
				<StyledTableCell key={"c"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>})}

			    {key.sub.map((c, index) => {return (key.name === 'humidity' && (loc === 0 || index === 0)) &&
				<StyledTableCell key={"c"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>})}

			    {key.sub.map((c, index) => {return (key.name === 'globalrad' && loc < 6) &&
				<StyledTableCell key={"c"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>})}

			    {key.sub.map((c, index) => {return (key.name === 'dursunshine' && loc < 4) &&
				<StyledTableCell key={"c"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>})}

			    {key.sub.map((c, index) => {return (key.name === 'rain' && (loc === 0 || index === 0)) &&
				<StyledTableCell key={"c"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>})}

			    {key.sub.map((c, index) => {return ((key.name === 'soiltemp1' || key.name === 'soiltemp2') && loc < 3) &&
				<StyledTableCell key={"l"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>})}

			    {key.sub.map((c, index) => {return ((key.name === 'windspeed'  && loc < 5)  && (loc === 0 || index === 0)) &&
				<StyledTableCell key={"l"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>})}

			    {key.sub.map((c, index) => {return (key.name === 'level' && loc < 2) &&
				<StyledTableCell key={"l"+key.name+index} sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>})}
			    </>
			    /*)}*/
		    )}

                </TableRow>

            </TableHead>

        </>
    )

}
