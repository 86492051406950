import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import { preferences } from "../../preferences";

import { getCurrentYear, } from '../../js/datetime';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from "@mui/material/CircularProgress";

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import DatePicker from '../../components/DatePicker';
import SpinButton from '../../components/SpinButton';
import StackRow from '../../components/StackRow';
import StationButton from '../../components/StationButton';
import { setup } from '../../components/d3/charts/genchart';
import { borders, addLine, addArea } from '../../components/d3/legend/legend';
import { xlabel, ylabel, logo } from '../../components/d3/charts/chart';

import { useMatomo } from '@datapunt/matomo-tracker-react'

export const Mills = ({ description, list }) => {

    const { trackPageView } = useMatomo()
    const { t, i18n } = useTranslation();

    const location = useLocation();

    const [ parameter] = useContext(GraphContext);

    const [ fromyjs, setFromyjs] = useState(dayjs().year(getCurrentYear()));
    /*const [ frombjs, setFrombjs] = useState(dayjs().year(getCurrentYear()-1).month(3).date(1));*/
    const [ open, setOpen ] = useState();
    const [ year, setYear ] = useState();

    const svgRef = useRef();

    const [ data, setData ] = useState();
    const [ apply, setApply ] = useState([]);

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [ resize, setResize ] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {
	clear();
        d3.json(preferences.address + "/query/models/mills/get.php?station=" + parameter.station + "&year=" + fromyjs.year())
            .then((data) => {
                setData(data);

            });
    }, [parameter, fromyjs]);

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Mills Tabelle', // optional                                                                       
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])


    useEffect(() => {
        const svg = d3.select(svgRef.current);

        //console.log (data.days);
        if (data !== undefined)
            draw(svg);

        /*let labels = [t("temperature_mids"), t("temperature_maps"), t("above_middle"), t("underneath_middle")]
        borders (svg, labels, 300, 70, 10);
        addLine (svg, 0, 2.5, "black", labels[0]);
        addLine (svg, 1, 1.5, "black", labels[1]);
        addArea (svg, 2, "red", labels[2]);
        addArea (svg, 3, "blue", labels[3]);*/

    }, [resize, data, fromyjs, i18n.language]);


    const downloadExcel = () => {

        var url = preferences.address + '/query/climate/temperature/overview/excel.php';

        let block = {};
        block.station = parameter.station;
        block.year = fromyjs.year();
        block.lang = i18n.language;

        let filename = ''
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try { filename = response.headers.get('content-disposition').split('filename=')[1]; }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" })
                saveAs(file);

            });
    }



    const handleSelector = (event) => {
	event.hour(0).minute(0).second(0);
        setFromyjs(event);
    }

    var xScale;
    var xScaleBand;
    let brush;
    let xAxis;
    let xGrid;
    let clip;

    let yScaleW;
    let yScaleR;
    let yScaleT;
    let yScaleM;
    let yScaleH;
    let yScaleF;

    var height = 600;
    var width = 0;
    var margin = { top: 10, right: 80, bottom: 40, left: 70, bottomHeight: 100, offset: 20 }

    let behandlung = [];

    function clear () {

        // Clear
        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove();
	setData();

    }

    function getYCoordinates (array, index)
    {
	let y_top = 0;
	let y_down = 0;
	y_top = margin.top;

	for (let i = 0; i < (index-1); i++)
	{
	    y_top += array[i];
	}
	y_top += (index-1)*margin.offset;
	y_down = y_top + array[index-1];
	return [y_top, y_down];
    }

    function draw(svg) {

        var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);

        // Clear
        svg.selectAll("*").remove();

        // setup SVG
        svg
            .attr("viewBox", [0, 0, svg.style('width'), svg.style('height')])
            .style("overflow", "visible")
            .style("-webkit-tap-highlight-color", "transparent")
            .on("pointerenter pointermove", pointermoved)
            .on("pointerleave", pointerleft)
            .on("touchstart", event => event.preventDefault());

        // Getting width
        width = parseInt(svg.style('width'));

	let hArray = [100, 100, 100, 100, 10, 200];
	let sum  = 0;
	for (let i = 0; i < hArray.length; i++)
	    sum += hArray[i];
	height = margin.top + margin.bottom + sum + (hArray.length - 1)*margin.offset;

        // SVG
        svg
            .attr("width", width) // + margin.left + margin.right
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // Add X axis --> it is a date format
	let timeMin = d3.min (data.list.map((p) => {return d3.timeParse("%Y-%m-%d %H:%M:%S")(p.timestamp)}));
	let timeMax = d3.max (data.list.map((p) => {return d3.timeParse("%Y-%m-%d %H:%M:%S")(p.timestamp)}));

        xScale = d3.scaleTime()
    	    .domain([timeMin, timeMax])
            .range([margin.left, width - margin.right]);


        let off_set = (width - margin.left - margin.right) / 365 * 2;
        xScaleBand = d3.scaleBand()
            .domain(d3.map(data.list, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)))
            .range([margin.left, width - margin.right]).padding(0);

        /* X axis rain */
        xAxis = svg.append("g")
            .attr("transform", "translate(0," + (height - margin.bottom) + ")")
            .call(d3.axisBottom(xScale))
            .style("font-size", "12px");

        let bottomScale = d3.axisBottom(xScale);

        // X grid
        xGrid = svg.append("g")
            .attr('id', 'xgrid')
            .attr('transform', 'translate(0 ,' + (height - margin.bottom) + ')')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(bottomScale.tickSize(-height + margin.bottom + margin.top, 0).tickFormat(d3.timeFormat("%d.%m")).ticks(30));

        // Y grid
        yScaleW = d3.scaleLinear()
	    .domain([5, 0])
            .range(getYCoordinates (hArray, 1));
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .call(d3.axisLeft(yScaleW).ticks(4, "0f").tickFormat((x) => { return x + " V" }))
            .style("font-size", "12px");
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(d3.axisLeft(yScaleW).tickSize(-width + margin.left + margin.right, 0).tickFormat(""));

        // Y grid
	let max = d3.max( data.list.map((d) => {return d.rain}) );
        yScaleR = d3.scaleLinear()
	    .domain([max, 0])
            .range(getYCoordinates (hArray, 2));
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .call(d3.axisLeft(yScaleR).ticks(2, "0f").tickFormat((x) => { return x + " mm" }))
            .style("font-size", "12px");
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(d3.axisLeft(yScaleR).tickSize(-width + margin.left + margin.right, 0).tickFormat(""));

        // Y grid Temperature
        yScaleT = d3.scaleLinear()
	    .range(getYCoordinates(hArray, 3))
            .domain([25, 0]);
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .call(d3.axisLeft(yScaleT).ticks(4, "0f").tickFormat((x) => { return x + " °C" }))
            .style("font-size", "12px");
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(d3.axisLeft(yScaleT).tickSize(-width + margin.left + margin.right, 0).tickFormat(""));

        // Y grid Humidity
        yScaleH = d3.scaleLinear()
            .range(getYCoordinates(hArray, 4))
            .domain([100, 0]);
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .call(d3.axisLeft(yScaleH).ticks(4, "0f").tickFormat((x) => { return x  + " %rH"}))
            .style("font-size", "12px");
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(d3.axisLeft(yScaleH).tickSize(-width + margin.left + margin.right, 0).tickFormat(""));

        // Y under Mills
	max = d3.max( data.list.map((d) => {return d.mills}) );
        yScaleF = d3.scaleLinear()
            .range(getYCoordinates(hArray, 5))
            .domain([1, 0]);
        /*svg.append("g")
            .attr('transform', 'translate(' + margin.left + ', 0)')
            .call(d3.axisLeft(yScaleF).ticks(5, "0f").tickFormat(""))
            .style("font-size", "12px");*/

        // Y under Mills
	max = d3.max( data.list.map((d) => {return d.mills}) );
        yScaleM = d3.scaleLinear()
            .range(getYCoordinates(hArray, 6))
            .domain([4, 0]);
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .call(d3.axisLeft(yScaleM).ticks(5, "0f").tickFormat((x) => { if (x===1) return t("leicht"); if (x===2) return t("mittel"); if (x===3) return t("stark"); return x + " X" }))
            .style("font-size", "12px");
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(d3.axisLeft(yScaleM).tickSize(-width + margin.left + margin.right, 0).tickFormat("").ticks(10));



        // brush
        brush = d3.brushX()
            .extent([[margin.left, 0], [width - margin.right, height - margin.bottom]])
            .on("end", updateChart); // brushended

        svg.append("g")
            .attr('class', 'brush')
            .call(brush);

        // Clip
        clip = svg.append('g')
            .attr("clip-path", "url(#clip)");

        svg.append("defs")
            .append("svg:clipPath")
            .attr('id', 'clip')
            .append("svg:rect")
            .attr("x", margin.left)
            .attr("y", 0)
            .attr("width", width - margin.left - margin.right)
            .attr("height", height);

  

        // Y ticks Regen
        /*let axisLeft2 = d3.axisLeft(yScaleR);
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .call(axisLeft2.ticks(4, "0f").tickFormat((x) => { return x + " mm" }))
            .style("font-size", "12px");*/

        // Grid X

        // Grid X
        /*svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(axisLeft2.tickSize(-width + margin.left + margin.right, 0).tickFormat(""));*/

        // Add the lines group
        const lines = clip
            .append("g")
            .attr('id', 'lines');

        //const X = d3.map(data, (d) => { return d3.timeParse("%Y-%m-%d")(d.date) });
        const Y1 = d3.map(data.list, (d) => { return d.timestamp });
        //const Y2 = d3.map(data, (d) => { return d.longterm });
        //const I = d3.range(data.length);
        const defined = (d, i) => Y1[i];
        //const D = d3.map(data, defined);
        //const line = (y) => d3.line().defined(i => D[i]).curve(d3.curveLinear).x(i => xScale(X[i])).y(y)(I);
        //const area = (y0, y1) => d3.area().defined(i => D[i]).curve(d3.curveLinear).x(i => xScale(X[i])).y0(y0).y1(y1)(I);


        lines.append("path")
            .datum(data.list)
            .attr('id', 'wet')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "brown")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)) })
                .y((d, i) => { return yScaleW(d.leafwet) })
            );
        lines.selectAll(".bar")
            .data(data.list)
            .enter()
            .append("rect")
            .attr('id', 'rain')
            .attr("class", "bar")
            .style("pointer-events", "none")
            .attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)) })
            .attr("y", (d) => { return yScaleR(d.rain) })
            .attr("width", (d) => { return xScaleBand.bandwidth() })
            .attr("height", (d) => { return yScaleR(0) - yScaleR(d.rain) })
            .attr('fill', '#084594');

        lines.append("path")
            .datum(data.list)
            .attr('id', 'temperature')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "green")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)) })
                .y((d, i) => { return yScaleT(d.temperature) })
            );

        lines.append("path")
            .datum(data.list)
            .attr('id', 'humidity')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "grey")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)) })
                .y((d, i) => { return yScaleH(d.humidity) })
            );

	// Set the gradient
	svg.append("linearGradient")
	    .attr("id", "line-gradient")
	    .attr("gradientUnits", "userSpaceOnUse")
	    .attr("x1", 0)
	    .attr("y1", yScaleM(0))
	    .attr("x2", 0)
	    .attr("y2", yScaleM(4))
	    .selectAll("stop")
            .data([
		{offset: "0%", color: "green"},
		{offset: "25%", color: "orange"},
		{offset: "50%", color: "red"},
		{offset: "75%", color: "red.darker(1)"},
		{offset: "100%", color: "black"}
            ])
	    .enter().append("stop")
            .attr("offset", function(d) { return d.offset; })
            .attr("stop-color", function(d) { return d.color; });
	
	
        lines.append("path")
            .datum(data.list)
            .attr('id', 'mills')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "url(#line-gradient)")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)) })
                .y((d, i) => { return yScaleM(d.mills < 4 ? d.mills : 4 ) }) // FIXME
            );

        lines.append("path")
            .datum(data.list)
            .attr('id', 'mills1')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 1.0)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)) })
                .y((d, i) => { return yScaleM(1) }) // FIXME
            );
        lines.append("path")
            .datum(data.list)
            .attr('id', 'mills1')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 1.0)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)) })
                .y((d, i) => { return yScaleM(2) }) // FIXME
            );
        lines.append("path")
            .datum(data.list)
            .attr('id', 'mills1')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 1.0)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)) })
                .y((d, i) => { return yScaleM(3) }) // FIXME
            );

	svg.append("g")

	/*lines
	    .append("rect")
            .attr('id', 'phase1')
	    .attr("fill", "green")
	    .attr("stroke", "#000")
	    .attr("stroke-width", 1.5)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.aufbruch)) )
	    .attr("y", getYCoordinates(hArray, 5)[0])
	    .attr("width", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.redknospen)) - xScale(d3.timeParse("%Y-%m-%d")(data.phenology.aufbruch)) )
	    .attr("height", 20)

	lines
	    .append("rect")
            .attr('id', 'phase2')
	    .attr("fill", "yellow")
	    .attr("stroke", "#000")
	    .attr("stroke-width", 1.5)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.bloomstart)) )
	    .attr("y", getYCoordinates(hArray, 5)[0])
	    .attr("width", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.bloomend)) - xScale(d3.timeParse("%Y-%m-%d")(data.phenology.bloomstart)) )
	    .attr("height", 20)*/

	const pics = svg.append("g").attr("id", "pics");

	if (data.phenology.aufbruch !== null)
	pics.append("svg:image")
	    .attr("id", "aufbruch")
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.aufbruch))-20)
	    .attr("y", getYCoordinates(hArray, 5)[0]-15)
	    .attr("width", 40)
	    .attr("height", 40)
	    .attr("xlink:href", "/phenology/stadien/001.png")

	if (data.phenology.mausohr !== null)
	pics.append("svg:image")
	    .attr("id", "mausohr")
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.mausohr))-20)
	    .attr("y", getYCoordinates(hArray, 5)[0]-15)
	    .attr("width", 40)
	    .attr("height", 40)
	    .attr("xlink:href", "/phenology/stadien/002.png")

	if (data.phenology.greenknospen !== null)
	pics.append("svg:image")
	    .attr("id", "greenknospen")
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.greenknospen))-20)
	    .attr("y", getYCoordinates(hArray, 5)[0]-15)
	    .attr("width", 40)
	    .attr("height", 40)
	    .attr("xlink:href", "/phenology/stadien/003.png")

	if (data.phenology.redknospen !== null)
	pics.append("svg:image")
	    .attr("id", "redknospen")
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.redknospen))-20)
	    .attr("y", getYCoordinates(hArray, 5)[0]-15)
	    .attr("width", 40)
	    .attr("height", 40)
	    .attr("xlink:href", "/phenology/stadien/004.png")

	if (data.phenology.bloomstart !== null)
	pics.append("svg:image")
	    .attr("id", "bloomstart")
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.bloomstart))-20)
	    .attr("y", getYCoordinates(hArray, 5)[0]-15)
	    .attr("width", 40)
	    .attr("height", 40)
	    .attr("xlink:href", "/phenology/stadien/005.png")

	if (data.phenology.bloom !== null)
	pics.append("svg:image")
	    .attr("id", "bloom")
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.bloom))-20)
	    .attr("y", getYCoordinates(hArray, 5)[0]-15)
	    .attr("width", 40)
	    .attr("height", 40)
	    .attr("xlink:href", "/phenology/stadien/006.png")

	if (data.phenology.bloomend !== null)
	pics.append("svg:image")
	    .attr("id", "bloomend")
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.bloomend))-20)
	    .attr("y", getYCoordinates(hArray, 5)[0]-15)
	    .attr("width", 40)
	    .attr("height", 40)
	    .attr("xlink:href", "/phenology/stadien/007.png")

	if (data.phenology.tstadium !== null)
	pics.append("svg:image")
	    .attr("id", "tstadium")
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.tstadium))-20)
	    .attr("y", getYCoordinates(hArray, 5)[0]-15)
	    .attr("width", 40)
	    .attr("height", 40)
	    .attr("xlink:href", "/phenology/stadien/008.png")

	if (data.phenology.erntebeginn !== null)
	pics.append("svg:image")
	    .attr("id", "erntebeginn")
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.erntebeginn))-20)
	    .attr("y", getYCoordinates(hArray, 5)[0]-15)
	    .attr("width", 40)
	    .attr("height", 40)
	    .attr("xlink:href", "/phenology/stadien/009.png")
        /*lines.append("path")
            .datum(data.chart)
            .attr('id', 'temperature')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d, i) => { return yScaleT(night) })
            );*/

        /*lines.append("path")
            .datum(data.chart)
            .attr('id', 'radiation')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d, i) => { return yScaleS(d.rad) })
            );*/


        const box = clip.append("g");

        // Striped up
        /*box.selectAll(".bar")
            .data(data.chart)
            .enter()
            .append("rect")
            .attr("class", "bar2")
            .style("pointer-events", "none")
            .attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("y", margin.top)
            .attr("width", (d) => { return xScaleBand.bandwidth() })
            .attr('fill', function (d) { return (d.rain === null ? '#084594' : '#084594') })
            .attr('opacity', function (d) { return (d.rain === null ? 0 : d.rain/100) })
            .attr("height", height - margin.bottom - margin.bottomHeight - margin.offset - margin.top);

        // Striped down
        box.selectAll(".bar")
            .data(data.chart)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .style("pointer-events", "none")
            .attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("y", (d) => { return yScaleR(d.rain) + height - margin.bottom - yScaleR(0) })
            .attr("width", (d) => { return xScaleBand.bandwidth() })
            .attr("height", (d) => { return yScaleR(0) - yScaleR(d.rain) })
            .attr('fill', '#084594');*/

        svg.append('line')
            .attr('id', 'cursorLine')
            .attr('stroke', 'grey')
            .attr('display', null);

        var dotgroup = svg.append("g").attr('id', 'dotgroup');

        /*  actual */
        dotgroup.append("circle")
            .attr('id', 'dot1')
            .style('display', 'none')
            .style("stroke", "grey")
            .style("fill", 'black')
            .attr("r", 3);

        dotgroup.append("circle")
            .attr('id', 'dot2')
            .style('display', 'none')
            .style("stroke", "grey")
            .style("fill", 'black')
            .attr("r", 3);

        dotgroup.append("circle")
            .attr('id', 'dot3')
            .style('display', 'none')
            .style("stroke", "grey")
            .style("fill", 'black')
            .attr("r", 3);

        dotgroup.append("circle")
            .attr('id', 'dot4')
            .style('display', 'none')
            .style("stroke", "grey")
            .style("fill", 'black')
            .attr("r", 3);

        //createArea();

        var tooltip = svg.append("g")
            .attr('id', 'tooltip')
            .style("pointer-events", "none");

        var path = tooltip.append("path")
            .attr('id', 'pathtool')
            .data([,])
            .attr("class", "shadow")
            .attr("fill", "white")
            .attr("stroke", 'black')
            .attr("stroke-width", 1.5)
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round");

        var tooltiptext = tooltip.append("text")
            .attr('id', 'tooltiptext')
            .style("pointer-events", "none");

        // Date
        tooltiptext
            .append("tspan")
            .attr('id', 'date')
            .attr("x", 15)
            .attr("y", 0)
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value_leafwet')
            .attr("x", 25)
            .attr("y", 20)
            .attr("stroke", 'black')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value_temperature')
            .attr("x", 25)
            .attr("y", 40)
            .attr("stroke", 'black')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value_humidity')
            .attr("x", 25)
            .attr("y", 60)
            .attr("stroke", 'black')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value_elapsed')
            .attr("x", 25)
            .attr("y", 100)
            .attr("stroke", 'black')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value_mills')
            .attr("x", 25)
            .attr("y", 80)
            .attr("stroke", 'black')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        xlabel(svg, t("year"), width / 2, height - 5);

        //ylabel(svg, t("temperature"), - height * 0.3, 15);
        //ylabel(svg, t("rain"), -height * 0.85, 15);

        // Image
        logo(svg);

    }

    function createArea() {
        //const svg = d3.select(svgRef.current);

        //const X = d3.map(data, (d) => { return d3.timeParse("%Y-%m-%d")(d.date) });
       
        //const Y2 = d3.map(data, (d) => { return d.longterm });
        //const I = d3.range(data.length);

        //const defined = (d, i) => Y1[i];

        // Obere Fläche
        //const Y1 = d3.map(data.chart, (d) => { return (yScaleT(d.night) > yScaleS(d.rad)) && (d.rad < day) && (d.night>night) ; });
	//const defined = (d, i) => Y1[i];

	/* Aufbauend */
	/* 3. Untere Grenze: Temperatur */
	/*clip.append('clipPath')
                .datum(data.chart) //.filter(defined)
		.attr('id', '_clip')
		.append('path')
                .attr("d", d3.area()
	            .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
            	    .y0(function (d) { return yScaleT(d.night) })
        	    .y1(function (d) { return yScaleT(-2.5) })
        	);
	/* 1. Grenzwerte
	clip.append('clipPath')
		.attr('id', '_clip3')
	        .attr('clip-path', "url(#_clip)")
		.append('rect')
		.attr('x', margin.left)
		.attr('y', yScaleT(25))
		.attr('width', width - margin.right)
		.attr('height', yScaleT(night) - yScaleT(25));

        clip.append("path")
            .datum(data.chart) //.filter(defined)
            .attr('class', 'area1')
	    .attr('clip-path', "url(#_clip3)")
            .attr("fill", "pink")
            .attr('opacity', 0.5)
            .attr("stroke-width", 0)
            .attr("d", d3.area()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScaleS(d.rad) })
                .y1(function (d) { return yScaleT(-2.5) })
            )

	clip.append('clipPath')
		.attr('id', '_clip5')
	        .attr('clip-path', "url(#_clip)")
		.append('rect')
		.attr('x', margin.left)
		.attr('y', yScaleT(25))
		.attr('width', width - margin.right)
		.attr('height', yScaleT(night) - yScaleT(25));

	if (behandlung !== undefined)
	{
        clip.append("path")
            .datum(behandlung) //.filter(defined)
            .attr('class', 'area1')
	    .attr('clip-path', "url(#_clip5)")
            .attr("fill", "red")
            .attr('opacity', 0.5)
            .attr("stroke-width", 0)
            .attr("d", d3.area()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScaleS(d.rad) })
                .y1(function (d) { return yScaleT(-2.5) })
            )
	}*/

    }

    function pointermoved(event) {

        const X = d3.map(data.list, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp));
        const I = d3.map(data.list, (_, i) => i);
        const i = d3.bisectCenter(X, xScale.invert(d3.pointer(event)[0] - 5));

        // cursorline
        const svg = d3.select(svgRef.current);
        svg.selectAll('#cursorLine')
            .style('display', null)
            .attr('x1', d3.pointer(event)[0] - 5)
            .attr('x2', d3.pointer(event)[0] - 5)
            .attr('y1', 0)
            .attr('y2', height - margin.bottom);

        svg
            .selectAll('#dot1')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScaleW(data.list[i].leafwet));
        svg
            .selectAll('#dot2')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScaleT(data.list[i].temperature));

        svg
            .selectAll('#dot3')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScaleH(data.list[i].humidity));

        svg
            .selectAll('#dot4')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScaleM(data.list[i].mills < 4 ? data.list[i].mills : 4)); // < 3 ? data[i].mills : 3

        // Textbox with values
        var tooltiptext = svg.select("#tooltiptext");
        tooltiptext.attr("transform", `translate(${0}, ${30 - 0})`); // Text

        const { x, y, width: w, height: h } = tooltiptext.node().getBBox();

        var path = svg.select("#pathtool");

        if (xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.list[i].date)) === width - margin.right) {
            svg.select("#tooltip").style("display", 'none');
        }
	let rb = xScale(X[i])> width-200 ? width-200 : xScale(X[i]);
        svg.select("#tooltip")
            .style("display", null)
            .attr("text-anchor", "middle")
            .attr("transform", `translate(${rb},0)`);

        path
            .style('display', null)
            .transition()
            .duration(100)
            .attr('d', `M 0 20 l 10 -10 H${20 + w} V${25 + h} H10 V25 Z`);

        svg.select("#date")
            .transition()
            .duration(200)
            .text(data.list[i].timestamp);

        svg.select("#value_leafwet")
            .transition()
            .duration(200)
            .text(Math.round(data.list[I[i]].leafwet * 10) / 10 + " V");

        svg.select("#value_temperature")
            .transition()
            .duration(200)
            .text(Math.round((data.list[I[i]].temperature) * 10) / 10 + " °C");

        svg.select("#value_humidity")
            .transition()
            .duration(200)
            .text(Math.round((data.list[I[i]].humidity) * 10) / 10 + " %rH");

        svg.select("#value_mills")
            .transition()
            .duration(200)
            .text("Mills: "+Math.round((data.list[I[i]].mills) * 100) / 100);

        svg.select("#value_elapsed")
            .transition()
            .duration(200)
            .text("Vergangen: "+Math.round(data.list[I[i]].elapsed*10)/10+"h");

        let x_pos = d3.pointer(event)[0];
        if (x_pos < 400)
            {
                svg.selectAll('#legend').attr('opacity', 0.1)
            }
        else
        {
            svg.selectAll('#legend').attr('opacity', 1)
        }

    }

    function pointerleft() {
        const svg = d3.select(svgRef.current);

        svg.selectAll('#cursorLine')
            .style('display', 'none');

        svg
            .selectAll('#dot1')
            .style('display', 'none')

        svg.select("#tooltip")
            .style("display", 'none');

        svg.selectAll('#legend').attr('opacity', 1)
    }

    var idleTimeout
    function idled() { idleTimeout = null; }



    function updateChart(event) {

        const svg = d3.select(svgRef.current);

        var extentX = event.selection
        var t1;

        let off_set = (width - margin.left - margin.right) / 365 * 2;

        if (!extentX) {
            if (!idleTimeout) return idleTimeout = setTimeout(idled, 350); // waiting a bit

	    let timeMin = d3.min (data.list.map((p) => {return d3.timeParse("%Y-%m-%d %H:%M:%S")(p.timestamp)}));
	    let timeMax = d3.max (data.list.map((p) => {return d3.timeParse("%Y-%m-%d %H:%M:%S")(p.timestamp)}));

            xScale.domain([timeMin, timeMax]);
            xScaleBand
                .domain(d3.map(data.list, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)))
                .range([margin.left, width - margin.right]).padding(0);
        }
        else {
            var from = xScale.invert(extentX[0]);
            var to = xScale.invert(extentX[1]);

            //console.log(xScale.invert(extentX[0]), xScale.invert(extentX[1]));

            xScale.domain([xScale.invert(extentX[0]), xScale.invert(extentX[1])]);

            var t = d3.map(data.list, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp));
            t1 = t.filter(item => item >= from && item <= to);
            xScaleBand.domain(t1);

            // This remove the grey brush area as soon as the selection has been done
            svg.select(".brush").call(brush.move, null)

        }


        // Bottom
        xAxis.transition()
            .duration(1000)
            .call(d3.axisBottom(xScale));

        // Bottom grid
        xGrid.transition()
            .duration(1000)
            .call(d3.axisBottom(xScale).tickSize(- height + margin.top + margin.bottom, 0).tickFormat(""));

        // Linie längjähriges Mittel
        svg.selectAll("#wet")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)) })
                .y((d) => { return yScaleW(d.leafwet) }));

        // react
        svg.selectAll(".bar")
            .transition()
            .duration(1000)
            .attr("x", d => { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp) )})
            .attr("width", () => { return xScaleBand.bandwidth() });


        svg.selectAll("#temperature")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)) })
                .y((d) => { return yScaleT(d.temperature) }));
        svg.selectAll("#humidity")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)) })
                .y((d) => { return yScaleH(d.humidity) }));
        svg.selectAll("#mills")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.timestamp)) })
                .y((d) => { return yScaleM(d.mills < 4 ? d.mills : 4) })); //FIXME  < 3 ? d.mills : 3

	svg.selectAll("#aufbruch")
            .transition()
            .duration(1000)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.aufbruch)) - 20);
	svg.selectAll("#mausohr")
            .transition()
            .duration(1000)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.mausohr)) - 20);
	svg.selectAll("#greenknospen")
            .transition()
            .duration(1000)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.greenknospen)) - 20);
	svg.selectAll("#redknospen")
            .transition()
            .duration(1000)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.redknospen)) - 20);
	svg.selectAll("#bloomstart")
            .transition()
            .duration(1000)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.bloomstart)) - 20);
	svg.selectAll("#bloom")
            .transition()
            .duration(1000)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.bloom)) - 20);
	svg.selectAll("#bloomend")
            .transition()
            .duration(1000)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.bloomend)) - 20);
	svg.selectAll("#tstadium")
            .transition()
            .duration(1000)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.tstadium)) - 20);
	svg.selectAll("#erntebeginn")
            .transition()
            .duration(1000)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.erntebeginn)) - 20);


        /*svg.selectAll("#phase1")
            .transition()
            .duration(1000)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.aufbruch)) )
	    .attr("width", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.redknospen)) - xScale(d3.timeParse("%Y-%m-%d")(data.phenology.aufbruch)) );

        svg.selectAll("#phase2")
            .transition()
            .duration(1000)
	    .attr("x", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.bloomstart)) )
	    .attr("width", xScale(d3.timeParse("%Y-%m-%d")(data.phenology.bloomend)) - xScale(d3.timeParse("%Y-%m-%d")(data.phenology.bloomstart)) );*/
    }

    const excelExport = (() => {

	let station = parameter.station;

        var url = preferences.address + '/query/models/mills/excel.php?station=' + station + "&year=" + fromyjs.year() + "&lang=" + i18n.language;
	setOpen(true);
	let filename = '';
        fetch(url)
	    .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error)
                {filename = 'Data_Export.xlsx'}
                return response.blob() })
            .then((data) => {
		var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
		setOpen(false);
            });


    })

    return (
        <>
            <div className="stagearea grid p-2">
                <Stack spacing={1}>

		<StackRow text={t("station")} component={<StationButton />} />

                    <Item>
                        <DatePicker
                            title="year"
                            value={fromyjs}
                            callback={handleSelector}
                            view={["year"]}
                            format="YYYY"
			    mindate="2023"
                        />
                    </Item>

                </Stack>


                <div id="svgchart">
	            {data === undefined ? <CircularProgress/> : <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>}
                </div>

                <Grid container flexDirection={"row-reverse"}>

                    <Grid item className="p-2">
                        <ExcelButton object="svg_chart" callback={excelExport} />
                    </Grid>

                    <Grid item>
                        <GraphicButton object="svg_chart" filename={t('temperature_mids')} />
                    </Grid>

                </Grid>
            </div>
        </>
    );

}
